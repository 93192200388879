/**
 * Returns a number formatted with commas
 * Can optionally be presented as a currency
 */

import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'localeNumber',
})
export class NgxsLocaleNumberPipe implements PipeTransform {
    transform(number: number, currency?: string): string | null {
        if (number === null || number === undefined) return '-'
        if (Number.isNaN(number)) return null // will only work value is a number

        const options: Intl.NumberFormatOptions = {
            maximumFractionDigits: 2,
        }
        if (currency) {
            options.style = 'currency'
            options.currency = currency
        }

        return number.toLocaleString('en-US', options)
    }
}
