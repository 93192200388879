     

        <!-- WIDGET 6 -->
        <fuse-widget >

            <!-- Front -->
            <div class="fuse-widget-front">

                <div class="px-16 py-8 border-bottom" fxLayout="row"
                        fxLayoutAlign="space-between center">
                    <div class="h3">{{widgetTitle}}</div>
                    <mat-form-field *ngIf="ranges.length > 1">
                        <mat-select class="simplified" [(ngModel)]="widget6.currentRange"
                                    aria-label="Change range" >
                            <mat-option *ngFor="let range of widgets.widget6.ranges | keys"
                                        [value]="range.key">
                                {{range.key}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="h-400">
                    <ngx-charts-pie-chart
                        *fuseIfOnDom
                        [scheme]="widget6.scheme"
                        [results]="widgets.widget6.mainChart[widget6.currentRange]"
                        [legend]="widget6.showLegend"
                        [explodeSlices]="widget6.explodeSlices"
                        [labels]="widget6.labels"
                        [doughnut]="widget6.doughnut"
                        [gradient]="widget6.gradient"
                        (select)="widget6.onSelect($event)">
                    </ngx-charts-pie-chart>
                </div>

                <!-- <div class="py-8 mh-16 border-top" fxLayout="row wrap" fxLayoutAlign="start center">

                    <div class="py-8 border-right" fxLayout="column" fxLayoutAlign="center center"
                            fxFlex="100" fxFlex.gt-sm="50">
                        <span class="mat-display-1 mb-0">
                            {{widgets.widget6.footerLeft.count[widget6.currentRange]}}
                        </span>
                        <span class="h4">{{widgets.widget6.footerLeft.title}}</span>
                    </div>

                    <div class="py-8" fxLayout="column" fxLayoutAlign="center center" fxFlex="100"
                            fxFlex.gt-sm="50">
                        <span class="mat-display-1 mb-0">
                            {{widgets.widget6.footerRight.count[widget6.currentRange]}}
                        </span>
                        <span class="h4">{{widgets.widget6.footerRight.title}}</span>
                    </div>
                </div> -->

            </div>
            <!-- / Front -->

        </fuse-widget>
        <!-- / WIDGET 6 -->