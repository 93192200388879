import { Injectable } from '@angular/core'
import { take } from 'rxjs/operators'
import { StudyService } from './study.service'
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore'
import { Observable } from 'rxjs'
import { FirestoreService } from './firebase.service'
import * as participantmodel from '@biotaware/models-biotasense-db-biotasense/src/Participant.model'
import { getTimeStamp } from '@biotaware/ngx-system'

/** Service tier for working with participants */
@Injectable()
export class BiotaSenseParticipantsService {
    participants: Observable<participantmodel.IParticipant[]>

    constructor(public firestore: AngularFirestore, public studyService: StudyService, public db: FirestoreService) {}

    /**
     * Temp helper to return currently selected study id
     */
    public getStudyId() {
        const studyId = this.studyService.studyId
        if (!studyId) {
            console.error('STUDY ID NOT SET. SHOULD NOT HAPPEN.')
            //studyId = "PPiHayQ1LTeFIRN7LSf6";
        }
        return studyId
    }

    /**
     * Return the root document for the currently selected study
     */
    public getStudyRoot() {
        const studyId = this.getStudyId()
        const studyRoot = this.studyService.db.doc(studyId)
        return studyRoot
    }

    /**
     * Return the participants collection for the currently selected study
     */
    public getParticipantsCollection(): AngularFirestoreCollection<participantmodel.IParticipant> {
        const studyRoot = this.getStudyRoot()
        const collectionRef = studyRoot.collection<participantmodel.IParticipant>(participantmodel.Collections.Participants)
        return collectionRef
    }

    /**
     * Return the observable array of participants for the currently selected study
     */
    public getParticipants(): Observable<participantmodel.IParticipant[]> {
        const collectionRef = this.getParticipantsCollection().ref.path
        console.log('fetching participants from path ' + collectionRef)
        this.participants = this.db.colWithIds$(collectionRef, (ref) => ref.orderBy('createdAt', 'asc'))
        return this.participants
    }

    /**
     * return an observable of the participant with the given document id
     * @param documentId
     */
    public getParticipant(documentId: string): Observable<participantmodel.IParticipant> {
        const collectionRef = this.getParticipantsCollection()
        const docRef = collectionRef.doc(documentId)
        return this.db.docWithIds$(docRef)
    }

    /**
     * get the participant with the given participantId
     * @param participantId
     */
    public getParticipantById(participantId: string) {
        const collectionRef = this.getParticipantsCollection().ref.path
        return this.db.colWithIds$<participantmodel.IParticipant>(collectionRef, (ref) => ref.where('participantId', '==', participantId))
    }

    // returm true if exists
    public checkParticipantExists(subjectId: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            //Check for duplicates
            //this.getSubject(subjectId)
            this.getParticipantById(subjectId)
                .pipe(take(1))
                .toPromise()
                .then((dupeResult) => {
                    console.log(' - getParticipantById resolved promise')
                    // A subject of this name already exists
                    const exists = dupeResult.length !== 0
                    console.log('dupeResult=' + JSON.stringify(dupeResult))
                    return resolve(exists)
                })
                .catch((error) => {
                    console.log(' - getParticipantById rejected promise')
                    // A subject of this name does not already exist
                    return resolve(false)
                })
        })
    }

    /**
     * Create a new Subject record with the specified ID
     *
     * Will verify a Subject does not already exist with the given id
     * @param participantId Id of the new Subject to create
     */
    public addParticipant(participantId: string): Promise<boolean> {
        /**
         * Process:
         *  1. Check if the subject ID exists already
         *  > Then (If Not)
         *  2. Create a Subject Record for the given ID
         *
         * A server-side process, triggered via the platform EventBus will
         * then takeover the heavy lifting of creating the User Account and tieing it all together
         */

        return new Promise<boolean>((resolve, reject) => {
            //Check for duplicates
            //this.getSubject(subjectId)
            this.checkParticipantExists(participantId)
                .then((dupeResult) => {
                    if (dupeResult) {
                        //Existing subject Found
                        return resolve(false)
                    }

                    //No existing record - so we get to make one!
                    const collectionRef = this.getParticipantsCollection().ref.path
                    const ts = this.db.getTimestamp()
                    const participant: participantmodel.IParticipant = {
                        createdAt: ts,
                        updatedAt: ts,
                        participantId: participantId,
                        studyId: this.getStudyId(),
                        lastSync: null,
                        deactivated: false,
                        fitbitConnectionStatus: participantmodel.FitbitStatus.NotConnected,
                        fitbitAuthenticatedAt: null,
                        _version: 1,
                    }

                    this.db
                        .add(collectionRef, participant)
                        .then((result) => {
                            //Record created
                            return resolve(true)
                        })
                        .catch((error) => {
                            //Failed to create Subject
                            return reject(error)
                        })
                })
                .catch((error) => {
                    console.log('Dupe Error')
                    console.log(error)
                })
        })
    }

    /**
     * update participant
     * Save a subject model back to the server
     */
    public updateParticipant(id: string, participant: participantmodel.IParticipant): Promise<any> {
        const collectionRef = this.getParticipantsCollection()

        participant.updatedAt = getTimeStamp()

        return collectionRef.doc(id).set(participant)
    }
}
