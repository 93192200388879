import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core'

import { FuseConfig } from './types/fuse-config'
import { FuseNavigation } from './types/fuse-navigation'
import { FUSE_CONFIG } from './services/config.service'
import { FUSE_NAVIGATION } from './app/fuse-app.component'

@NgModule()
export class FuseModule {
    constructor(@Optional() @SkipSelf() parentModule: FuseModule) {
        if (parentModule) {
            throw new Error('FuseModule is already loaded. Import it in the AppModule only!')
        }
    }

    static forRoot(config: FuseConfig, navigation: FuseNavigation[]): ModuleWithProviders<FuseModule> {
        return {
            ngModule: FuseModule,
            providers: [
                {
                    provide: FUSE_CONFIG,
                    useValue: config,
                },
                {
                    provide: FUSE_NAVIGATION,
                    useValue: navigation,
                },
            ],
        }
    }
}
