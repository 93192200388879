import { Metric } from '../types/metrics/metric'
//import { forEach } from '@angular/router/src/utils/collection';

const MILLISECOND_DAYS = 1000 * 60 * 60 * 24 * 7

// Wrapper to sit around the metric dataset which is returned by
// our helper class
export class MetricDataSet {
    public dataPoints: Array<any>
    public series: Array<any>
    public name = ''

    constructor() {
        this.dataPoints = new Array<any>()
        this.series = new Array<any>()
    }

    public push(dataPoint: any, time: any) {
        this.dataPoints.push(dataPoint)
        this.series.push(time)
    }

    // public getTimeSeries(start: any, end: any, division: any) {

    // }
}

export class MetricGroup {
    public metricList: Array<Metric>

    constructor(public seriesName: string) {
        this.metricList = new Array<Metric>()
    }
}
