import { Component, OnInit, Input, SimpleChanges } from '@angular/core'
import { MetricDatasetBuilder } from '../../services/metric-dataset-builder'
//import * as shape from 'd3-shape';

@Component({
    selector: 'app-biotasense-analysis-widget',
    templateUrl: './biotasense-analysis-widget.component.html',
    styleUrls: ['./biotasense-analysis-widget.component.scss'],
})
export class BiotaSenseAnalysisWidgetComponent implements OnInit {
    @Input() widgetTitle = '' // title of the widget

    @Input() metricDataSource: MetricDatasetBuilder[] = null
    @Input() ranges = [{ start: -1, end: -1, name: '_def_' }]

    widget6: any = {}

    constructor() {
        /**
         * Widget 6
         */
        this.widget6 = {
            currentRange: 'TW',
            legend: false,
            explodeSlices: false,
            labels: true,
            doughnut: true,
            gradient: false,
            scheme: {
                domain: ['#42b9f4', '#ef232a', '#efcd23', '#495456'],
            },
            onSelect: (ev) => {
                console.log(ev)
            },
        }

        this.widget6.currentRange = Object.keys(this.widgets.widget6.mainChart)[0]
    }

    ngOnInit() {
        this.checkForNewDataSource()

        this.widget6.currentRange = Object.keys(this.widgets.widget6.mainChart)[0]
    }

    ngOnChanges(changes: SimpleChanges) {
        this.checkForNewDataSource()

        if (this.widgets.widget6.mainChart != null) {
            this.widget6.currentRange = Object.keys(this.widgets.widget6.mainChart)[0]
        }
    }

    checkForNewDataSource() {
        // If the client provided a data source.
        if (this.metricDataSource != null) {
            const mainChartTemp = {}

            this.ranges.forEach((range) => {
                const rangeData = this.setUpChartDataForRange(range.start, range.end, range.name)
                mainChartTemp[range.name] = rangeData
            })

            this.widgets.widget6.mainChart = mainChartTemp
        }
    }

    setUpChartDataForRange(start: number, end: number, name: string = '_def_') {
        const sourceSets = []
        this.metricDataSource.forEach((source) => {
            // If we have range defined then we need to change the dates
            if (name != '_def_') {
                source.setDateRangeDays(start, end)
            }

            const dataSet = source.getDataSets()
            sourceSets.push(dataSet)
        })

        // Now we have source sets of info, we need to combine them into a singular set
        // This assumes that they have the same amount of data for each one, possibly a bad assumption

        const rangeDataSet = []

        let i = 0
        for (i; i < sourceSets.length; i++) {
            rangeDataSet.push({
                name: sourceSets[i].series[0],
                value: sourceSets[i].dataPoints[0],
            })
        }

        return rangeDataSet
    }

    public widgets = {
        widget6: {
            title: 'Task Distribution',
            ranges: {
                TW: 'This Week',
                LW: 'Last Week',
                '2W': '2 Weeks Ago',
            },
            mainChart: {
                // 'TW': [
                //     {
                //         'name' : 'Frontend',
                //         'value': 15
                //     },
                //     {
                //         'name' : 'Backend',
                //         'value': 20
                //     },
                //     {
                //         'name' : 'API',
                //         'value': 38
                //     },
                //     {
                //         'name' : 'Issues',
                //         'value': 27
                //     }
                // ],
                // 'LW': [
                //     {
                //         'name' : 'Frontend',
                //         'value': 19
                //     },
                //     {
                //         'name' : 'Backend',
                //         'value': 16
                //     },
                //     {
                //         'name' : 'API',
                //         'value': 42
                //     },
                //     {
                //         'name' : 'Issues',
                //         'value': 23
                //     }
                // ],
                // '2W': [
                //     {
                //         'name' : 'Frontend',
                //         'value': 18
                //     },
                //     {
                //         'name' : 'Backend',
                //         'value': 17
                //     },
                //     {
                //         'name' : 'API',
                //         'value': 40
                //     },
                //     {
                //         'name' : 'Issues',
                //         'value': 25
                //     }
                // ]
            },
            footerLeft: {
                title: 'Tasks Added',
                count: {
                    '2W': 487,
                    LW: 526,
                    TW: 594,
                },
            },
            footerRight: {
                title: 'Tasks Completed',
                count: {
                    '2W': 193,
                    LW: 260,
                    TW: 287,
                },
            },
        },
    }
}
