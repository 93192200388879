/**
 * Core module for system wide components & services
 * Provides app wide services for firebase declared in this module
 * Imported only once by the app module
 */

import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core'

// Environment service & config for API keys etc.
import { NgxsEnvironmentService, EnvironmentConfig, ENVIRONMENT_TOKEN } from './services/environment.service'

@NgModule({
    declarations: [],
    imports: [],
    providers: [NgxsEnvironmentService],
})
export class NgxsSystemModule {
    constructor(@Optional() @SkipSelf() parentModule: NgxsSystemModule) {
        if (parentModule) {
            throw new Error('Core SystemModule is already loaded. Import it in the AppModule only')
        }
    }

    /**
     * SystemModule must be imported by the client application AppModule with `.forRoot(environment)`
     * @param environment
     * @returns
     */
    public static forRoot(environment: EnvironmentConfig): ModuleWithProviders<NgxsSystemModule> {
        return {
            ngModule: NgxsSystemModule,
            providers: [{ provide: ENVIRONMENT_TOKEN, useValue: environment }],
        }
    }
}
