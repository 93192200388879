import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'

import { FuseNavigationModule } from '../../../../../components/navigation/navigation.module'
import { FuseSharedModule } from '../../../../../shared.module'

import { NavbarVerticalStyle1Component } from './style-1.component'

@NgModule({
    declarations: [NavbarVerticalStyle1Component],
    imports: [MatButtonModule, MatIconModule, FuseSharedModule, FuseNavigationModule],
    exports: [NavbarVerticalStyle1Component],
})
export class NavbarVerticalStyle1Module {}
