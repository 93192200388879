<!-- show spinner if loading -->
<mat-progress-spinner *ngIf="isLoading" [color]="isLight ? 'accent' : 'primary'" mode="indeterminate" [diameter]="40" style="margin:0 auto;">
</mat-progress-spinner>
<!-- otherwise show any child content if not loading -->
<ng-content *ngIf="!isLoading"></ng-content>


<!--

<div class="loading-spinner" [class.loading-spinner--white]="isLight">
    <i *ngIf="isLoading" class="loading-spinner fas fa-spinner fa-spin fa-2x"></i>
</div>
-->
