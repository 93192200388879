import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { FuseSharedModule } from '../../../../../fuse/src/lib/shared.module'

// Shared app components
import { NgxsProgressBarComponent } from './progress-bar.component'

@NgModule({
    imports: [CommonModule, FuseSharedModule],
    declarations: [NgxsProgressBarComponent],
    exports: [NgxsProgressBarComponent],
})
export class NgxsProgressBarModule {}
