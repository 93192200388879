import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { FuseSharedModule } from '../../../../../fuse/src/lib/shared.module'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

// Shared app components
import { NgxsProgressSpinnerComponent } from './progress-spinner.component'

@NgModule({
    imports: [CommonModule, FuseSharedModule, MatProgressSpinnerModule],
    declarations: [NgxsProgressSpinnerComponent],
    exports: [NgxsProgressSpinnerComponent],
})
export class NgxsProgressSpinnerModule {}
