import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router, ActivatedRouteSnapshot, UrlSegment, NavigationEnd } from '@angular/router'
import { filter } from 'rxjs/operators'

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class NgxsBreadcrumbComponent implements OnInit {
    public breadcrumbs: {
        name: string
        url: string
    }[] = []

    constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

    ngOnInit() {
        // Get the breadcrumbs for routes accessed using the address bar
        this.breadcrumbs = []
        this.parseRoute(this.router.routerState.snapshot.root)

        // Subscribe to the NavigationEnd event
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            // Set breadcrumbs
            this.breadcrumbs = []
            this.parseRoute(this.router.routerState.snapshot.root)
        })
    }

    private parseRoute(node: ActivatedRouteSnapshot) {
        const breadcrumbNameRaw = node.data['breadcrumb']
        if (breadcrumbNameRaw) {
            let breadcrumbName = breadcrumbNameRaw
            // exchange breadcrumb name for route parameter if enclosed in {}
            const breadcrumbNameToken = breadcrumbNameRaw.replace(/\{ *([\w_]+) *\}/g, function (s, key) {
                try {
                    //console.log("looking for key {" + key + "}")
                    const param = node.params[key]
                    if (param) {
                        //console.log("found key {" + key + "}")
                        //console.log("found param {" + param + "}")
                        return param
                    } else {
                        //console.log("key not found")
                    }
                } catch (err) {
                    console.log('replace threw error - key not found')
                }
                return undefined
            })

            if (breadcrumbNameToken) breadcrumbName = breadcrumbNameToken

            if (!this.breadcrumbs.find((item) => item.name === breadcrumbName)) {
                let urlSegments: UrlSegment[] = []
                node.pathFromRoot.forEach((routeState) => {
                    urlSegments = urlSegments.concat(routeState.url)
                })

                const url = urlSegments
                    .map((urlSegment) => {
                        return urlSegment.path
                    })
                    .join('/')

                this.breadcrumbs.push({
                    name: breadcrumbName, //node.data['breadcrumb'],
                    url: url,
                })
            }
        }

        if (node.children.length > 0) {
            this.parseRoute(node.firstChild)
        }
    }

    navigateToRoute(url: string) {
        this.router.navigate([url])
    }
}
