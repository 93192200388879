import { Metric } from '../types/metrics/metric'
import { MetricGroup, MetricDataSet } from './metric-dataset'

export class MetricDatasetBuilder {
    constructor(private metrics: Array<Metric>) {
        this.groupNames = new Array<string>()
        this.dateGetterFunction = this.defaultDateGetter
        this.groupAssignFunction = this.defaultGroupNameAssigner

        this.fromDate = new Date()
        this.fromDate.setDate(this.fromDate.getDate() - 7)

        this.toDate = new Date()
    }

    public groupNames: Array<string>
    public dateGetterFunction: (m: Metric) => Date
    public groupAssignFunction: (m: Metric) => string
    public fromDate: Date
    public toDate: Date
    public dateFormatter: (date: Date) => string = this.dateToString
    public aggregateFunction: (m: Array<Metric>, series: string) => any = null
    public name = ''

    /**
     * Client has to provide all info for this
     * @param aggregateFunction
     */
    public getDataSets(aggregateFunction: (m: Array<Metric>, series: string) => any = null) {
        // Check that the data set has either been passed in via the method or attached to the object
        const currentAggFunc = aggregateFunction == null ? this.aggregateFunction : aggregateFunction
        if (currentAggFunc == null) return null

        const result = this.collectGroupsForMetric(this.groupNames, this.groupAssignFunction)
        const dataSet = this.getDataSetsFromGroup(result, currentAggFunc)

        return dataSet
    }

    test() {
        const builder = new MetricDatasetBuilder(null)
        builder.setDateRangeDays(7)
        builder.getDataSets(MetricDatasetBuilder.aggregateAverage('', ''))
    }

    public collectGroupsForMetric(groupNames: Array<string>, func: (m: Metric) => string): Map<string, MetricGroup> {
        const resultMap = new Map<string, MetricGroup>()

        // Start by making an entry for each groupName
        groupNames.forEach((groupName) => {
            this.addMetricGroupToMapIfDoesntExist(groupName, resultMap)
        })

        this.metrics.forEach((metric) => {
            // Let the client work out which day it is.
            const seriesName: string = func(metric)

            // We let the client return null if they would like the metric excluded
            if (seriesName != null) {
                this.addMetricGroupToMapIfDoesntExist(seriesName, resultMap)

                const group = resultMap.get(seriesName)
                group.metricList.push(metric)
            }
        })

        return resultMap
    }

    private addMetricGroupToMapIfDoesntExist(groupName: string, map: Map<string, MetricGroup>) {
        if (!map.has(groupName)) map.set(groupName, new MetricGroup(groupName))
    }

    /**
     * Takes a series of metrics for a timeseries and then returns the data point for that series
     * @param func
     */
    public getDataSetsFromGroup(metricMap: Map<string, MetricGroup>, func: (m: Array<Metric>, series: string) => any) {
        const dataSet = new MetricDataSet()
        dataSet.name = this.name

        // We need to group all of the metrics by series
        const map: Map<string, Array<Metric>> = new Map<string, Array<Metric>>()

        // Now we need to ask the user to aggregate the series into distinct pieces of data
        metricMap.forEach((val, key) => {
            // Key = series, val = list o'metrics
            const result: any = func(val.metricList, key)

            // put the value into our dataset
            dataSet.push(result, key)
        })

        return dataSet
    }

    // DATE RANGE METHODS

    public setDateRange(start: Date, end: Date, deltaSeconds: number = 86400) {
        this.fromDate = start
        this.toDate = end

        if (this.groupAssignFunction == this.defaultGroupNameAssigner) this.groupAssignFunction = this.groupNameFromDateWithRange(this.fromDate, this.toDate)

        this.setGroupNamesFromDateRange(this.fromDate, this.toDate, deltaSeconds)
    }

    /**
     *
     * @param start How many days in the past are we starting, 0 is today, 1 is yesterday etc Time set to Midnight AM
     * @param end How many days in the past are we ending, 0 is today, 1 is yesterday etc Time set to Midnight PM
     */
    public setDateRangeDays(start: number, end: number = 0) {
        this.fromDate = new Date()
        this.fromDate.setDate(this.fromDate.getDate() - start)
        this.fromDate.setHours(0)
        this.fromDate.setMinutes(0)
        this.fromDate.setSeconds(0)
        this.fromDate.setMilliseconds(0)

        this.toDate = new Date()
        this.toDate.setDate(this.toDate.getDate() - end)

        if (end > 0) {
            this.toDate.setHours(23)
            this.toDate.setMinutes(59)
            this.toDate.setSeconds(59)
            this.toDate.setMilliseconds(999)
        }

        if (this.groupAssignFunction == this.defaultGroupNameAssigner) this.groupAssignFunction = this.groupNameFromDateWithRange(this.fromDate, this.toDate)

        this.setGroupNamesFromDateRange(this.fromDate, this.toDate, 86400)
    }

    private setGroupNamesFromDateRange(start: Date, end: Date, deltaSeconds: number) {
        // Build the groups
        this.groupNames = new Array<string>()
        const tempDate = new Date(start)

        while (tempDate <= end) {
            // Get the group name using our default and return that
            this.groupNames.push(this.dateFormatter(tempDate))

            // Calculate the next date
            tempDate.setSeconds(tempDate.getSeconds() + deltaSeconds)
        }
    }

    // DEFAULT HELPER METHODS

    private defaultDateGetter(metric: Metric) {
        return new Date(metric.CapturedAt)
    }

    private defaultGroupNameAssigner(metric: Metric) {
        const date = this.dateGetterFunction(metric)
        return this.dateFormatter(date)
    }

    private dateToString(date: Date) {
        return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear().toString().substring(2, 4)
    }

    public groupNameFromDateWithRange(start: Date, end: Date): (m: Metric) => string {
        const ref = this // keep a references to the builder
        function returnFunction(m: Metric): string {
            const dateTime = ref.dateGetterFunction(m)

            // Now we need to return null on any that may be outside of the range
            if (ref.fromDate != null && dateTime < ref.fromDate) return null

            if (ref.toDate != null && dateTime > ref.toDate) return null

            // Otherwise we can return this series
            const group = ref.defaultGroupNameAssigner(m)
            if (ref.groupNames.includes(group)) return group

            return null
        }

        return returnFunction
    }

    // USEFUL HELPER AGGREGATES

    public static aggregateSum(schemaName: string, measurementName: string): (metrics: Array<Metric>, series: string) => any {
        function returnFunction(metrics: Array<Metric>, series: string): any {
            let sum = 0
            metrics.forEach((metric) => {
                sum += metric.Schemas[schemaName][measurementName]
            })

            return sum
        }

        return returnFunction
    }

    public static aggregateAverage(schemaName: string, measurementName: string): (metrics: Array<Metric>, series: string) => any {
        function returnFunction(metrics: Array<Metric>, series: string): any {
            let avg = 0
            metrics.forEach((metric) => {
                avg += metric.Schemas[schemaName][measurementName]
                avg /= 2
            })
            return avg
        }

        return returnFunction
    }

    public static aggregateMax(schemaName: string, measurementName: string): (metrics: Array<Metric>, series: string) => any {
        function returnFunction(metrics: Array<Metric>, series: string): any {
            let currentMax = 0
            metrics.forEach((metric) => {
                const steps = metric.Schemas[schemaName][measurementName]
                if (steps > currentMax) currentMax = steps
            })
            return currentMax
        }

        return returnFunction
    }
}
