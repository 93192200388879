/**
 * Participant Class
 * Encapsulate all services related to a study participant
 */

import { Injectable, Query } from '@angular/core'
import { Observable, Subscription, Subject } from 'rxjs'
import { map } from 'rxjs/operators'

import { BiotaSenseParticipantsService } from './participants.service'
import { FirestoreService } from '../services/firebase.service'
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore'

import * as participantmodel from '@biotaware/models-biotasense-db-biotasense/src/Participant.model'
import * as fitbitservermodel from '@biotaware/models-biotasense-db-biotasense/src/FitbitServer.model'

//SM: Had to make this a service.
@Injectable()
export class ParticipantService {
    participant: participantmodel.IParticipant
    participantId: string
    fitbitUserId: string

    participant$: Observable<participantmodel.IParticipant>
    fitbitAccount$: Observable<fitbitservermodel.IFitbitAccount>
    fitbitAccountTokenStatus$: Observable<fitbitservermodel.IFitbitTokenStatus>
    fitbitAccountDevices$: Observable<fitbitservermodel.IFitbitDevices>

    participantLoaded = false
    fitbitAccountLoaded = false
    fitbitAccountTokenStatusLoaded = false
    fitbitAccountDevicesLoaded = false

    subscriptions: Subscription = new Subscription()

    constructor(private subjectsSvc: BiotaSenseParticipantsService, private firestore: AngularFirestore, public db: FirestoreService) {
        console.log('New Participant service instance created')
    }

    public setParticipant(participantId: string) {
        console.log("Participant '" + participantId + "' created")
        this.participantId = participantId
        this.participant$ = this.subjectsSvc.getParticipant(participantId)
        this.subscriptions.add(
            this.participant$.subscribe((value) => {
                const fitbitUserId = value.fitbitUserId
                console.log("Participant '" + participantId + "' loaded, fitbit Id '" + fitbitUserId + "'")

                this.participant = value
                this.fitbitUserId = fitbitUserId

                this.participantLoaded = true
                this.syncFitbitData()
            }),
        )
    }

    private syncFitbitData() {
        // fetch the fitbit account root document
        const fitbitCollectionRef = this.firestore.collection(fitbitservermodel.Collections.FitbitAccounts)
        const fitbitAccountRef = fitbitCollectionRef.doc<fitbitservermodel.IFitbitAccount>(this.fitbitUserId)
        this.fitbitAccount$ = this.db.doc$<fitbitservermodel.IFitbitAccount>(fitbitAccountRef)

        this.subscriptions.add(
            this.fitbitAccount$.subscribe((value) => {
                console.log('loaded fitbit account ' + JSON.stringify(value))
                this.fitbitAccountLoaded = true
            }),
        )

        // fetch the token status for this fitbit account (so that we can determine permissions/scope)
        const fitbitTokenStatusRef = fitbitAccountRef.collection(fitbitservermodel.Collections.FitbitTokenStatus)
        this.fitbitAccountTokenStatus$ = this.db
            .col$<fitbitservermodel.IFitbitTokenStatus>(fitbitTokenStatusRef.ref.path, (ref) => ref.orderBy('time', 'desc').limit(1))
            .pipe(map((array) => array[0]))

        this.subscriptions.add(
            this.fitbitAccountTokenStatus$.subscribe((value) => {
                console.log('loaded fitbit token status ' + JSON.stringify(value))
                this.fitbitAccountTokenStatusLoaded = true
            }),
        )

        // fetch the token status for this fitbit account (so that we can determine permissions/scope)
        const fitbitDeviceRef = fitbitAccountRef.collection(fitbitservermodel.Collections.FitbitDevices)
        this.fitbitAccountDevices$ = this.db
            .col$<fitbitservermodel.IFitbitDevices>(fitbitDeviceRef.ref.path, (ref) => ref.orderBy('time', 'desc').limit(1))
            .pipe(map((array) => array[0]))

        this.subscriptions.add(
            this.fitbitAccountDevices$.subscribe((value) => {
                console.log('loaded fitbit devices ' + JSON.stringify(value))
                this.fitbitAccountDevicesLoaded = true
            }),
        )
    }

    destroy() {
        this.subscriptions.unsubscribe()
    }
}
