// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// DEVELOPMENT VERSION FOR DEVELOPMENT OR LOCAL SERVER
export const environment = {
    production: false,
    multiTenant: true,
    firebase: {
        apiKey: 'AIzaSyDRbdffKfV2fZ4qYTXXSxDtEcITyu27JL0',
        authDomain: 'biotasense-dev.firebaseapp.com',
        databaseURL: 'https://biotasense-dev.firebaseio.com',
        projectId: 'biotasense-dev',
        storageBucket: 'biotasense-dev.appspot.com',
        messagingSenderId: '462630165501',
        appId: '1:462630165501:web:af25c9e97fc47449cc5794',
        measurementId: 'G-HPDWT05PBR',
    },
    apiHost: 'https://app.biotasense.dev',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
