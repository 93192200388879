import { Injectable, Inject } from '@angular/core'

import { EnvironmentConfig, FirebaseAppConfig } from '../types/environment.model'
export { EnvironmentConfig } from '../types/environment.model'

export const ENVIRONMENT_TOKEN = 'environment' // App Environment object is Injected by App bootstrap using this token

// @dynamic
@Injectable()
export class NgxsEnvironmentService {
    constructor(@Inject(ENVIRONMENT_TOKEN) private _env: EnvironmentConfig) {
        /** */
    }

    isProduction(): boolean {
        return this._env.production
    }
    isMultiTenant(): boolean {
        return this._env.multiTenant
    }

    getFirebaseConfig(): FirebaseAppConfig {
        return this._env.firebase
    }

    getApiHost(): string {
        return this._env.apiHost
    }

    get env(): EnvironmentConfig {
        return this._env
    }
}
