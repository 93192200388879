import { Component, OnDestroy, OnInit, Injector } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Subject } from 'rxjs'
import { FuseTranslationLoaderService } from '@biotaware/ngx-fuse'
import { FuseAppService } from '@biotaware/ngx-fuse'

import { Router } from '@angular/router'

import { locale as navigationEnglish } from './navigation/i18n/en'
import { locale as navigationTurkish } from './navigation/i18n/tr'

import { NgxsAuthenticationService } from '@biotaware/ngx-server'

import { Md5 } from 'ts-md5/dist/md5'

import { Route } from '@angular/router'

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    // Private
    private _unsubscribeAll: Subject<any>

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _router: Router,
        private _authenticationService: NgxsAuthenticationService,
        private injector: Injector,
        private _fuseAppService: FuseAppService,
    ) {
        // Add languages
        this._translateService.addLangs(['en', 'tr'])

        // Set the default language
        this._translateService.setDefaultLang('en')

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish)

        // Use a language
        this._translateService.use('en')

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         */

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         * setTimeout(() => {
         * this._translateService.setDefaultLang('en');
         * this._translateService.setDefaultLang('tr');
         * });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Set the private defaults
        this._unsubscribeAll = new Subject()

        // Configure App Branding
        this._fuseAppService.setBranding('Biotasense', 'assets/images/logos/Biotaware.png')

        // Subscribe to logged in user
        this._authenticationService.user$.subscribe((user) => {
            if (user) {
                console.log('--- User has changed ---')
                this._fuseAppService.currentUserName = user.displayName
                this._fuseAppService.currentUserEmail = user.email
                this._fuseAppService.currentUserAvatar = user.photoURL
                    ? user.photoURL
                    : 'https://www.gravatar.com/avatar/' + Md5.hashStr(user.email.toLowerCase())
            } else {
                this._fuseAppService.currentUserName = ''
                this._fuseAppService.currentUserEmail = ''
                this._fuseAppService.currentUserAvatar = ''
            }
        })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // debug to list all known routes for this app
        //this.listRoutes()
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next()
        this._unsubscribeAll.complete()
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Log out the current user
     */
    logout(): void {
        this._authenticationService.logout().then((res) => {
            this._router.navigate(['/auth/login'])
        })
    }

    //private urls: string[] = [];

    private listRoutes() {
        console.log(JSON.stringify(this._router.config))
        this._router.config.forEach((i) => {
            this.getPaths(i)
        })
        //console.log(JSON.stringify(this.urls))
    }
    private getPaths(route: Route, parent: string = '') {
        if (route.redirectTo) {
            return
        }
        if (route.children) {
            route.children.forEach((i) => {
                this.getPaths(i, parent + '/' + route.path)
            })
        } else if (route.loadChildren) {
            ;(<any>this._router).configLoader.load(this.injector, route).subscribe((i) => {
                i.routes.forEach((j) => {
                    this.getPaths(j, parent + '/' + route.path)
                })
            })
        } else if (route.path !== null) {
            this.setPath(route.path, parent)
        }
    }
    setPath(path, parent) {
        let fullPath: string
        if (path !== null) {
            if (parent) {
                fullPath = `/${parent}/${path}`
            } else {
                fullPath = `/${path}`
            }
        }
        console.log(fullPath)
        //this.urls.push(fullPath)
    }
}
