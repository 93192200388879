import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

// SM: Globally import the FirebaseFirestore namespace for compatibility with our function data model files.
//  tslint:disable-next-line
//import { FirebaseFirestore } from '@biotaware/ngx-system/@system/types/FirebaseFirestore' //'@system/types/FirebaseFirestore'
//import '@biotaware/ngx-system/types/FirebaseFirestore' //'@system/types/FirebaseFirestore'

if (environment.production) {
    enableProdMode()

    // Disable logs in production build
    // https://stackoverflow.com/questions/53733807/disabling-console-log-in-production
    window.console.log = () => {}
    window.console.warn = () => {}
    // window.console.error = () => { }
    window.console.time = () => {}
    window.console.timeEnd = () => {}
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err))
