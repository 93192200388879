import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core'

import * as shape from 'd3-shape'
import * as d3 from 'd3'

// colour schemes:
// vivid, natural, cool, fire, solar, air, aqua, flame, ocean, forest, horizon, neons, picnic, night, nightLights

@Component({
    selector: 'app-data-chart',
    templateUrl: './data-chart.component.html',
    styleUrls: ['./data-chart.component.scss'],
    encapsulation: ViewEncapsulation.None, // so that data-chart-container is accessible externally to this component.
})
export class DataChartComponent implements OnInit {
    singleDataSet = [
        {
            name: 'Subject-001',
            value: 40632,
        },
        {
            name: 'Subject-002',
            value: 49737,
        },
        {
            name: 'Subject-003',
            value: 36745,
        },
        {
            name: 'Subject-004',
            value: 36240,
        },
        {
            name: 'Subject-005',
            value: 33000,
        },
        {
            name: 'Subject-006',
            value: 35800,
        },
    ]

    multiDataSet = [
        {
            name: 'Subject-001',
            series: [
                {
                    name: '2010',
                    value: 40632,
                },
                {
                    name: '2000',
                    value: 36953,
                },
                {
                    name: '1990',
                    value: 31476,
                },
            ],
        },
        {
            name: 'Subject-002',
            series: [
                {
                    name: '2010',
                    value: 49737,
                },
                {
                    name: '2000',
                    value: 45986,
                },
                {
                    name: '1990',
                    value: 37060,
                },
            ],
        },
        {
            name: 'Subject-003',
            series: [
                {
                    name: '2010',
                    value: 36745,
                },
                {
                    name: '2000',
                    value: 34774,
                },
                {
                    name: '1990',
                    value: 29476,
                },
            ],
        },
        {
            name: 'Subject-004',
            series: [
                {
                    name: '2010',
                    value: 36240,
                },
                {
                    name: '2000',
                    value: 32543,
                },
                {
                    name: '1990',
                    value: 26424,
                },
            ],
        },
    ]

    bubbleDataSet = [
        {
            name: 'Subject-001',
            series: [
                {
                    name: '2010',
                    x: '2010-01-01T00:00:00.000Z',
                    y: 80.3,
                    r: 80.4,
                },
                {
                    name: '2000',
                    x: '2000-01-01T00:00:00.000Z',
                    y: 80.3,
                    r: 78,
                },
                {
                    name: '1990',
                    x: '1990-01-01T00:00:00.000Z',
                    y: 75.4,
                    r: 79,
                },
            ],
        },
        {
            name: 'Subject-002',
            series: [
                {
                    name: '2010',
                    x: '2010-01-01T00:00:00.000Z',
                    y: 80.2,
                    r: 62.7,
                },
                {
                    name: '2000',
                    x: '2000-01-01T00:00:00.000Z',
                    y: 77.8,
                    r: 58.9,
                },
                {
                    name: '1990',
                    x: '1990-01-01T00:00:00.000Z',
                    y: 75.7,
                    r: 57.1,
                },
            ],
        },
        {
            name: 'Subject-003',
            series: [
                {
                    name: '2004',
                    x: '2004-01-01T00:00:00.000Z',
                    y: 65,
                    r: 37,
                },
            ],
        },
        {
            name: 'Subject-004',
            series: [
                {
                    name: '1990',
                    x: '1990-01-01T00:00:00.000Z',
                    y: 34,
                    r: 35,
                },
            ],
        },
        {
            name: 'Subject-005',
            series: [
                {
                    name: '2001',
                    x: '2001-01-01T00:00:00.000Z',
                    y: 59,
                    r: 36,
                },
            ],
        },
        {
            name: 'Subject-006',
            series: [
                {
                    name: '2007',
                    x: '2007-01-01T00:00:00.000Z',
                    y: 75,
                    r: 41,
                },
            ],
        },
        {
            name: 'MalSubject-007',
            series: [
                {
                    name: '2009',
                    x: '2009-01-01T00:00:00.000Z',
                    y: 44,
                    r: 40,
                },
            ],
        },
        {
            name: 'Subject-008',
            series: [
                {
                    name: '1992',
                    x: '1992-01-01T00:00:00.000Z',
                    y: 85,
                    r: 44,
                },
            ],
        },
        {
            name: 'Subject-009',
            series: [
                {
                    name: '1990',
                    x: '1990-01-01T00:00:00.000Z',
                    y: 68,
                    r: 36,
                },
            ],
        },
        {
            name: 'Subject-010',
            series: [
                {
                    name: '1991',
                    x: '1991-01-01T00:00:00.000Z',
                    y: 57,
                    r: 36,
                },
            ],
        },
    ]

    @Input() chartType: string

    theme = 'dark'
    chartGroups: any[]
    chart: any
    realTimeData = false
    countries: any[]
    single: any[] = this.singleDataSet
    multi: any[] = this.multiDataSet
    fiscalYearReport: any[]
    dateData: any[]
    dateDataWithRange: any[]
    calendarData: any[]
    statusData: any[]
    sparklineData: any[]
    timelineFilterBarData: any[]
    graph: { links: any[]; nodes: any[] }
    bubble: any = this.bubbleDataSet
    linearScale = false
    range = false

    view: any[]
    width = 700
    height = 300
    fitContainer = true

    // options
    showXAxis = true
    showYAxis = true
    gradient = false
    showLegend = true
    legendTitle = 'Legend'
    legendPosition = 'right'
    showXAxisLabel = true
    tooltipDisabled = false
    xAxisLabel = 'Participant ID'
    showYAxisLabel = true
    yAxisLabel = 'Steps Per Day'
    showGridLines = true
    innerPadding = '10%'
    barPadding = 8
    groupPadding = 16
    roundDomains = false
    maxRadius = 10
    minRadius = 3
    showSeriesOnHover = true
    roundEdges = true
    animations = true
    xScaleMin: any
    xScaleMax: any
    yScaleMin: number
    yScaleMax: number
    showDataLabel = false
    trimXAxisTicks = true
    trimYAxisTicks = true
    maxXAxisTickLength = 16
    maxYAxisTickLength = 16

    curves = {
        Basis: shape.curveBasis,
        'Basis Closed': shape.curveBasisClosed,
        Bundle: shape.curveBundle.beta(1),
        Cardinal: shape.curveCardinal,
        'Cardinal Closed': shape.curveCardinalClosed,
        'Catmull Rom': shape.curveCatmullRom,
        'Catmull Rom Closed': shape.curveCatmullRomClosed,
        Linear: shape.curveLinear,
        'Linear Closed': shape.curveLinearClosed,
        'Monotone X': shape.curveMonotoneX,
        'Monotone Y': shape.curveMonotoneY,
        Natural: shape.curveNatural,
        Step: shape.curveStep,
        'Step After': shape.curveStepAfter,
        'Step Before': shape.curveStepBefore,
        default: shape.curveLinear,
    }

    // line interpolation
    curveType = 'Linear'
    curve: any = this.curves[this.curveType]
    interpolationTypes = ['Basis', 'Bundle', 'Cardinal', 'Catmull Rom', 'Linear', 'Monotone X', 'Monotone Y', 'Natural', 'Step', 'Step After', 'Step Before']

    closedCurveType = 'Linear Closed'
    closedCurve: any = this.curves[this.closedCurveType]
    closedInterpolationTypes = ['Basis Closed', 'Cardinal Closed', 'Catmull Rom Closed', 'Linear Closed']

    colorSets: any
    @Input() colorScheme: any = 'solar'
    schemeType = 'ordinal'
    selectedColorScheme: string
    rangeFillOpacity = 0.15

    // Override colors for certain values
    // customColors: any[] = [
    //   {
    //     name: 'Germany',
    //     value: '#0000ff'
    //   }
    // ];

    // pie
    showLabels = true
    explodeSlices = false
    doughnut = false
    arcWidth = 0.25

    // line, area
    autoScale = true
    timeline = false

    // margin
    margin = false
    marginTop = 40
    marginRight = 40
    marginBottom = 40
    marginLeft = 40

    // gauge
    gaugeMin = 0
    gaugeMax = 100
    gaugeLargeSegments = 10
    gaugeSmallSegments = 5
    gaugeTextValue = ''
    gaugeUnits = 'alerts'
    gaugeAngleSpan = 240
    gaugeStartAngle = -120
    gaugeShowAxis = true
    gaugeValue = 50 // linear gauge value
    gaugePreviousValue = 70

    // heatmap
    heatmapMin = 0
    heatmapMax = 50000

    //chartType = 'line';
    lineChartModel = {
        scheme: {
            domain: ['#5c84f1'],
        },
        results: [
            {
                name: 'Sales',
                series: [
                    {
                        name: 'Jan 1',
                        value: 540,
                    },
                    {
                        name: 'Jan 2',
                        value: 539,
                    },
                    {
                        name: 'Jan 3',
                        value: 538,
                    },
                    {
                        name: 'Jan 4',
                        value: 539,
                    },
                    {
                        name: 'Jan 5',
                        value: 540,
                    },
                    {
                        name: 'Jan 6',
                        value: 539,
                    },
                    {
                        name: 'Jan 7',
                        value: 540,
                    },
                ],
            },
        ],
        yScaleMin: 538,
        yScaleMax: 541,
    }

    chartModel = this.lineChartModel

    constructor() {
        /** */
    }

    ngOnInit() {
        if (!this.fitContainer) {
            this.view = [this.width, this.height]
        } else {
            this.view = undefined
        }
    }

    onLegendLabelClick(entry) {
        console.log('Legend clicked', entry)
    }
}
