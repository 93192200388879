import { Component, OnInit, Inject } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm.component.html',
})
export class NgxsConfirmComponent implements OnInit {
    title = 'Title'
    content = 'Content?'
    negativeText = 'No'
    positiveText = 'Yes'

    constructor(public dialogRef: MatDialogRef<NgxsConfirmComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit() {
        this.title = this.data.title ? this.data.title : this.title
        this.content = this.data.content ? this.data.content : this.content
        this.negativeText = this.data.negativeText ? this.data.negativeText : this.negativeText
        this.positiveText = this.data.positiveText ? this.data.positiveText : this.positiveText
    }
}
