import { IFireStoreDocumentVersioned } from '@biotaware/models-shared-db-document'

/**
 * User documents exist to reflect current firebase user accounts to the database for application query & access purposes
 * User documents are created when new user accounts are created
 * For multi-tenant applications, the root `users` collections is for "super users" (system adminstrators),
 *  and each tenant account maintains its own `users` sub-collection beneath its `/tenants/{tenantId}/users` sub collection
 *
 */
export class Collections {
    public static User = 'users'
}

/**
 * Define a tuple type that captures the results returned from a user query
 */
export type UserQueryResult = [FirebaseFirestore.DocumentReference, IUser]

/**
 * API Key status
 */
export enum UserApiKeyStatus {
    valid = 'valid',
    revoked = 'revoked',
}

/**
 * User API key interface
 */
export interface UserApiKey {
    keyType: string
    status: UserApiKeyStatus
    id: string
}

export interface IUser extends IFireStoreDocumentVersioned<1> {
    readonly uid: string // matches document ID
    readonly tenantId: string | null

    // these are all empty strings if not set, rather than being undefined or null.
    firstname: string
    lastname: string
    fullname: string // displayName
    phoneNumber: string // E164 format
    email: string

    profileImageUrl: string

    //-----------------------------
    // User permissions
    // These are reflected from the user auth record
    // Changing these on the user document will not change permissions
    //-----------------------------

    role?: string // app defined user role
    isAdmin?: boolean // this user has an admin/super user role assigned

    // Last login time
    // If this isn't present then we can assume that the user has been invited
    // but has not yet logged in.
    lastLogin?: FirebaseFirestore.Timestamp

    // User API keys
    // Used for REST API keys etc
    apiKeys?: UserApiKey[]

    //-----------------------------
    // User deletion
    //-----------------------------

    // SM: Dec 2020 - upon deletion of an account we do not delete user documents, since this user id is referenced elsewhere in the db.
    // instead, we temporarily archive their original details, but scrub the rest to ensure document queries are never matched again.
    // the dbUserOnWrite trigger will propagate the changed data as needed.
    // if for GDPR purpose we later have to purge personal info, it can be done by deleting this field.
    isDeleted?: boolean // this user account has been deleted. It no longer has a firebase user auth account
    deletionRecord?: {
        firstname: string
        lastname: string
        fullname: string
        phoneNumber: string
        email: string
        profileImageUrl: string
        deletedOn: FirebaseFirestore.Timestamp
    }

    // we discourage the addition of application specific fields in here, so that the user code can be used cross-project
    // instead create subcollections for this data if needed

    // Also, we do not store copies of custom claims on this document since it reveals platform information about how we implement security
    // Better to have an API to read or change access permissions for a given user.
}
