/**
 * Main module
 * Only imported once by App module
 * Declare app wide modules, routes, services, pipe that are app-specific in here
 */

import { NgModule, Optional, SkipSelf } from '@angular/core'

// Libraries
import { FuseSharedModule } from '@biotaware/ngx-fuse'
import { FuseConfirmDialogModule, FuseSidebarModule } from '@biotaware/ngx-fuse'
import { FuseHighlightModule } from '@biotaware/ngx-fuse'
import { FuseWidgetModule } from '@biotaware/ngx-fuse'

// Services
import { BiotaSenseParticipantsService } from './services/participants.service'
import { BiotaSenseFitbitService } from './services/fitbit.service'

import { FirestoreService } from './services/firebase.service'
import { ParticipantService } from './services/Participant.service'

import { ComplianceService } from './services/compliance.service'
import { StudyService } from './services/study.service'
import { UserService } from './services/user.service'
import { MetricsService } from './services/metric.service'

@NgModule({
    declarations: [],
    imports: [FuseSharedModule, FuseConfirmDialogModule, FuseSidebarModule, FuseHighlightModule, FuseWidgetModule],
    providers: [
        StudyService,
        UserService,
        BiotaSenseParticipantsService,
        BiotaSenseFitbitService,
        FirestoreService,
        ParticipantService,
        ComplianceService,
        MetricsService,
    ],
})
export class MainModule {
    constructor(@Optional() @SkipSelf() parentModule: MainModule) {
        console.log('MainModule ctor')
        if (parentModule) {
            throw new Error('Core MainModule is already loaded. Import it in the AppModule only')
        }
    }
}
