import { Component, OnInit, Input, SimpleChanges } from '@angular/core'
import * as shape from 'd3-shape'
import { MetricDatasetBuilder } from '../../services/metric-dataset-builder'

@Component({
    selector: 'app-biotasense-graph-widget',
    templateUrl: './biotasense-graph-widget.component.html',
    styleUrls: ['./biotasense-graph-widget.component.scss'],
})
export class BiotaSenseGraphWidgetComponent implements OnInit {
    @Input() widgetTitle = '' // title of the widget
    @Input() widgetData: any

    @Input() metricDataSource: MetricDatasetBuilder[] = null
    @Input() ranges = [{ start: -1, end: -1, name: '_def_' }]

    @Input() type = 'bar-stacked'

    currentRange = 0

    widget5: any = {}

    constructor() {
        /**
         * Widget 5
         */
        this.widget5 = {
            currentRange: 'TW',
            xAxis: true,
            yAxis: true,
            gradient: true,
            legend: false,
            showXAxisLabel: false,
            xAxisLabel: 'Days',
            showYAxisLabel: false,
            yAxisLabel: 'Value',
            scheme: {
                domain: ['#42BFF7', '#C6ECFD', '#C7B42C', '#AAAAAA'],
            },
            onSelect: (ev) => {
                console.log(ev)
            },
            supporting: {
                currentRange: '2W',
                xAxis: false,
                yAxis: false,
                gradient: false,
                legend: false,
                showXAxisLabel: false,
                xAxisLabel: 'Days',
                showYAxisLabel: false,
                yAxisLabel: 'Value',
                scheme: {
                    domain: ['#42BFF7', '#C6ECFD', '#C7B42C', '#AAAAAA'],
                },
                curve: shape.curveBasis,
            },
        }

        this.widget5.currentRange = Object.keys(this.widgets.widget5.mainChart)[0]
    }

    ngOnInit() {
        this.checkForNewDataSource()

        if (this.widgetData != null) this.widgets.widget5.mainChart = this.widgetData

        // Object.keys(this.widgets.widget5.mainChart)[0];
        this.widget5.currentRange = Object.keys(this.widgets.widget5.mainChart)[0]
    }

    ngOnChanges(changes: SimpleChanges) {
        this.checkForNewDataSource()

        if (this.widgetData != null) {
            this.widgets.widget5.mainChart = this.widgetData
        }

        if (this.widgets.widget5.mainChart != null) {
            this.widget5.currentRange = Object.keys(this.widgets.widget5.mainChart)[0]
        }
    }

    checkForNewDataSource() {
        // If the client provided a data source.
        if (this.metricDataSource != null) {
            const mainChartTemp = {}

            this.ranges.forEach((range) => {
                const rangeData = this.setUpChartDataForRange(range.start, range.end, range.name)
                mainChartTemp[range.name] = rangeData
            })

            this.widgets.widget5.mainChart = mainChartTemp
        }
    }

    setUpChartDataForRange(start: number, end: number, name: string = '_def_') {
        const sourceSets = []
        this.metricDataSource.forEach((source) => {
            // If we have range defined then we need to change the dates
            if (name != '_def_') {
                source.setDateRangeDays(start, end)
            }

            const dataSet = source.getDataSets()
            sourceSets.push(dataSet)
        })

        // Now we have source sets of info, we need to combine them into a singular set
        // This assumes that they have the same amount of data for each one, possibly a bad assumption

        const rangeDataSet = []

        if (this.type == 'line') {
            // line chart format
            let i = 0
            for (i; i < sourceSets.length; i++) {
                const series = []
                let j = 0
                for (j = 0; j < sourceSets[i].series.length; j++) {
                    series.push({
                        name: sourceSets[i].series[j],
                        value: sourceSets[i].dataPoints[j],
                    })
                }

                rangeDataSet.push({
                    name: sourceSets[i].name,
                    series: series,
                })
            }
        } else {
            // bar chart format
            let i = 0
            for (i; i < sourceSets[0].series.length; i++) {
                const series = []
                sourceSets.forEach((set) => {
                    series.push({
                        name: set.name,
                        value: set.dataPoints[i],
                    })
                })

                rangeDataSet.push({
                    name: sourceSets[0].series[i],
                    series: series,
                })
            }
        }
        return rangeDataSet
    }

    /** This is what we were doing in SubjectsView
     * let ranges = [
                {
                    name : "This Week",
                    startDays : 6,
                    endDays : 0
                },
                {
                    name : "Last Week",
                    startDays : 13,
                    endDays : 7
                },
                {
                    name : "Two Weeks Ago",
                    startDays : 20,
                    endDays : 14
                }
            ];

            ranges.forEach(range => {

                // Get Datasets
                let measurementSets = [];

                measurements.forEach(measurement => {

                    builder.setDateRangeDays(range.startDays, range.endDays); // Last week
                    let weekDataset = builder.getDataSets(MetricDatasetBuilder.aggregateMax("HeartRateSummary", measurement));
        
                    // Add it to this so we can iterate over it for the chart and save some computation
                    // in doing it again
                    measurementSets.push({ measurement, weekDataset });
                });

                let rangeDataSet = [];
                let i = 0; // For each group returned in the dataset
                for(i; i < measurementSets[0].weekDataset.series.length; i++) {

                    let series = []
                    measurementSets.forEach(set => {
                        series.push({
                            "name" : set.measurement,
                            "value" : set.weekDataset.dataPoints[i],
                        })
                    });

                    rangeDataSet.push({
                        "name" : measurementSets[0].weekDataset.series[i],
                        "series" : series
                    });
                }

                this.chartDataset[range.name] = rangeDataSet;
            });
     */

    public widgets = {
        widget5: {
            title: 'Github Issues',
            mainChart: {},
            supporting: {
                created: {
                    label: 'CREATED',
                    count: {
                        '2W': 48,
                        LW: 46,
                        TW: 54,
                    },
                    chart: {
                        '2W': [
                            {
                                name: 'CREATED',
                                series: [
                                    {
                                        name: 'Mon',
                                        value: 5,
                                    },
                                    {
                                        name: 'Tue',
                                        value: 8,
                                    },
                                    {
                                        name: 'Wed',
                                        value: 5,
                                    },
                                    {
                                        name: 'Thu',
                                        value: 6,
                                    },
                                    {
                                        name: 'Fri',
                                        value: 7,
                                    },
                                    {
                                        name: 'Sat',
                                        value: 8,
                                    },
                                    {
                                        name: 'Sun',
                                        value: 7,
                                    },
                                ],
                            },
                        ],
                        LW: [
                            {
                                name: 'Created',
                                series: [
                                    {
                                        name: 'Mon',
                                        value: 6,
                                    },
                                    {
                                        name: 'Tue',
                                        value: 3,
                                    },
                                    {
                                        name: 'Wed',
                                        value: 7,
                                    },
                                    {
                                        name: 'Thu',
                                        value: 5,
                                    },
                                    {
                                        name: 'Fri',
                                        value: 5,
                                    },
                                    {
                                        name: 'Sat',
                                        value: 4,
                                    },
                                    {
                                        name: 'Sun',
                                        value: 7,
                                    },
                                ],
                            },
                        ],
                        TW: [
                            {
                                name: 'Created',
                                series: [
                                    {
                                        name: 'Mon',
                                        value: 3,
                                    },
                                    {
                                        name: 'Tue',
                                        value: 2,
                                    },
                                    {
                                        name: 'Wed',
                                        value: 1,
                                    },
                                    {
                                        name: 'Thu',
                                        value: 4,
                                    },
                                    {
                                        name: 'Fri',
                                        value: 8,
                                    },
                                    {
                                        name: 'Sat',
                                        value: 8,
                                    },
                                    {
                                        name: 'Sun',
                                        value: 4,
                                    },
                                ],
                            },
                        ],
                    },
                },
                closed: {
                    label: 'CLOSED',
                    count: {
                        '2W': 27,
                        LW: 31,
                        TW: 26,
                    },
                    chart: {
                        '2W': [
                            {
                                name: 'CLOSED',
                                series: [
                                    {
                                        name: 'Mon',
                                        value: 3,
                                    },
                                    {
                                        name: 'Tue',
                                        value: 2,
                                    },
                                    {
                                        name: 'Wed',
                                        value: 1,
                                    },
                                    {
                                        name: 'Thu',
                                        value: 4,
                                    },
                                    {
                                        name: 'Fri',
                                        value: 8,
                                    },
                                    {
                                        name: 'Sat',
                                        value: 8,
                                    },
                                    {
                                        name: 'Sun',
                                        value: 4,
                                    },
                                ],
                            },
                        ],
                        LW: [
                            {
                                name: 'CLOSED',
                                series: [
                                    {
                                        name: 'Mon',
                                        value: 6,
                                    },
                                    {
                                        name: 'Tue',
                                        value: 5,
                                    },
                                    {
                                        name: 'Wed',
                                        value: 4,
                                    },
                                    {
                                        name: 'Thu',
                                        value: 5,
                                    },
                                    {
                                        name: 'Fri',
                                        value: 7,
                                    },
                                    {
                                        name: 'Sat',
                                        value: 4,
                                    },
                                    {
                                        name: 'Sun',
                                        value: 7,
                                    },
                                ],
                            },
                        ],
                        TW: [
                            {
                                name: 'CLOSED',
                                series: [
                                    {
                                        name: 'Mon',
                                        value: 6,
                                    },
                                    {
                                        name: 'Tue',
                                        value: 3,
                                    },
                                    {
                                        name: 'Wed',
                                        value: 7,
                                    },
                                    {
                                        name: 'Thu',
                                        value: 5,
                                    },
                                    {
                                        name: 'Fri',
                                        value: 5,
                                    },
                                    {
                                        name: 'Sat',
                                        value: 4,
                                    },
                                    {
                                        name: 'Sun',
                                        value: 7,
                                    },
                                ],
                            },
                        ],
                    },
                },
                reOpened: {
                    label: 'RE-OPENED',
                    count: {
                        '2W': 4,
                        LW: 5,
                        TW: 2,
                    },
                    chart: {
                        '2W': [
                            {
                                name: 'RE-OPENED',
                                series: [
                                    {
                                        name: 'Mon',
                                        value: 6,
                                    },
                                    {
                                        name: 'Tue',
                                        value: 3,
                                    },
                                    {
                                        name: 'Wed',
                                        value: 7,
                                    },
                                    {
                                        name: 'Thu',
                                        value: 5,
                                    },
                                    {
                                        name: 'Fri',
                                        value: 5,
                                    },
                                    {
                                        name: 'Sat',
                                        value: 4,
                                    },
                                    {
                                        name: 'Sun',
                                        value: 7,
                                    },
                                ],
                            },
                        ],
                        LW: [
                            {
                                name: 'RE-OPENED',
                                series: [
                                    {
                                        name: 'Mon',
                                        value: 5,
                                    },
                                    {
                                        name: 'Tue',
                                        value: 7,
                                    },
                                    {
                                        name: 'Wed',
                                        value: 8,
                                    },
                                    {
                                        name: 'Thu',
                                        value: 8,
                                    },
                                    {
                                        name: 'Fri',
                                        value: 6,
                                    },
                                    {
                                        name: 'Sat',
                                        value: 4,
                                    },
                                    {
                                        name: 'Sun',
                                        value: 1,
                                    },
                                ],
                            },
                        ],
                        TW: [
                            {
                                name: 'RE-OPENED',
                                series: [
                                    {
                                        name: 'Mon',
                                        value: 3,
                                    },
                                    {
                                        name: 'Tue',
                                        value: 2,
                                    },
                                    {
                                        name: 'Wed',
                                        value: 1,
                                    },
                                    {
                                        name: 'Thu',
                                        value: 4,
                                    },
                                    {
                                        name: 'Fri',
                                        value: 8,
                                    },
                                    {
                                        name: 'Sat',
                                        value: 8,
                                    },
                                    {
                                        name: 'Sun',
                                        value: 4,
                                    },
                                ],
                            },
                        ],
                    },
                },
                wontFix: {
                    label: "WON'T FIX",
                    count: {
                        '2W': 6,
                        LW: 3,
                        TW: 4,
                    },
                    chart: {
                        '2W': [
                            {
                                name: "WON'T FIX",
                                series: [
                                    {
                                        name: 'Mon',
                                        value: 5,
                                    },
                                    {
                                        name: 'Tue',
                                        value: 7,
                                    },
                                    {
                                        name: 'Wed',
                                        value: 4,
                                    },
                                    {
                                        name: 'Thu',
                                        value: 6,
                                    },
                                    {
                                        name: 'Fri',
                                        value: 5,
                                    },
                                    {
                                        name: 'Sat',
                                        value: 3,
                                    },
                                    {
                                        name: 'Sun',
                                        value: 2,
                                    },
                                ],
                            },
                        ],
                        LW: [
                            {
                                name: "WON'T FIX",
                                series: [
                                    {
                                        name: 'Mon',
                                        value: 6,
                                    },
                                    {
                                        name: 'Tue',
                                        value: 3,
                                    },
                                    {
                                        name: 'Wed',
                                        value: 7,
                                    },
                                    {
                                        name: 'Thu',
                                        value: 5,
                                    },
                                    {
                                        name: 'Fri',
                                        value: 5,
                                    },
                                    {
                                        name: 'Sat',
                                        value: 4,
                                    },
                                    {
                                        name: 'Sun',
                                        value: 7,
                                    },
                                ],
                            },
                        ],
                        TW: [
                            {
                                name: "WON'T FIX",
                                series: [
                                    {
                                        name: 'Mon',
                                        value: 6,
                                    },
                                    {
                                        name: 'Tue',
                                        value: 5,
                                    },
                                    {
                                        name: 'Wed',
                                        value: 4,
                                    },
                                    {
                                        name: 'Thu',
                                        value: 5,
                                    },
                                    {
                                        name: 'Fri',
                                        value: 7,
                                    },
                                    {
                                        name: 'Sat',
                                        value: 4,
                                    },
                                    {
                                        name: 'Sun',
                                        value: 7,
                                    },
                                ],
                            },
                        ],
                    },
                },
                needsTest: {
                    label: 'NEEDS TEST',
                    count: {
                        '2W': 10,
                        LW: 7,
                        TW: 8,
                    },
                    chart: {
                        '2W': [
                            {
                                name: 'NEEDS TEST',
                                series: [
                                    {
                                        name: 'Mon',
                                        value: 6,
                                    },
                                    {
                                        name: 'Tue',
                                        value: 5,
                                    },
                                    {
                                        name: 'Wed',
                                        value: 4,
                                    },
                                    {
                                        name: 'Thu',
                                        value: 5,
                                    },
                                    {
                                        name: 'Fri',
                                        value: 7,
                                    },
                                    {
                                        name: 'Sat',
                                        value: 4,
                                    },
                                    {
                                        name: 'Sun',
                                        value: 7,
                                    },
                                ],
                            },
                        ],
                        LW: [
                            {
                                name: 'NEEDS TEST',
                                series: [
                                    {
                                        name: 'Mon',
                                        value: 5,
                                    },
                                    {
                                        name: 'Tue',
                                        value: 7,
                                    },
                                    {
                                        name: 'Wed',
                                        value: 8,
                                    },
                                    {
                                        name: 'Thu',
                                        value: 8,
                                    },
                                    {
                                        name: 'Fri',
                                        value: 6,
                                    },
                                    {
                                        name: 'Sat',
                                        value: 4,
                                    },
                                    {
                                        name: 'Sun',
                                        value: 1,
                                    },
                                ],
                            },
                        ],
                        TW: [
                            {
                                name: 'NEEDS TEST',
                                series: [
                                    {
                                        name: 'Mon',
                                        value: 6,
                                    },
                                    {
                                        name: 'Tue',
                                        value: 3,
                                    },
                                    {
                                        name: 'Wed',
                                        value: 7,
                                    },
                                    {
                                        name: 'Thu',
                                        value: 5,
                                    },
                                    {
                                        name: 'Fri',
                                        value: 5,
                                    },
                                    {
                                        name: 'Sat',
                                        value: 4,
                                    },
                                    {
                                        name: 'Sun',
                                        value: 7,
                                    },
                                ],
                            },
                        ],
                    },
                },
                fixed: {
                    label: 'FIXED',
                    count: {
                        '2W': 21,
                        LW: 17,
                        TW: 14,
                    },
                    chart: {
                        '2W': [
                            {
                                name: 'FIXED',
                                series: [
                                    {
                                        name: 'Mon',
                                        value: 5,
                                    },
                                    {
                                        name: 'Tue',
                                        value: 7,
                                    },
                                    {
                                        name: 'Wed',
                                        value: 8,
                                    },
                                    {
                                        name: 'Thu',
                                        value: 8,
                                    },
                                    {
                                        name: 'Fri',
                                        value: 6,
                                    },
                                    {
                                        name: 'Sat',
                                        value: 4,
                                    },
                                    {
                                        name: 'Sun',
                                        value: 1,
                                    },
                                ],
                            },
                        ],
                        LW: [
                            {
                                name: 'FIXED',
                                series: [
                                    {
                                        name: 'Mon',
                                        value: 6,
                                    },
                                    {
                                        name: 'Tue',
                                        value: 5,
                                    },
                                    {
                                        name: 'Wed',
                                        value: 4,
                                    },
                                    {
                                        name: 'Thu',
                                        value: 5,
                                    },
                                    {
                                        name: 'Fri',
                                        value: 7,
                                    },
                                    {
                                        name: 'Sat',
                                        value: 4,
                                    },
                                    {
                                        name: 'Sun',
                                        value: 7,
                                    },
                                ],
                            },
                        ],
                        TW: [
                            {
                                name: 'FIXED',
                                series: [
                                    {
                                        name: 'Mon',
                                        value: 5,
                                    },
                                    {
                                        name: 'Tue',
                                        value: 7,
                                    },
                                    {
                                        name: 'Wed',
                                        value: 4,
                                    },
                                    {
                                        name: 'Thu',
                                        value: 6,
                                    },
                                    {
                                        name: 'Fri',
                                        value: 5,
                                    },
                                    {
                                        name: 'Sat',
                                        value: 3,
                                    },
                                    {
                                        name: 'Sun',
                                        value: 2,
                                    },
                                ],
                            },
                        ],
                    },
                },
            },
        },
    }
}
