import { IFireStoreDocument } from '@biotaware/models-shared-db-document'

export class Collections {
    public static Tenants = 'tenants' // server side only collection root for tenant
}

/**
 * Tenants have Names and ID
 * Names are their user friendly organisation identity
 * They are alphanumeric & hypens only, no spaces, & must start with an a-z alpha character.
 * ID is assigned by GCP Identity Platform when a tenant is created. It is usually the tenant name with a hyphen and a random code suffix
 */

export interface ITenant extends IFireStoreDocument {
    // these are readonly for now, set on creation
    // we _might_ consider creating random tenant Id's and using tenantNames as query/mapping field only
    readonly tenantName: string // lower case name of this Tenant / Organisation identity
    readonly tenantId: string // internal Google tenant ID
    readonly tenantOwnerEmail: string // email address of user that is the principle owner of this tenant account (set on tenant creation, or if primary ownership is changed)

    // editable organisation information can be added here later
    tenantDisplayName: string // Display name of this Tenant / Organisation identity - can be anything the tenant wants
}

export interface IRegisterTenant {
    organisation: string
    fullname: string
    email: string
    password: string
}
