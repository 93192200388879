import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'

import { FuseConfirmDialogComponent } from './confirm-dialog.component'

@NgModule({
    declarations: [FuseConfirmDialogComponent],
    imports: [MatDialogModule, MatButtonModule],
    entryComponents: [FuseConfirmDialogComponent],
    exports: [FuseConfirmDialogComponent],
})
export class FuseConfirmDialogModule {}
