import { NgModule } from '@angular/core'

// shared pipes
import { NgxsSeparateByPipe } from './separateByPipe'
import { NgxsShortNumberPipe } from './shortNumberPipe'
import { NgxsTimeAgoPipe } from './timeAgoPipe'
import { NgxsLocaleNumberPipe } from './localeNumberPipe'
import { NgxsElapsedTimePipe } from './elapsedTimePipe'

@NgModule({
    declarations: [
        //pipes
        NgxsSeparateByPipe,
        NgxsShortNumberPipe,
        NgxsLocaleNumberPipe,
        NgxsElapsedTimePipe,
        NgxsTimeAgoPipe,
    ],
    imports: [],
    providers: [
        NgxsElapsedTimePipe, // allow this one to be injectable
    ],
    exports: [
        // shared pipes
        NgxsSeparateByPipe,
        NgxsShortNumberPipe,
        NgxsLocaleNumberPipe,
        NgxsElapsedTimePipe,
        NgxsTimeAgoPipe,
    ],
})
export class NgxsPipesModule {}
