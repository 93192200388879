import { Injectable } from '@angular/core'
import { AngularFirestoreDocument } from '@angular/fire/firestore'
import * as bigquerymodel from '@biotaware/models-shared-db-bigquery'
import { Subscription } from 'rxjs'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { NgxsAuthenticationService } from '@biotaware/ngx-server'
import { NgxsEnvironmentService } from '@biotaware/ngx-system'

interface SubscribeFunction {
    (results: any[]): void
}

/** Service tier for working with metrics */
@Injectable()
export class MetricsService {
    private subscription: Subscription

    constructor(private _environment: NgxsEnvironmentService, private authenticationService: NgxsAuthenticationService, private http: HttpClient) {
        //...
    }

    /**
     * Run a HTTP metric query request
     * @param urlPath The url rest path of the query
     * @param params A JSON object containing query parameters
     * @returns Promise of string array (return result rows)
     */
    runQuery(urlPath: string, params: any): Promise<string[]> {
        return new Promise<any>((resolve, reject) => {
            const p = new HttpParams({ fromObject: params }).toString()

            const headers = new HttpHeaders().set('Authorization', 'bearer ' + this.authenticationService.userToken)

            const apiHost = this._environment.getApiHost()
            this.http
                .get<string[]>(apiHost + '/api/v1/metrics/' + urlPath + '?' + p, {
                    headers: headers,
                })
                .subscribe(
                    (data) => {
                        //console.log('*** GET PARTICIPANT COUNT CALLED OK: ' + data)
                        resolve(data)
                    },
                    (error) => {
                        //console.error('*** GET PARTICIPANT COUNT NOT CALLED OK')
                        reject(error)
                    },
                )
        })
    }

    /**
     * Subscribe to a query cache doc
     * @param rootRef The root location that will contain the 'bigquery-cache' collections
     * @param urlPath The url rest path of the query
     * @param func The function to call when the query cache doc changes
     * @returns The subscription for the doc changes
     */
    subscribe(rootRef: AngularFirestoreDocument, urlPath: string, func: SubscribeFunction) {
        console.log('*** subscribe(): root = ' + rootRef.ref.path)

        // The unique name of the document is the same as the url path except '/' are replaced
        // with '-'
        const name = urlPath.split('/').join('-')

        const sub = rootRef
            .collection(bigquerymodel.Collections.BigQueryCache)
            .doc(name)
            .valueChanges()
            .subscribe((doc: bigquerymodel.IBigQueryCache) => {
                if (doc) {
                    const results = this.getResults(doc.resultRows)
                    func(results)
                }
            })
        return sub
    }

    /**
     * Subscribe to query cache doc and execute query
     * @param rootRef The root location that will contain the 'bigquery-cache' collections
     * @param urlPath The url rest path of the query
     * @param params A JSON object containing query parameters
     * @param func The function to call when the query cache doc changes
     * @returns The subscription for the doc changes
     */
    subscribeRunQuery(rootRef: AngularFirestoreDocument, urlPath: string, params: Record<string, unknown>, func: SubscribeFunction) {
        const subscription = this.subscribe(rootRef, urlPath, func)

        this.runQuery(urlPath, params).then((data) => {
            func(this.getResults(data))
        })

        return subscription
    }

    // Private

    // Return an array of JS objects from a string array
    private getResults(rows: string[]): any[] {
        if (!rows) return []
        return rows.map((js) => JSON.parse(js))
    }
}
