// From https://mat-progress-buttons.firebaseapp.com/home
import { Component, Input, Output, HostListener, EventEmitter, OnChanges, SimpleChanges } from '@angular/core'
import { NgxSpinnerButtonOptions } from './spinner-button.interface'

//SM: This component originally used the "[options]" Input for various customizations, but its a PITA to use
// So I've broken out various options to individual component inputs.

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'app-spinner-button', // was mat-spinner-button
    templateUrl: './spinner-button.component.html',
    styleUrls: ['./spinner-button.component.scss'],
})
export class NgxsSpinnerButtonComponent implements OnChanges {
    @Input() options: NgxSpinnerButtonOptions = {
        active: false,
        text: '',

        spinnerSize: 19,
        customClass: '', //"device-button w-25",
        raised: true,
        stroked: false,
        buttonColor: 'accent',
        spinnerColor: 'primary',
        fullWidth: true,
        disabled: false,
        mode: 'indeterminate',
        buttonIcon: {
            fontIcon: '',
            inline: false,
        },
    }

    @Input() active: boolean
    @Input() disabled: boolean
    @Input() icon: string
    @Input() buttonColor = 'accent'
    @Input() customClass = ''

    defaultOptions: NgxSpinnerButtonOptions = {
        active: false,
        text: '',

        spinnerSize: 19,
        customClass: '', //"device-button w-25",
        raised: true,
        stroked: false,
        buttonColor: 'accent',
        spinnerColor: 'primary',
        fullWidth: true,
        disabled: false,
        mode: 'indeterminate',
        buttonIcon: {
            fontIcon: '',
            inline: false,
        },
    }

    @Output() btnClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>()
    @HostListener('click', ['$event'])
    public onClick(event: MouseEvent) {
        if (!this.options.disabled && !this.options.active) {
            this.btnClick.emit(event)
        }
    }
    ngOnChanges(changes: SimpleChanges) {
        /*
    if (changes.active) {
      this.options.active = changes.active.currentValue;
    }
    if (changes.disabled) {
      this.options.disabled = changes.disabled.currentValue;
    }
   if (changes.icon) {
      console.log("icon now " + changes.icon.currentValue);
      this.options.buttonIcon.fontIcon = changes.icon.currentValue;
    }
  */
    }
}
