import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { FuseSharedModule } from '../../../../../fuse/src/lib/shared.module'
import { MatCardModule } from '@angular/material/card'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon'

// dependencies
import { NgxsProgressSpinnerModule } from '../progress-spinner/progress-spinner.module'

// Shared app components
import { NgxsPanelComponent } from './panel.component'

@NgModule({
    imports: [CommonModule, FuseSharedModule, MatCardModule, MatExpansionModule, MatIconModule, NgxsProgressSpinnerModule],
    declarations: [NgxsPanelComponent],
    exports: [NgxsPanelComponent],
})
export class NgxsPanelModule {}
