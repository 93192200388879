import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { FuseWidgetModule } from '@biotaware/ngx-fuse'
import { NgxChartsModule } from '@swimlane/ngx-charts'

// Shared app components
import { BiotaSenseLineChartComponent } from './biotasense-line-chart.component'

@NgModule({
    imports: [CommonModule, FuseWidgetModule, NgxChartsModule],
    declarations: [BiotaSenseLineChartComponent],
    exports: [BiotaSenseLineChartComponent],
})
export class BiotaSenseLineChartModule {}
