import firebase from 'firebase/app'

function getTimeStamp(date: Date = new Date()): FirebaseFirestore.Timestamp {
    return firebase.firestore.Timestamp.fromDate(date)
}

/**
 * Helper function to return a readable date string given a JS date object
 */
function readableDate(date: Date) {
    let hours = date.getHours() % 12
    hours = hours ? hours : 12

    const time = ('00' + hours).slice(-2) + ':' + ('00' + date.getMinutes()).slice(-2) + ' ' + (date.getHours() >= 12 ? 'PM' : 'AM')

    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][date.getMonth()]
    const day = ('00' + date.getDate()).slice(-2)
    const year = date.getFullYear()

    const readable = time + ', ' + day + ' ' + month + ' ' + year

    /*

	const readable =  
	    ("00" + hours).slice(-2) + ":" + 
	    ("00" + date.getMinutes()).slice(-2) + " " + (date.getHours() >= 12 ? 'PM' : 'AM') + " " +
			['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][date.getMonth()] + " " + 
	    ("00" + date.getDate()).slice(-2) + " " + 
		date.getFullYear(); 
		
	*/

    return readable
}

/**
 * Helper function to return a JS Date object given a device date string
 * of the form: "dd/mm/yyyy hh:mm:ss"
 */
function deviceDateToDate(deviceDate: string) {
    //	s = "05/12/2020 11:55:17"

    const d = new Date()
    d.setFullYear(+deviceDate.slice(6, 10), (+deviceDate.slice(3, 5) - 1) % 12, +deviceDate.slice(0, 2))

    d.setHours(+deviceDate.slice(11, 13))
    d.setMinutes(+deviceDate.slice(14, 16))
    d.setSeconds(+deviceDate.slice(17, 19))

    return d
}

/**
 * Given a device timestamp string of 'DD/MM/YYYY HH:MM:SS'
 * Return a UTC firebase timestamp
 * Assumes all devices are always configured to use UTC time zone.
 * @param t
 */
function deviceDateToTimeStamp(t: string): FirebaseFirestore.Timestamp {
    const date = deviceDateToDate(t)
    return getTimeStamp(date)
}

/**
 * Given an ISO 8601 formatted timestamp string, convert it to a UTC firebase timestamp.
 * @param t
 */
function isoDateToTimeStamp(t: string): FirebaseFirestore.Timestamp {
    if (!t) {
        return getTimeStamp(new Date(Date.now()))
    } else {
        const date = new Date(t)
        return getTimeStamp(date)
    }
}

/**
 * Return a string representation of elapsed time
 * @param date
 */
//SM: replaced by time-ago-pipe npm package
/*
function timeSince(date:Date) : string
{
	const elapsedTime = Date.now() - date.getTime()
	const seconds = Math.floor(elapsedTime / 1000)

	let interval = Math.floor(seconds / 31536000)

	if (interval > 1) {
		return interval + " years"
    } else if (interval === 1) {
		return interval + " year"
    }

	interval = Math.floor(seconds / 2592000)
	if (interval > 1) {
		return interval + " months"
    } else if (interval === 1) {
		return interval + " month"
    }

    interval = Math.floor(seconds / 86400)
	if (interval > 1) {
		return interval + " days"
    } else if (interval === 1) {
		return interval + " day"
    }

    interval = Math.floor(seconds / 3600)
	if (interval > 1) {
		return interval + " hrs"
    } else if (interval === 1) {
		return interval + " hr"
    }

    interval = Math.floor(seconds / 60)
	if (interval > 1) {
		return interval + " mins"
    } else if (interval === 1) {
		return interval + " min"
    }

    interval = Math.floor(seconds)
	if (interval > 1) {
        return interval + " secs"
	}
	
    if (interval === 0) {
		return 'just now'
	}

    return interval + " sec"
}
*/
export { getTimeStamp, readableDate, deviceDateToDate, deviceDateToTimeStamp, isoDateToTimeStamp }
