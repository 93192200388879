<fuse-widget class="widget">

  <!-- Front -->
  <div class="fuse-widget-front">

    <!-- header -->
    <div class="pl-16 pr-8 py-16 h-12" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="h3" *ngIf="widgetTitle !== ''">{{widgetTitle}}</div>
      <button mat-icon-button fuseWidgetToggle aria-label="more" *ngIf="dataInfo !== ''">
        <mat-icon>info</mat-icon>
      </button>
    </div>

    <!-- Data count -->
    <div class="pt-8 pb-24" fxLayout="column" fxLayoutAlign="center center">
      <div [ngClass]="dataColour" class="font-size-44 line-height-44">
        {{ widgetValue === null ? '---' : widgetValue }}
      </div>
      <div class="h3 secondary-text font-weight-500">{{dataLabel}}
      </div>
    </div>

  </div>

  <!-- / Front -->

  <!-- Back -->
  <div class="fuse-widget-back p-16 pt-32" *ngIf="dataInfo !== ''">
    <button mat-icon-button fuseWidgetToggle class="fuse-widget-flip-button" aria-label="Flip widget">
      <mat-icon class="s-16">close</mat-icon>
    </button>

    <div>
      {{dataInfo}}
    </div>
  </div>
  <!-- / Back -->

</fuse-widget>