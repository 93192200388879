import { NgModule } from '@angular/core'

// Shared app components
import { BiotaSenseSubjectPinModule } from './biotasense-subjectpin/biotasense-subjectpin.module'
import { BiotaSenseDataWidgetModule as BiotaSenseDataWidgetModule } from './biotasense-data-widget/biotasense-data-widget.module'
import { BiotasenseTrendWidgetModule as BiotaSenseTrendWidgetModule } from './biotasense-trend-widget/biotasense-trend-widget.module'
import { BiotaSenseGraphWidgetModule as BiotaSenseGraphWidgetModule } from './biotasense-graph-widget/biotasense-graph-widget.module'
import { BiotaSenseAnalysisWidgetModule } from './biotasense-analysis-widget/biotasense-analysis-widget.module'
import { BiotaSenseLineChartModule } from './biotasense-line-chart/biotasense-line-chart.module'
import { DataChartModule } from './data-chart/data-chart.module'
import { TimeSeriesChartModule } from './timeseries-chart/timeseries-chart.module'
import { BiotaSenseSimpleDataWidgetModule } from './biotasense-simple-data-widget/biotasense-simple-data-widget.module'
import { TimeSeriesChartSimpleModule } from './timeseries-chart-simple/timeseries-chart-simple.module'

@NgModule({
    imports: [
        BiotaSenseSubjectPinModule,
        BiotaSenseDataWidgetModule,
        BiotaSenseSimpleDataWidgetModule,
        BiotaSenseTrendWidgetModule,
        BiotaSenseGraphWidgetModule,
        BiotaSenseAnalysisWidgetModule,
        BiotaSenseLineChartModule,
        TimeSeriesChartModule,
        TimeSeriesChartSimpleModule,
        DataChartModule,
    ],
    declarations: [],
    entryComponents: [],
    exports: [
        BiotaSenseSubjectPinModule,
        BiotaSenseDataWidgetModule,
        BiotaSenseSimpleDataWidgetModule,
        BiotaSenseTrendWidgetModule,
        BiotaSenseGraphWidgetModule,
        BiotaSenseAnalysisWidgetModule,
        BiotaSenseLineChartModule,
        TimeSeriesChartModule,
        TimeSeriesChartSimpleModule,
        DataChartModule,
    ],
    providers: [],
})
export class MainComponentsModule {}
