
<!-- panels are material cards with 8 pixel top and bottom margins. Horizontal spacing is the users choice.
	they wrap contained content and will show a progress spinner if the panel isLoading flag is set.
	they can also have panelIcon, panelTitle and panelSubtitle properties set on them.
	panelType can be either "card" or "panel"
-->

<!-- CARD PANEL TYPE -->
<mat-card *ngIf="panelType == 'card'" class="my-8">
	<mat-card-title *ngIf="panelTitle != ''">
		<div fxLayout="row" fxLayoutAlign="start center">
			<div *ngIf="panelIcon != ''">
				<mat-icon>{{ panelIcon }}</mat-icon>
			</div>
			<span>&nbsp;{{ panelTitle }}</span>
		</div>
	</mat-card-title>
	<mat-card-subtitle *ngIf="panelSubtitle != ''">{{ panelSubtitle }}</mat-card-subtitle>
	<!--

	<mat-divider *ngIf="panelTitle != ''"></mat-divider>
	-->
	<mat-card-content>

		<div *ngIf="isLoading"><app-progress-spinner [isLoading]="isLoading"></app-progress-spinner></div>
		<div *ngIf="!isLoading">
			<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
		</div>
	<!--

		<ng-content  *ngIf="!isLoading"></ng-content>
	-->
	</mat-card-content>
</mat-card>

<!-- EXPANSION PANEL TYPE -->
<mat-expansion-panel *ngIf="panelType == 'panel'" [expanded]="!isCollapsed"  class="my-8">

	<mat-expansion-panel-header>
		<mat-panel-title *ngIf="panelTitle != ''">
			<h2>
				<div fxLayout="row" fxLayoutAlign="start center">
					<mat-icon *ngIf="panelIcon != ''">{{ panelIcon }}</mat-icon>
					<span>&nbsp;{{ panelTitle }}</span>
				</div>
			</h2>
		</mat-panel-title>
		<mat-panel-description *ngIf="panelSubtitle != ''">{{ panelSubtitle }}</mat-panel-description>
	</mat-expansion-panel-header>

	<div *ngIf="isLoading">
		<app-progress-spinner [isLoading]="isLoading"></app-progress-spinner>
	</div>
	<div *ngIf="!isLoading">
		<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
	</div>

</mat-expansion-panel>

<!-- output template needed because ng-content cannot be used twice -->
<ng-template #contentTpl><ng-content></ng-content></ng-template>






<!--

<div *ngIf="panelTitle != ''" class="content-full">
	<h1 class="content-heading"><i id="icon" [class]="panelIcon ? 'fa ' + panelIcon : 'hidden'" aria-hidden="true"></i> {{ panelTitle }}</h1>
	<p *ngIf="panelSubtitle != ''"><strong class="content-panel-subtitle">{{ panelSubtitle }}</strong></p>
</div>

<div class="content-full" *ngIf="!isLoading"><app-progress-spinner [isLoading]="isLoading"></app-progress-spinner></div>

<ng-content *ngIf="!isLoading"></ng-content>

<div class="content-panel-footer content-full" [class.hidden]="disableFooterWhenLoading && isLoading">
	<ng-content select="footer"></ng-content>
</div>
-->