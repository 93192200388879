import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { FuseSharedModule } from '@biotaware/ngx-fuse'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'

// dependencies
import { NgxsBreadcrumbModule } from '../breadcrumb/breadcrumb.module'

// Shared app components
import { NgxsPageHeaderComponent } from './page-header.component'

@NgModule({
    imports: [CommonModule, FuseSharedModule, MatIconModule, MatButtonModule, NgxsBreadcrumbModule],
    declarations: [NgxsPageHeaderComponent],
    exports: [NgxsPageHeaderComponent],
})
export class NgxsPageHeaderModule {}
