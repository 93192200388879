import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/firestore'
import * as compliancemodel from '@biotaware/models-biotasense-db-biotasense/src/ComplianceMetrics.model'
import { Subscription, Subject } from 'rxjs'
import { format, addDays, differenceInCalendarDays, differenceInDays } from 'date-fns'

export interface IComplianceStats {
    numDays: number
    numPending: number
    numPossibleCompliants: number
    numCompliants: number
    percCompliants: string
}

/** Service tier for working with Compliance */
@Injectable()
export class ComplianceService {
    private subscription: Subscription

    collection = (userId: string) =>
        this.db.collection(compliancemodel.Collections.ComplianceMetrics).doc(userId).collection(compliancemodel.Collections.UserComplianceMetrics)
    reference = (userId: string, date: string) => this.collection(userId).doc(date)

    constructor(private db: AngularFirestore) {}

    public subscribe(
        userId: string,
        startDate: Date,
        endDate: Date,
        complianceMetrics: compliancemodel.IComplianceMetrics[],
        stats: IComplianceStats,
        func: () => void = () => {
            /*...*/
        },
    ) {
        if (this.subscription) {
            this.subscription.unsubscribe()
        }

        this.subscription = this.collection(userId)
            .valueChanges()
            .subscribe((docs) => {
                // Reset stats
                stats.numDays = differenceInCalendarDays(endDate, startDate) + 1
                stats.numPending = 0
                stats.numPossibleCompliants = 0
                stats.numCompliants = 0
                stats.percCompliants = '0%'

                const startDateStr = format(startDate, 'yyyy-MM-dd')
                const endDateStr = format(endDate, 'yyyy-MM-dd')

                complianceMetrics.length = 0
                let currentDateStr = startDateStr

                for (const doc of docs) {
                    const compliance = doc as compliancemodel.IComplianceMetrics

                    if (compliance.datetime >= startDateStr) {
                        while (currentDateStr < compliance.datetime) {
                            complianceMetrics.push({
                                _version: 1,
                                fitbitId: userId,
                                datetime: currentDateStr,
                                activity: compliancemodel.ComplianceState.Missing,
                                sleep: compliancemodel.ComplianceState.Missing,
                                heart: compliancemodel.ComplianceState.Missing,
                                traceMarker: false,
                                createdAt: null,
                                updatedAt: null,
                            })
                            currentDateStr = format(addDays(new Date(currentDateStr), 1), 'yyyy-MM-dd')
                        }

                        complianceMetrics.push(compliance)

                        // Stats
                        if (
                            compliance.activity !== compliancemodel.ComplianceState.Pending &&
                            compliance.heart !== compliancemodel.ComplianceState.Pending &&
                            compliance.sleep !== compliancemodel.ComplianceState.Pending
                        ) {
                            // Not pending, possible compliants
                            stats.numPossibleCompliants++

                            if (
                                compliance.activity === compliancemodel.ComplianceState.Compliant &&
                                compliance.heart === compliancemodel.ComplianceState.Compliant &&
                                compliance.sleep === compliancemodel.ComplianceState.Compliant
                            ) {
                                stats.numCompliants++
                            }
                        } else {
                            stats.numPending++
                        }

                        currentDateStr = format(addDays(new Date(currentDateStr), 1), 'yyyy-MM-dd')
                    }
                }

                while (currentDateStr <= endDateStr) {
                    stats.numPossibleCompliants++

                    complianceMetrics.push({
                        _version: 1,
                        fitbitId: userId,
                        datetime: currentDateStr,
                        activity: compliancemodel.ComplianceState.Missing,
                        sleep: compliancemodel.ComplianceState.Missing,
                        heart: compliancemodel.ComplianceState.Missing,
                        traceMarker: false,
                        createdAt: null,
                        updatedAt: null,
                    })
                    currentDateStr = format(addDays(new Date(currentDateStr), 1), 'yyyy-MM-dd')
                }

                // Make sure they're in datetime order
                complianceMetrics.sort((a, b) => {
                    if (a.datetime < b.datetime) return -1
                    if (a.datetime > b.datetime) return 1
                    return 0
                })

                if (stats.numPossibleCompliants > 0) {
                    const pcomp = (stats.numCompliants / stats.numPossibleCompliants) * 100.0

                    stats.percCompliants = pcomp.toFixed(2) + '%'
                }

                func()
            })
    }

    public unsubscribe() {
        if (this.subscription) {
            this.subscription.unsubscribe()
            this.subscription = null
        }
    }
}
