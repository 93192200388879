import {
    Component,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ViewChild,
    ComponentFactoryResolver,
    ApplicationRef,
    Injector,
    Input,
    ChangeDetectionStrategy,
} from '@angular/core'
import { CdkPortal, DomPortalOutlet } from '@angular/cdk/portal'

/**
 * Portal Component Base class
 * Allows new components to easily declare portals within their templates and render them inside a DOM host
 *  without needing the Cdk Portal boilerplate in their component code.
 * See https://blog.bitsrc.io/component-inheritance-in-angular-acd1215d5dd8 for component inheritance guidelines
 *
 * To create your portal component:
 * In your host component declare a div with a unique Id, eg.
 * ```
 * <div id="host-container"></div>
 * ```
 * Then in your custom component declare the portal content to be injected into the host:
 * ```
 * <app-portal hostId="#host-container">
 *  <p>portal component!</p>
 * </app-portal>
 * ``
 *
 * Portals are injected directly as children of the parent container
 *
 */
@Component({
    selector: 'app-portal',
    template: `
        <ng-container *cdkPortal>
            <ng-content></ng-content>
        </ng-container>
    `,
    //changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxsPortalComponent implements AfterViewInit, OnDestroy {
    @Input() hostId: string
    @ViewChild(CdkPortal)
    private portal: CdkPortal
    private host: DomPortalOutlet

    constructor(protected componentFactoryResolver: ComponentFactoryResolver, protected applicationRef: ApplicationRef, protected injector: Injector) {
        console.log('*** NgxsPortalComponent created')
    }

    setHostElementId(hostId: string) {
        this.hostId = hostId
    }

    ngAfterViewInit() {
        console.log('*** NGXSPORTALCOMPONENT ngAfterViewInit()')
        if (!this.hostId) {
            throw Error("'hostId' attribute not set on NgxsPortalComponent instance")
        }
        //console.log(document.querySelector(this.hostId));
        this.host = new DomPortalOutlet(document.querySelector(this.hostId)!, this.componentFactoryResolver, this.applicationRef, this.injector)
        this.host.attach(this.portal)
    }

    ngOnDestroy() {
        this.host.detach()
    }
}
