import { Component, Inject, ViewEncapsulation, NgZone } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { MatColors } from '@biotaware/ngx-fuse'
import { StudyService } from '../../../../main/services/study.service'
import { IStudy } from '@biotaware/models-biotasense-db-biotasense/src/Study.model'
import { FirestoreService } from '../../../../main/services/firebase.service'
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar'
import { Router } from '@angular/router'

@Component({
    selector: 'event-form-dialog',
    templateUrl: './edit-study-form.component.html',
    styleUrls: ['./edit-study-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EditStudyDialogComponent {
    action: string
    eventForm: FormGroup
    dialogTitle: string
    presetColors = MatColors.presets
    title = ''

    private _snackBarConfig: MatSnackBarConfig = {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
    }

    /**
     * Constructor
     *
     * @param {MatDialogRef<NewStudyDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _router: Router,
        public matDialogRef: MatDialogRef<EditStudyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private studyService: StudyService,
        private firebase: FirestoreService,
        private ngZone: NgZone,
        private _snackBar: MatSnackBar,
    ) {
        this.dialogTitle = 'Edit study name'

        this.eventForm = this.createEventForm()

        this.eventForm.get('studyName').setValue(_data.studyName)
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    editStudy(): void {
        const name = this.eventForm.get('studyName').value.trim()

        // Don't allow empty names
        if (name === '') {
            this._snackBar.open('Empty study name not allowed', '', this._snackBarConfig)
            return
        }

        this.studyService.renameStudy(name).then((renamed) => {
            if (renamed) {
                // Show OK snackbar, close dialog and navigate to new study
                this._snackBar.open("Study renamed to '" + name + "'.", '', this._snackBarConfig)
                this.matDialogRef.close(this.eventForm)
                return
            }

            // Couldn't create show NOT OK snack bar and do not close dialog
            this._snackBar.open("'" + name + "' unable to rename.", '', this._snackBarConfig)
        })
    }

    /**
     * Create the event form
     *
     * @returns {FormGroup}
     */
    createEventForm(): FormGroup {
        return new FormGroup({
            studyName: new FormControl(this.title),
        })
    }
}
