import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { HttpClientModule } from '@angular/common/http'
import { TranslateModule } from '@ngx-translate/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
//import { RouterModule, Routes } from '@angular/router';

// Environment for API keys etc.
import { environment } from '../environments/environment'

// Fuse
import { FuseModule } from '@biotaware/ngx-fuse'
import { FuseAppModule } from '@biotaware/ngx-fuse'
import { FuseSharedModule } from '@biotaware/ngx-fuse'

// App Setup Modules
import { fuseConfig } from './fuse-config'
import { navigation } from './navigation/navigation'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'

// System shared core module
import { NgxsSystemModule } from '@biotaware/ngx-system'
import { NgxsServerModule } from '@biotaware/ngx-server'

// Project specific modules
// Main app module
import { MainModule } from './main/main.module'

import { ToolbarContentModule } from './main/core/toolbar-content/toolbar-content.module'

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        //RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
        AppRoutingModule,

        TranslateModule.forRoot(),

        // Fuse modules
        FuseModule.forRoot(fuseConfig, navigation),
        FuseAppModule,
        FuseSharedModule,

        // System core module
        NgxsSystemModule.forRoot(environment),
        // Firebase core module
        NgxsServerModule.forRoot(environment.firebase, true),

        // App specific modules
        // SampleModule,
        // AppStoreModule
        MainModule,
        ToolbarContentModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
