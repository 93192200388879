/**
 * Multi tenancy services
 * This service is layered upon AuthenticationService
 *
 */
import { Injectable, Inject } from '@angular/core'
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore'
import { BehaviorSubject } from 'rxjs'

import { Collections } from '@biotaware/models-shared-db-accounts/src/Tenant.model'

export const MULTITENANT_TOKEN = 'multi-tenant'

@Injectable({ providedIn: 'root' })
export class NgxsTenantService {
    // tenants have their own root collection on the db
    private _tenantDbRef: AngularFirestoreDocument | undefined

    // tenantId subject
    private readonly _tenantId = new BehaviorSubject<string>(null)

    // tenantName
    private _tenantName = ''

    // tenant service only runs if multiTenant is set when FirebaseModule is initialized
    constructor(private _firestore: AngularFirestore, @Inject(MULTITENANT_TOKEN) private _isMultiTenant: boolean) {
        console.log('TenantService ctor')
    }

    /**
     * @returns true if this project is set as multi-tenant authentication in the environment config
     */
    isMultiTenant() {
        return this._isMultiTenant
    }

    // Public tenantId observable & snapshot, subscribe for change events
    //public readonly tenantId$ = this._tenantId.asObservable()
    public get onTenantIdChanged() {
        return this._tenantId.asObservable()
    }

    /**
     * Return the currently set tenantId
     */
    public get tenantId() {
        return this._tenantId.value
    }

    /**
     * Return the current set tenantName
     */
    public get tenantName() {
        return this._tenantName
    }

    /**
     * Set Tenant - sets future tenant access
     * @param tenantId The google cloud ID for the tenant
     * @param tenantName The display/subdomain/login name of the tenant
     *
     * Subscribers to the tenantId will be notified accordingly
     */
    setTenant(tenantId: string | null): void {
        if (tenantId) {
            // Google creates tenantId's as the tenant name with a unique ID suffix
            // So it turns out we are able to determine the tenant name from the tenant ID
            const tenantName = tenantId.substr(0, tenantId.lastIndexOf('-'))
            this._tenantName = tenantName
            console.log("*** TENANT SERVICE: Tenant Id '" + tenantId + "', Name '" + tenantName + "'")
            this._tenantDbRef = this._firestore.collection(Collections.Tenants).doc(tenantId)
            this._tenantId.next(tenantId)
        } else {
            console.log('*** TENANT SERVICE: No tenant')
            this._tenantName = ''
            this._tenantDbRef = null
            this._tenantId.next(null)
        }
    }

    /**
     * Clear the tenant info
     */
    clearTenant() {
        this.setTenant(null)
    }

    /**
     * Return the tenant document.
     * Use the tenant document as a base to access all other tenant collections
     * @throws Exception if no tenant has been set before calling this method
     */
    get db(): AngularFirestoreDocument {
        if (!this._tenantDbRef) {
            throw Error('TenantService - accessing tenant db without valid tenant set.')
        }
        console.log('tenantService db()=' + this._tenantDbRef.ref.path)
        return this._tenantDbRef
    }
}
