import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FlexLayoutModule } from '@angular/flex-layout'

import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'

import { NgxsPortalModule } from '@biotaware/ngx-system'

import { ToolbarContentComponent } from './toolbar-content.component'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatDividerModule } from '@angular/material/divider'
import { NewStudyDialogComponent } from './new-study/new-study-form.component'
import { FuseConfirmDialogModule, FuseSharedModule } from '@biotaware/ngx-fuse'
import { MatInputModule } from '@angular/material/input'
import { EditStudyDialogComponent } from './edit-study/edit-study-form.component'

@NgModule({
    declarations: [ToolbarContentComponent, NewStudyDialogComponent, EditStudyDialogComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        FlexLayoutModule,

        MatCheckboxModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatToolbarModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatDividerModule,

        NgxsPortalModule,

        FuseSharedModule,
        FuseConfirmDialogModule,
    ],
    exports: [ToolbarContentComponent],
    entryComponents: [NewStudyDialogComponent, EditStudyDialogComponent],
})
export class ToolbarContentModule {}
