import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { FuseSharedModule } from '@biotaware/ngx-fuse'
import { FuseWidgetModule } from '@biotaware/ngx-fuse'

import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'

// Shared app components
import { BiotaSenseDataWidgetComponent } from './biotasense-data-widget.component'

@NgModule({
    imports: [CommonModule, FuseSharedModule, FuseWidgetModule, MatFormFieldModule, MatSelectModule, MatButtonModule, MatIconModule],
    declarations: [BiotaSenseDataWidgetComponent],
    exports: [BiotaSenseDataWidgetComponent],
})
export class BiotaSenseDataWidgetModule {}
