/**
 * Returns a string representing the elapsed time string between two dates
 */

import { Pipe, PipeTransform } from '@angular/core'

// Specify elapsed time as:
// 'long' - "years"/"months"/"days"/"weeks"/"hours"/"minutes"/"seconds"
// 'medium' -
type ElapsedTimePipeFormats = 'short' | 'medium' | 'long'
type ElapsedTimePipeFormat = {
    years: string
    months: string
    weeks: string
    days: string
    hours: string
    minutes: string
    seconds: string
}

const TimeFormats: { [k in ElapsedTimePipeFormats]: ElapsedTimePipeFormat } = {
    long: {
        years: ' years',
        months: ' months',
        weeks: ' weeks',
        days: ' days',
        hours: ' hours',
        minutes: ' minutes',
        seconds: ' seconds',
    },
    medium: {
        years: ' yrs',
        months: ' mnths',
        weeks: ' wks',
        days: ' days',
        hours: ' hrs',
        minutes: ' mins',
        seconds: ' secs',
    },
    // no spacer for short format
    short: {
        years: 'y',
        months: 'mnth',
        weeks: 'w',
        days: 'd',
        hours: 'h',
        minutes: 'm',
        seconds: 's',
    },
}

@Pipe({
    name: 'elapsedTime',
})
export class NgxsElapsedTimePipe implements PipeTransform {
    /**
     * Convert deltaMs to a string representation of elapsed time
     * @param deltaMs - elapsed time period in milliseconds
     * @param format - formatting string, can provide optional precision and/or format in "<precision numeric>,<format type - short / medium / long>"
     */
    transform(deltaMs: number, format: string = '1,medium'): string {
        if (isNaN(deltaMs)) return null // will only work if value is a number
        if (deltaMs === null) return null

        let precision = 1
        let formatLength: ElapsedTimePipeFormats = 'medium'

        // sanitize the input, could be just a precision number
        if (typeof format !== 'string') {
            if (typeof format === 'number') {
                const p = parseInt(format)
                if (isNaN(p)) return 'Bad Pipe Format'

                precision = p
            }
        } else {
            const params = format.split(',')
            for (const p of params) {
                const asNumber = parseInt(p)
                const isNumber = !isNaN(asNumber)
                if (isNumber) precision = asNumber
                else if (TimeFormats[p] !== undefined) formatLength = p as ElapsedTimePipeFormats
            }
        }

        const formatTable = TimeFormats[formatLength]

        const seconds = deltaMs / 1000
        const minutes = seconds / 60
        const hours = minutes / 60
        const days = hours / 24
        const weeks = days / 7
        const months = weeks * (12 / 52)
        const years = months / 12

        const options: Intl.NumberFormatOptions = {
            maximumFractionDigits: precision,
        }

        if (seconds < 60) {
            return seconds.toLocaleString('en-US', options) + formatTable.seconds //" secs"
        }
        if (minutes < 60) {
            return minutes.toLocaleString('en-US', options) + formatTable.minutes //" mins"
        }
        if (hours < 24) {
            return hours.toLocaleString('en-US', options) + formatTable.hours //" hours"
        }
        if (days < 21) {
            return days.toLocaleString('en-US', options) + formatTable.days //" days"
        }
        if (weeks < 5) {
            return weeks.toLocaleString('en-US', options) + formatTable.weeks //" weeks"
        }
        if (months < 12) {
            return months.toLocaleString('en-US', options) + formatTable.months //" months"
        }
        return years.toLocaleString('en-US', options) + formatTable.years //" years"
    }
}
