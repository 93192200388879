/**
 * Custom Firebase Authentication Route Guard
 */
import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router'

import { Observable, of } from 'rxjs'

import { AngularFireAuthGuard, hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard'
import { NgxsAuthenticationService, AuthenticationState } from './authentication.service'
import { AngularFireAuth } from '@angular/fire/auth'

@Injectable({ providedIn: 'root' }) // singleton service, so no need to add as root module provider //implements CanActivate {
export class NgxsAuthGuard extends AngularFireAuthGuard {
    authState$: any

    constructor(
        private _router: Router,
        private _angularFireAuth: AngularFireAuth, //        private _authService: AuthenticationService,
    ) {
        super(_router, _angularFireAuth)
        console.log('AuthGuard service()')
    }

    /*
    canActivate = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree>  => 
    {
        console.log("**** CAN ACTIVATE!")
        const routeStatus = super.canActivate(route, state);

        if (this._authService.isLoggedIn) {

            console.log('IS LOGGED IN');

            // authorised so return true
            return of(true);
        }



        return new Observable<boolean>( (v) => {

            // Unsubscribe if we're subscribed to auth state changes
            if (this.authState$)
            {
                this.authState$.unsubscribe()
            }

            this.authState$ = this._authService.authenticationState.subscribe(a => {

                if (a === AuthenticationState.LoggedIn)
                {
                    console.log("*** RESOLVE LOGGED IN ***")
                    console.log("activateRoute() logged in, checking next");
        
                    const routeOk = true //this.activateRoute(route, state);
                    if (routeOk) {
                        console.log("canActivate() current route is ok");
                    } else {
                        console.log("canActivate() route '" + route.pathFromRoot + "' is not ok");
                    }
                    
                    v.next(routeOk);
                }
                else
                {
                    console.log("*** RESOLVE NOT LOGGED IN ***")
                    this._router.navigate(['/auth/login'])                    
                    v.next(false)
                }
            })
        });


        // not logged in so redirect to login page with the return url
        ////this.router.navigate(['/auth/login'], { preserveFragment: true });//{ queryParams: { returnUrl: state.url } });
        //this._router.createUrlTree(['/auth/login'], { queryParams: { returnUrl: state.url }});
        //return false;
    }
*/
}
