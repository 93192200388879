<div class="widget fuse-card auto-width mb-32" [ngClass.gt-sm]="'mb-0 mr-32'">

    <div class="p-16 pb-0" fxLayout="row wrap" fxLayoutAlign="start end">

        <div class="pr-16">
            <div class="h3 secondary-text">{{widgetTitle}}</div>
            <div class="font-size-54 font-weight-300 line-height-1 mt-8">
                26,000
            </div>
        </div>

        <div class="py-4 font-size-16" fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="row" fxLayoutAlign="start center" class="green-fg">
                <mat-icon class="green-fg mr-4">trending_up</mat-icon>
                <span>5,633</span>
            </div>
            <div class="ml-4 text-nowrap"> per day average</div>
        </div>

        <div class="py-4 font-size-16" fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="row" fxLayoutAlign="start center" class="red-fg">
                 
                <mat-icon class="red-fg mr-4">trending_down</mat-icon>
                <span>8%</span>
            </div>
            <div class="ml-4 text-nowrap">of target</div>
        </div>
    </div>

</div>
