<button mat-button 
  [type]="options.type"
  [color]="buttonColor"
  [class.active]="active"
  [class.fullWidth]="options.fullWidth"
  [class.mat-raised-button]="options.raised"
  [class.mat-stroked-button]="options.stroked"
  [class.mat-flat-button]="options.flat"
  [class.mat-fab]="options.fab"
  [ngClass]="customClass"
  [disabled]="active || disabled">

  <ng-container
    *ngIf="options.buttonIcon">
    <mat-icon
      class="mat-button-icon"
      [class.is-mat-icon]="!options.buttonIcon.fontSet"
      [class.active]="active && !disabled"
      [ngClass]="options.buttonIcon.customClass"
      [fontSet]="options.buttonIcon.fontSet"
      [fontIcon]="options.buttonIcon.fontIcon"
      [color]="options.buttonIcon.color"
      [svgIcon]="options.buttonIcon.svgIcon"
      [inline]="options.buttonIcon.inline">
<!--      {{ options.buttonIcon.fontSet ? '' : options.buttonIcon.fontIcon }} -->
      {{ icon }}
    </mat-icon>
  </ng-container>

  <span
    class="button-text"
    *ngIf="!options.fab"
    [class.active]="active && !disabled">
      {{ options.text }}
      <ng-content></ng-content>
  </span>

  <ng-container
    *ngIf="options.fab && options.icon">
    <mat-icon
      [fontSet]="options.icon.fontSet"
      [fontIcon]="options.icon.fontIcon"
      [color]="options.icon.color"
      [svgIcon]="options.icon.svgIcon"
      [inline]="options.icon.inline">
        {{ options.icon.fontSet ? '' : options.icon.fontIcon }}
    </mat-icon>
  </ng-container>

  <mat-spinner class="spinner"
    *ngIf="active && !disabled"
    [diameter]="options.fab ? 58 : options.spinnerSize"
    [color]="options.spinnerColor"
    [mode]="options.mode"
    [value]="options.value"
    [class.active]="active && !disabled">
  </mat-spinner>
</button>