/**
 * User class
 * Encapsulate all services related to users
 */
import firebase from 'firebase/app'

import { Injectable, Query } from '@angular/core'
import { Observable, Subscription, Subject } from 'rxjs'

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore'

import * as usermodel from '@biotaware/models-shared-db-accounts/src/User.model'
import { NgxsAuthenticationService, NgxsTenantService } from '@biotaware/ngx-server'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { NgxsEnvironmentService } from '@biotaware/ngx-system'

@Injectable()
export class UserService {
    subscriptions: Subscription = new Subscription()

    constructor(
        private firestore: AngularFirestore,
        private tenantService: NgxsTenantService,
        private authenticationService: NgxsAuthenticationService,
        private _environment: NgxsEnvironmentService,
        private http: HttpClient,
    ) {}

    getUserCollection(): AngularFirestoreCollection<usermodel.IUser> {
        return this.tenantService.db.collection(usermodel.Collections.User)
    }

    getUser(userId: string): Promise<usermodel.IUser> {
        const user = this.getUserCollection()
            .doc(userId)
            .get()
            .toPromise()
            .then((u) => {
                return u.data()
            })
        return user
    }

    sendInvite(email: string, role?: string): Promise<any> {
        const tenantId = this.tenantService.tenantId
        const userEmail = this.authenticationService.user.email

        console.log('*** SEND INVITE TO: ' + email + ' FROM ' + userEmail + ' ON TENANT: ' + tenantId)

        return new Promise<any>((resolve, reject) => {
            const headers = new HttpHeaders().set('Authorization', 'bearer ' + this.authenticationService.userToken)

            const apiHost = this._environment.getApiHost()
            this.http
                .post<any>(
                    apiHost + '/api/v1/users/invite',
                    {
                        inviterEmail: userEmail,
                        invitedEmail: email,
                        role: role,
                        tenantId: tenantId,
                    },
                    {
                        headers: headers,
                    },
                )
                .subscribe(
                    (data) => {
                        console.log('*** INVITE POST CALL WAS SUCCESSFUL!')
                        resolve(data)
                    },
                    (error) => {
                        console.log('*** INVITE POST NOT SUCCESSFUL! ' + error.message)
                        reject(error)
                    },
                )
        })
    }
}
