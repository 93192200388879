import { Component, OnInit, Input } from '@angular/core'

@Component({
    selector: 'app-biotasense-trend-widget',
    templateUrl: './biotasense-trend-widget.component.html',
    styleUrls: ['./biotasense-trend-widget.component.scss'],
})
export class BiotaSenseTrendWidgetComponent implements OnInit {
    @Input() widgetTitle = '' // title of the widget

    constructor() {
        /** */
    }

    ngOnInit() {}
}
