/*
 * Public API Surface of @biotaware/ngx-system
 */

// System library exports
export * from './lib/system.module'
export * from './lib/shared.module'

// System Library pipes
export * from './lib/pipes/pipes.module'
export * from './lib/pipes/elapsedTimePipe'
export * from './lib/pipes/localeNumberPipe'
export * from './lib/pipes/separateByPipe'
export * from './lib/pipes/shortNumberPipe'
export * from './lib/pipes/timeAgoPipe'

// System Library components
export * from './lib/components/breadcrumb/breadcrumb.component'
export * from './lib/components/breadcrumb/breadcrumb.module'
export * from './lib/components/confirm/confirm.component'
export * from './lib/components/confirm/confirm.module'
export * from './lib/components/page-header/page-header.component'
export * from './lib/components/page-header/page-header.module'
export * from './lib/components/paged-table/paged-table.module'
export * from './lib/components/panel/panel.component'
export * from './lib/components/panel/panel.module'
export * from './lib/components/progress-bar/progress-bar.component'
export * from './lib/components/progress-bar/progress-bar.module'
export * from './lib/components/progress-dots/progress-dots.component'
export * from './lib/components/progress-dots/progress-dots.module'
export * from './lib/components/progress-spinner/progress-spinner.component'
export * from './lib/components/progress-spinner/progress-spinner.module'
export * from './lib/components/spinner-button/spinner-button.component'
export * from './lib/components/spinner-button/spinner-button.module'
export * from './lib/components/spinner-button/spinner-button.interface'
export * from './lib/components/portal/portal.module'
export * from './lib/components/portal/portal.component'

// System library services
export * from './lib/services/environment.service'

// System library utils
export * from './lib/utils/custompaginator.component'
export * from './lib/utils/dateUtils'
export * from './lib/utils/pagedtable'

export * from './lib/ngx-system.module'
