import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PortalModule } from '@angular/cdk/portal'

import { NgxsPortalComponent } from './portal.component'

@NgModule({
    declarations: [NgxsPortalComponent],
    imports: [CommonModule, PortalModule],
    exports: [NgxsPortalComponent],
})
export class NgxsPortalModule {}
