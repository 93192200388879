// https://gist.githubusercontent.com/shifatul-i/69a2c247a414482e445b1c324b01134b/raw/4c0211bfdb9b544fa7d39c200502026ed6c0bca6/short-number.pipe.ts

import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'shortNumber',
})
export class NgxsShortNumberPipe implements PipeTransform {
    transform(number: number, args?: any): any {
        if (Number.isNaN(number)) return null // will only work value is a number
        if (number === null) return null
        if (number === 0) return 0
        let abs = Math.abs(number)
        const rounder = Math.pow(10, 1)
        const isNegative = number < 0 // will also work for Negetive numbers
        let key = ''

        const powers = [
            { key: 'Q', value: Math.pow(10, 15) },
            { key: 'T', value: Math.pow(10, 12) },
            { key: 'B', value: Math.pow(10, 9) },
            { key: 'M', value: Math.pow(10, 6) },
            { key: 'K', value: 1000 },
        ]

        for (let i = 0; i < powers.length; i++) {
            let reduced = abs / powers[i].value
            reduced = Math.round(reduced * rounder) / rounder
            if (reduced >= 1) {
                abs = reduced
                key = powers[i].key
                break
            }
        }
        return (isNegative ? '-' : '') + abs + key
    }
}
