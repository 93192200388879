/**
 * Participant Document Model
 * CLIENT READABLE
 */

import { IFireStoreDocumentVersioned } from '@biotaware/models-shared-db-document'

export class Collections {
    // Compliance metrics are a sub-collection of "Studies"
    public static ComplianceMetrics = 'compliance-metrics'
    public static UserComplianceMetrics = 'user-compliance-metrics'
}

export enum ComplianceState {
    Missing = 'Missing', // Data missing on client
    NoData = 'NoData', // No data
    Pending = 'Pending', // Data is pending
    Compliant = 'Compliant', // Data is compliant
    Noncompliant = 'Noncompliant', // Data is noncompliant
}

/**
 * Compliance states
 * We'll use this as a partial for updating states
 */
export interface IComplianceStates {
    activity: ComplianceState // Whether we have activity data
    sleep: ComplianceState // Whether we have sleep data
    heart: ComplianceState // Whether we have heart data
}

/**
 * IComplianceMetrics represents an individual days compliance metrics for
 * a participant
 */
export interface IComplianceMetrics extends IFireStoreDocumentVersioned<1> {
    fitbitId: string // The Fitbit firebase ID
    datetime: string // The date (day) in yyyy-MM-dd format
    activity: ComplianceState // Whether we have activity data
    sleep: ComplianceState // Whether we have sleep data
    heart: ComplianceState // Whether we have heart data
    traceMarker: boolean // Flag used for backfilling
}

export interface ComplianceEntry {
    datetime: string
    activity: ComplianceState
    sleep: ComplianceState
    heart: ComplianceState
}
