import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { FuseSharedModule } from '@biotaware/ngx-fuse'

import { MatIconModule } from '@angular/material/icon'

// Shared app components
import { BiotaSenseTrendWidgetComponent } from './biotasense-trend-widget.component'

@NgModule({
    imports: [CommonModule, FuseSharedModule, MatIconModule],
    declarations: [BiotaSenseTrendWidgetComponent],
    exports: [BiotaSenseTrendWidgetComponent],
})
export class BiotasenseTrendWidgetModule {}
