
<!-- WIDGET 5 -->
<fuse-widget class="widget widget5">

    <!-- Front -->
    <div class="fuse-widget-front">

        <div class="px-16 border-bottom" fxLayout="row wrap"
                fxLayoutAlign="space-between center">

            <div fxFlex class="py-16 h3">{{widgetTitle}}</div>

            <div fxFlex="0 1 auto" class="py-16" fxLayout="row" *ngIf="ranges.length > 1">
                <button mat-button class="px-16"
                        *ngFor="let range of widgets.widget5.mainChart | keys"
                        (click)="widget5.currentRange = range.key"
                        [ngClass]="{'accent' : widget5.currentRange == range.key}">
                    {{range.key}}
                </button>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start">

            <div class="h-420 my-16" fxLayout="row" fxFlex="100" fxFlex.gt-sm="50" *ngIf="type == 'line'">
                <ngx-charts-line-chart
                    *fuseIfOnDom
                    animations="false"
                    [scheme]="widget5.scheme"
                    [results]="this.widgets.widget5.mainChart[this.widget5.currentRange]"
                    [gradient]="widget5.gradient"
                    [xAxis]="widget5.xAxis"
                    [yAxis]="widget5.yAxis"
                    [legend]="widget5.legend"
                    [showXAxisLabel]="widget5.showXAxisLabel"
                    [showYAxisLabel]="widget5.showYAxisLabel"
                    [xAxisLabel]="widget5.xAxisLabel"
                    [yAxisLabel]="widget5.yAxisLabel"
                    (select)="widget5.onSelect($event)">
                </ngx-charts-line-chart>
            </div>

            <div class="h-420 my-16" fxLayout="row" fxFlex="100" fxFlex.gt-sm="50" *ngIf="type == 'bar-stacked'">
                <ngx-charts-bar-vertical-stacked
                    *fuseIfOnDom
                    animations="false"
                    [scheme]="widget5.scheme"
                    [results]="this.widgets.widget5.mainChart[this.widget5.currentRange]"
                    [gradient]="widget5.gradient"
                    [xAxis]="widget5.xAxis"
                    [yAxis]="widget5.yAxis"
                    [legend]="widget5.legend"
                    [showXAxisLabel]="widget5.showXAxisLabel"
                    [showYAxisLabel]="widget5.showYAxisLabel"
                    [xAxisLabel]="widget5.xAxisLabel"
                    [yAxisLabel]="widget5.yAxisLabel"
                    (select)="widget5.onSelect($event)">
                </ngx-charts-bar-vertical-stacked>
            </div>
            <div class="my-16" fxFlex="100" fxLayout="row wrap" fxFlex.gt-sm="50">

                <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxLayoutAlign="center"
                        *ngFor="let widget of widgets.widget5.supporting | keys"
                        class="mb-24">

                    <div class="px-24">
                    </div>

                    <div class="h-64" *ngIf="this.widget5.currentRange"> 
                        <ngx-charts-area-chart
                            *fuseIfOnDom
                            [results]="widget.value.chart[widget5.currentRange]"
                            [scheme]="widget5.supporting.scheme"
                            [gradient]="widget5.supporting.gradient"
                            [xAxis]="widget5.supporting.xAxis"
                            [yAxis]="widget5.supporting.yAxis"
                            [legend]="widget5.supporting.legend"
                            [showXAxisLabel]="widget5.supporting.showXAxisLabel"
                            [showYAxisLabel]="widget5.supporting.showYAxisLabel"
                            [xAxisLabel]="widget5.supporting.xAxisLabel"
                            [yAxisLabel]="widget5.supporting.yAxisLabel"
                            [curve]="widget5.supporting.curve">
                        </ngx-charts-area-chart>
                    </div>
                </div> 
            </div>
        </div>
    </div>
    <!-- / Front -->

</fuse-widget>
<!-- / WIDGET 5 -->