/**
 * StudyService is a singleton
 *
 * Manages all studies available to the current tenant
 * Also manages the active study selected by the current user
 *
 * The study is selected as a user session "workspace" so that routes do not need to have an embedded studyId
 *
 */
import { Injectable } from '@angular/core'
import {
    AngularFirestore,
    AngularFirestoreCollection,
    AngularFirestoreDocument,
    DocumentData,
    DocumentSnapshot,
    DocumentChangeAction,
} from '@angular/fire/firestore'
import { NgxsAuthenticationService, NgxsTenantService } from '@biotaware/ngx-server'
import { take, filter, first, distinctUntilChanged } from 'rxjs/operators'
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs'
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router'
import { Md5 } from 'ts-md5'

import * as studymodel from '@biotaware/models-biotasense-db-biotasense/src/Study.model'
import * as _ from 'lodash'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { NgxsEnvironmentService } from '@biotaware/ngx-system'

const LOCAL_STORAGE_STUDY_KEY = 'currentStudy'

/**
 * Study Service
 * Based on the assumption that there will always be at least on study.
 * Handles route resolver and cases where there's no study ID in local storage
 * which can happen when first logging on.  It also handles bad study ID's.
 */

@Injectable({ providedIn: 'root' })
export class StudyService implements Resolve<any> {
    // List of studies available to the currently logged in tenant
    private readonly _studies = new BehaviorSubject<studymodel.IStudy[]>([])

    // The currently selected study data
    private readonly _study = new BehaviorSubject<studymodel.IStudy>(null)

    // firebase document change subscriptions
    private _studiesSub: Subscription = null
    private _studySub: Subscription = null

    // firebase document references for the current tenant's studies & selected study
    private _studiesRef: AngularFirestoreCollection | null = null
    private _studyRef: AngularFirestoreDocument | null = null

    // client observables
    public studies$ = this._studies.asObservable()
    public study$ = this._study.asObservable()

    constructor(
        private _firestore: AngularFirestore,
        private _router: Router,
        private _tenantService: NgxsTenantService,
        private _environment: NgxsEnvironmentService,
        private authenticationService: NgxsAuthenticationService,
        private http: HttpClient,
    ) {
        // Subscribe to changes in tenant Id
        // When it changes, we will update the list of studies we have access to
        this._tenantService.onTenantIdChanged.subscribe((tenantId) => {
            this.onTenantIdChanged(tenantId)
        })
    }

    /**
     * Setup the firebase root studies collection references for the given tenant
     * and fetch all of the study documents available to this tenant
     * Internal method called only by tenant update subscriptions
     * @param tenantId
     * @returns
     */
    private onTenantIdChanged(tenantId: string | null) {
        console.log("StudyService, detected tenantId Updated to '" + tenantId + "'")

        if (!tenantId) {
            // no tenant Id, must be logged out
            console.log('No Tenant ID. So no Studies.')
            this._studiesRef = null
            this._studies.next([])
            this._study.next(null)
            return
        }

        const tenantDb = this._tenantService.db
        const studiesRef = tenantDb.collection(studymodel.Collections.Studies)
        this._studiesRef = studiesRef
        console.log("Set studies for tenant ID to '" + studiesRef.ref.path + "'")

        if (this._studiesSub) {
            this._studiesSub.unsubscribe()
            this._studiesSub = null
        }

        console.log("Subscribing to studies at '" + studiesRef.ref.path + "'")

        // Subscribe indefinitely to all study documents for this tenant
        // This ensures the study service can always provide the current list of available studies to clients
        this._studiesSub = studiesRef.snapshotChanges().subscribe((snapshots) => {
            const studies: studymodel.IStudy[] = snapshots.map((snapshot) => {
                const data = snapshot.payload.doc.data() as studymodel.IStudy
                return data
            })
            // emit latest data to our service observable
            this._studies.next(studies)
        })

        // Setup subscriptions to studies available to this user/tenant
        const defaultStudyId = localStorage.getItem(LOCAL_STORAGE_STUDY_KEY)

        // Do we have a studyId in local storage?
        if (!defaultStudyId) {
            console.log('****** NO STUDY FOUND IN LOCAL STORAGE ******')

            this.studies$
                .pipe(
                    filter((studies) => studies.length > 0),
                    take(1),
                )
                .subscribe((s) => {
                    console.log('****** USING FIRST STUDY ****** ')
                    const defaultStudyId = s[0].studyId
                    localStorage.setItem(LOCAL_STORAGE_STUDY_KEY, defaultStudyId)

                    this.selectStudy(defaultStudyId)
                })
        } else {
            console.log('**** STUDY FOUND IN LOCAL STORAGE ****')
            this.selectStudy(defaultStudyId)
        }
    }

    /**
     * Setup references & fetch the firebase root study document reference for the given studyId
     * If there is no study document for this id study will be marked as null
     * Internal method called only by subscription updates to the selected study Id
     * @param studyId
     * @returns
     */
    private subscribeToStudy(studyId: string | null) {
        console.log("StudyService(), detected studyId Updated to '" + studyId + "'")

        if (this._studySub) {
            this._studySub.unsubscribe()
            this._studySub = null
        }

        if (!studyId) {
            // no study selected, possibly logged out
            console.log('No Study ID currently selected. So no Study loaded.')
            this._studyRef = null
            this._study.next(null)
            return
        }

        console.log("Fetching study '" + studyId + "'")
        const tenantDb = this._tenantService.db
        const studiesRef = tenantDb.collection(studymodel.Collections.Studies)
        const studyRef = studiesRef.doc(studyId)
        this._studyRef = studyRef

        console.log("Subscribing to current selected study at '" + studyRef.ref.path + "'")

        // Subscribe indefinitely to the currently selected study document for this user.
        // This ensures the study service can always provide the current study data to clients.
        // AngularFirestore snapshotChanges() can sometimes trigger twice, once for the cache
        // and once for the server update.  The code here makes sure that a trigger only
        // happens when the data is different. Only necessary with documents AFAIK.
        this._studySub = studyRef
            .snapshotChanges()
            .pipe(distinctUntilChanged((prev, curr) => _.isEqual(prev?.payload.data(), curr?.payload.data())))
            .subscribe((snapshot) => {
                if (snapshot.payload.exists) {
                    const id = snapshot.payload.id
                    const study = snapshot.payload.data() as studymodel.IStudy
                    console.log("Loaded currently selected study Id '" + id + "', name '" + study.name)

                    localStorage.setItem(LOCAL_STORAGE_STUDY_KEY, id)

                    // Emit latest data to our service observable
                    this._study.next(study)
                } else {
                    // Study not found!
                    console.log("No study exists with Id '" + studyRef.ref.id + "'")

                    localStorage.removeItem(LOCAL_STORAGE_STUDY_KEY)

                    this.studies$
                        .pipe(
                            filter((studies) => studies.length > 0),
                            take(1),
                        )
                        .subscribe((s) => {
                            console.log('****** STUDY NOT FOUND, USING FIRST STUDY ****** ')

                            const defaultStudyId = s[0].studyId
                            this.selectStudy(defaultStudyId)
                        })
                }
            })
    }

    /**
     * Get currently selected study (if one is selected)
     */
    get study(): studymodel.IStudy | null {
        return this._study.value
    }

    /**
     * Get currently selected study ID
     */
    get studyId(): string | null {
        return this._study.value ? this._study.value.studyId : null
    }

    /**
     * Get currently selected study Name
     */
    get studyName(): string {
        return this.study ? this.study.name : ''
    }

    /**
     * Select a study by Id. It will be automatically loaded and reflected to study$
     * @param studyId
     */
    selectStudy(studyId: string | null) {
        if (!this._study.value || this._study.value.studyId !== studyId) {
            console.log('StudyService.selectStudy() - ' + studyId)
            this.subscribeToStudy(studyId)
        }
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @returns {Observable<any>}
     */
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        console.log('StudyService.resolve() : ' + JSON.stringify(this._study.value))

        // Hold routing until the study observable has completed
        return this._study.pipe(
            filter((study) => study !== null),
            take(1),
        )
    }

    /**
     * Get study ID given study name
     * @param studyDoc
     */
    getUniqueId(): string {
        return this.db.ref.doc().id
    }

    /**
     * Return an md5 encoded version of the study document ID
     * This is a bit of a throwback to the legacy Azure project
     * @param studyId
     * @returns
     */
    getStudyInstanceId(studyId: string): string {
        const md5 = new Md5()
        const studyInstanceId = md5.appendStr(studyId).end().toString()
        return studyInstanceId
    }

    /**
     * Create study
     * A new study document will be created, and the service will add this new study to the current studies list
     */
    async createStudy(name: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const headers = new HttpHeaders().set('Authorization', 'bearer ' + this.authenticationService.userToken)

            const apiHost = this._environment.getApiHost()
            this.http
                .post<any>(
                    apiHost + '/api/v1/studies',
                    {
                        name: name,
                        description: '',
                    },
                    {
                        headers: headers,
                    },
                )
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (error) => {
                        reject(error)
                    },
                )
        })
    }

    /**
     * Rename current study
     */
    async renameStudy(name: string): Promise<boolean> {
        return new Promise<any>((resolve, reject) => {
            const headers = new HttpHeaders().set('Authorization', 'bearer ' + this.authenticationService.userToken)

            const apiHost = this._environment.getApiHost()
            this.http
                .patch<any>(
                    apiHost + '/api/v1/studies/' + this._studyRef.ref.id,
                    {
                        name: name,
                    },
                    {
                        headers: headers,
                    },
                )
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (error) => {
                        reject(error)
                    },
                )
        })
    }

    /**
     * Return the study root collection for the currently logged in user's tenant identity
     * Use this document as a base to access all study documents
     */
    get db(): AngularFirestoreCollection | null {
        return this._studiesRef
    }

    /**
     * Return the current study document reference
     * Use this document as a base to access all documents relative study documents
     */
    get studyRef(): AngularFirestoreDocument | null {
        return this._studyRef
    }
}
