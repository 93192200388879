import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { BiotaSenseParticipantsService } from './participants.service'

/** Service tier for working with Fitbit */
@Injectable()
export class BiotaSenseFitbitService {
    // fitbit account info
    public accountId = ''
    public isLoading = false
    public fitbitAccessToken = ''
    public fitbitAccessTokenStatus = 'Loading...'
    // fitbit API responses
    public fitbitAccessTokenInfo: Object = null
    public fitbitAccountSubscriptions: Object = null
    public fitbitAccountProfile: Object = null
    public fitbitAccountDevices: Array<Object> = null
    // fitbit status
    public fitbitDeviceImage = ''
    public fitbitBatteryImage = ''
    public fitbitDeviceLastSync = ''

    private static fitbitDeviceImageMap: Object = {
        'Charge 4': 'assets/images/biotasense/fitbit/charge4.png',
        'Charge 3': 'assets/images/biotasense/fitbit/charge3.png',
        'Charge 2': 'assets/images/biotasense/fitbit/charge2.png',
        Versa: 'assets/images/biotasense/fitbit/versa.png',
        Ionic: 'assets/images/biotasense/fitbit/ionic.png',
        Inspire: 'assets/images/biotasense/fitbit/inspire.png',
        Blaze: 'assets/images/biotasense/fitbit/blaze.png',
        Unknown: 'assets/images/biotasense/fitbit/unknown.png', // a fitbit device which is not listed
        None: 'assets/images/biotasense/fitbit/unknown.png', // fitbit device could not be determined
    }

    private static fitbitBatteryImageMap: Object = {
        Empty: 'assets/images/biotasense/battery/battery_0.png',
        Low: 'assets/images/biotasense/battery/battery_1.png',
        Medium: 'assets/images/biotasense/battery/battery_2.png',
        High: 'assets/images/biotasense/battery/battery_3.png',
        Unknown: 'assets/images/biotasense/battery/battery_n.png',
    }

    constructor(
        //public _objectsService: ObjectsService,
        private _subjectsSvc: BiotaSenseParticipantsService,
        //private _accSvc: AccountsService,
        private _httpClient: HttpClient,
    ) {}

    //SM: this was all done in a hurry and is pretty messy.

    public resetAccount(): void {
        this.isLoading = true
        this.fitbitAccessToken = ''
        this.fitbitAccessTokenStatus = 'Loading...'

        this.fitbitAccessTokenInfo = null
        this.fitbitAccountSubscriptions = null
        this.fitbitAccountProfile = null
        this.fitbitAccountDevices = null

        this.fitbitDeviceImage = BiotaSenseFitbitService.fitbitDeviceImageMap['default']
        this.fitbitBatteryImage = BiotaSenseFitbitService.fitbitBatteryImageMap['default']
        this.fitbitDeviceLastSync = ''
    }

    public setAccountId(id: string) {
        this.resetAccount()

        // fetch the fitbit access token
        /*
      this._accSvc.getAccountSecret(id, "fitbittoken")
      .then(secretResponse => {

          let secretValue: string = secretResponse.Data.SecretValue;

          if (secretValue.length > 0) {
              this.fitbitAccessToken = secretValue;
              this.fitbitAccessTokenStatus = "Available";

              this.getFitbitDevices();

          }
          else {
              this.fitbitAccessTokenStatus = "No Fitbit Access Token stored for this user";
              // set an empty object so we can indicate loading is finished.
              this.fitbitAccountDevices = new Array<Object>();

          }

      })

      .catch(error => {
          this.fitbitAccessTokenStatus = "Error fetching fitbit access token."
          // set an empty object so we can indicate loading is finished.
          this.fitbitAccountDevices = new Array<Object>();
      });  

*/
    }

    // fitbit API methods hacked in. Should be put into a service at some point.

    // Fitbit API - fetch user app subscriptions information
    getFitbitSubscriptions(): void {
        // set headers
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.fitbitAccessToken,
            }),
        }
        // fetch sub info
        this._httpClient.get(`https://api.fitbit.com/1/user/-/apiSubscriptions.json`, httpOptions).subscribe((data) => {
            this.fitbitAccountSubscriptions = data
        })
    }

    // Fitbit API - fetch user profile information
    getFitbitProfile(): void {
        // set headers
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.fitbitAccessToken,
            }),
        }
        // fetch profile info
        this._httpClient.get(`https://api.fitbit.com/1/user/-/profile.json`, httpOptions).subscribe((data) => {
            this.fitbitAccountProfile = data
        })
    }

    // Fitbit API - fetch token information
    getFitbitTokenInfo(): void {
        // set headers etc.
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Bearer ' + this.fitbitAccessToken,
            }),
        }

        const body = new HttpParams().set('token', this.fitbitAccessToken)

        // fetch profile info
        this._httpClient.post(`https://api.fitbit.com/1.1/oauth2/introspect`, body.toString(), httpOptions).subscribe((data) => {
            this.fitbitAccessTokenInfo = data
        })
    }

    // Fitbit API - fetch user devices information
    getFitbitDevices(): void {
        // set headers
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.fitbitAccessToken,
            }),
        }
        // fetch device info
        this._httpClient.get(`https://api.fitbit.com/1/user/-/devices.json`, httpOptions).subscribe((data) => {
            // fitbit API returns an array of devices for this user
            this.fitbitAccountDevices = data as Array<Object>
        })
    }

    // component methods

    getFitbitDeviceBatteryUrlFromString(batteryLevel: string): string {
        if (BiotaSenseFitbitService.fitbitBatteryImageMap.hasOwnProperty(batteryLevel)) {
            return BiotaSenseFitbitService.fitbitBatteryImageMap[batteryLevel]
        }
        return BiotaSenseFitbitService.fitbitBatteryImageMap['Unknown']
    }

    getFitbitDeviceBatteryUrl(): string {
        if (this.fitbitAccountDevices != null) {
            if (this.fitbitAccountDevices.length != 0) {
                const batteryLevel = this.fitbitAccountDevices[0]['battery']
                return this.getFitbitDeviceBatteryUrlFromString(batteryLevel)
            }
        }
        return BiotaSenseFitbitService.fitbitBatteryImageMap['Unknown']
    }

    getFitbitDeviceImageUrlFromString(deviceType: string): string {
        if (BiotaSenseFitbitService.fitbitDeviceImageMap.hasOwnProperty(deviceType)) {
            return BiotaSenseFitbitService.fitbitDeviceImageMap[deviceType]
        } else {
            return BiotaSenseFitbitService.fitbitDeviceImageMap['Unknown']
        }
    }

    getFitbitDeviceImageUrl(): string {
        let deviceType: string | undefined
        if (this.fitbitAccountDevices != null) {
            if (this.fitbitAccountDevices.length != 0) {
                deviceType = this.fitbitAccountDevices[0]['deviceVersion']
            }
        }
        return this.getFitbitDeviceImageUrlFromString(deviceType ? deviceType : 'None')
    }

    getFitbitDeviceType(): string {
        if (this.fitbitAccountDevices != null) {
            if (this.fitbitAccountDevices.length != 0) {
                return this.fitbitAccountDevices[0]['deviceVersion']
            }
        }
        return 'None'
    }

    getFitbitDeviceCapabilities(): string {
        if (this.fitbitAccountDevices != null) {
            if (this.fitbitAccountDevices.length != 0) {
                return 'Steps, Distance, Active Minutes, Sleep, Heart Rate'
            }
        }
        return 'None'
    }

    timeFormat(value: number, label: string) {
        if (value == 1) {
            return value + ' ' + label + ' ago'
        } else {
            return value + ' ' + label + 's ago'
        }
    }

    timeDifference(previous: Date): string {
        const msPerMinute = 60 * 1000
        const msPerHour = msPerMinute * 60
        const msPerDay = msPerHour * 24
        const msPerMonth = msPerDay * 30
        const msPerYear = msPerDay * 365

        const current: Date = new Date()
        const elapsed = Math.abs(current.getTime() - previous.getTime())

        if (elapsed < msPerMinute) {
            return this.timeFormat(Math.round(elapsed / 1000), 'second')
        } else if (elapsed < msPerHour) {
            return this.timeFormat(Math.round(elapsed / msPerMinute), 'minute')
        } else if (elapsed < msPerDay) {
            return this.timeFormat(Math.round(elapsed / msPerHour), 'hour')
        } else if (elapsed < msPerMonth) {
            return 'approximately ' + this.timeFormat(Math.round(elapsed / msPerDay), 'day')
        } else if (elapsed < msPerYear) {
            return 'approximately ' + this.timeFormat(Math.round(elapsed / msPerMonth), 'month')
        } else {
            return 'approximately ' + this.timeFormat(Math.round(elapsed / msPerYear), 'year')
        }
    }

    getFitbitDeviceLastSync(): string {
        if (this.fitbitAccountDevices != null) {
            if (this.fitbitAccountDevices.length != 0) {
                const date = new Date(this.fitbitAccountDevices[0]['lastSyncTime'])
                return this.timeDifference(date)
            }
        }
        return 'Unknown'
    }
}
