/**
 * @fileoverview Data Model for Project-specific database objects
 */

import { IFireStoreDocument } from '@biotaware/models-shared-db-document'

/**
 * constants declaring the name of database collections.
 */
export class Collections {
    // Fitbit specific collections
    //public static Fitbit: string = 'fitbit';	// deprecated/removed

    // Root level collection for all fitbit data we receive/manage.
    public static FitbitAccounts = 'fitbit-accounts'

    // Fitbit Account Subcollections '/fitbit-accounts/{userId}/...'
    public static FitbitTokens = 'fitbit-tokens' // a subcollection to store access & refresh tokens for a given user
    public static FitbitProfile = 'fitbit-profile' // record of user account profiles
    public static FitbitDevices = 'fitbit-devices'
    public static FitbitSubscriptions = 'fitbit-subscriptions'
    public static FitbitNotifications = 'fitbit-notifications'
    public static FitbitTokenStatus = 'fitbit-tokenstatus' // access token state
    public static FitbitActivities = 'fitbit-activities' // user daily activity summaries
    public static FitbitSleep = 'fitbit-sleep' // user daily sleep summaries
    public static FitbitHeart = 'fitbit-heart' // user daily heart rate summaries
}

//--------------------------------------------------------------
// Fitbit Models
//--------------------------------------------------------------

// Generic wrapper for fitbit document snapshots.
// We add a date so that we can sort them.
export interface IFitbitSnapshot<T> {
    fitbitUserId?: string
    tstime?: string // The 'yyyy-MM-dd' format of the time series date
    data: T
    time: FirebaseFirestore.Timestamp // The update time of the day entry
}

//---------------------------------------------------------------------------
// IFitbitTokenScope
//---------------------------------------------------------------------------

export type FitbitTokenScope = 'activity' | 'heartrate' | 'location' | 'nutrition' | 'profile' | 'settings' | 'sleep' | 'social' | 'weight'
export type FitbitPermissionAccess = 'read' | 'write' | undefined

/**
 * This is a biotasense specific object that represents the specific permissions granted by a fitbit user account.
 */
export interface IFitbitTokenScope {
    [index: string]: FitbitPermissionAccess

    activity?: FitbitPermissionAccess
    heartrate?: FitbitPermissionAccess
    location?: FitbitPermissionAccess // PII
    nutrition?: FitbitPermissionAccess
    profile?: FitbitPermissionAccess // PII
    settings?: FitbitPermissionAccess // required to access user devices
    sleep?: FitbitPermissionAccess
    social?: FitbitPermissionAccess // PII
    weight?: FitbitPermissionAccess
}

//---------------------------------------------------------------------------
// "fitbit-subscriptions"
//---------------------------------------------------------------------------

/**
 * Fitbit collections that can be subscribed to
 * Collections are distinct from scopes; permissions are given by users but only collections can be subscribed to
 * Subscribing to collections requires one or more permissions:
 * activities collection requires activity
 * body collection requires weight
 * foods collection requires nutrition
 * sleep collection requires sleep
 * If no collection specified then activity, nutrition, profile, settings, sleep, weight scopes are all required. (subscription will be refused without all of these scopes)
 */
export enum FitbitCollection {
    Activities = 'activities', // notify on new activity data
    Body = 'body', // notify on new weight/biometrics etc.
    Foods = 'foods', // notify on new food logs etc.
    Sleep = 'sleep', // notify on new sleep data
    DeleteUser = 'deleteUser', // notify when user has deleted their account (we do not subscribe to this collection however)
    //	Meals = "meals",					// possibly "meals" is a supported collection type too. docs are scarce.
    User = 'user', // notify on any new data for all of the above. User is the default subscription for "all" collections. note that this requires all permissions.
}

// String union type for places where its easier to use a string type rather than an enum
export type FitbitCollectionName = 'user' | 'activities' | 'body' | 'foods' | 'sleep' | 'deleteUser' // possibly "meals" is a supported collection type too. docs are scarce.

export interface IFitbitSubscription {
    collectionType: FitbitCollection // the collection this subscription relates to
    ownerId: string // the fitbit user Id that this subscription relates to
    ownerType: string // usually "user"
    subscriptionId: string // the subscription Id that was set by us when the subscription was created
    subscriberId: string // the name of the subscriber we used when the subscription was created - subscribers are managed in our biotasense app "dev" or "prod"
}

// This is the subscriptions list returned from the fitbit API subscription endpoints
export interface IFitbitApiSubscriptions {
    apiSubscriptions: IFitbitSubscription[]
}

// This is a modified version of the subscription API response
// normally the API will only return a single collection
// If we dont subscribe to "user" collection, we do not have a way to query all active subscriptions for other collections
// So this document allows us to keep track of individual collections
export interface IFitbitSubscriptions {
    // this is a merged array of subscriptions to all or individual subscriptions
    apiSubscriptions: IFitbitSubscription[]
    // summary of active subscriptions
    // fields are set present if there is an active subscription to that collection
    // fields are set to null if there is no permission available to access that collection subscription.(indicating that a scope was not granted)
    // note that user collection is a subscription to all topics.
    // note also that having active user collection subscriptions as well as single collection subscriptions will generate duplicate notifications. Avoid this.
    subscriptions: {
        // mapped subscription collection name to subscription object - use FitbitCollectionName as the string index to ensure typed range is correct.
        [index: string]: IFitbitSubscription | null

        activities: IFitbitSubscription | null
        body: IFitbitSubscription | null
        //deleteUser: IFitbitSubscription|null;
        foods: IFitbitSubscription | null
        sleep: IFitbitSubscription | null
        user: IFitbitSubscription | null
    }
}

export type IFitbitSubscriptionsSnapshot = IFitbitSnapshot<IFitbitSubscriptions>

//---------------------------------------------------------------------------
// "fitbit-tokens"
//---------------------------------------------------------------------------

// Fitbit API token JSON object. Only used internally in this module.
export interface IFitbitApiAccessToken {
    access_token: string
    expires_in: number // Number of seconds access token is valid for.
    refresh_token: string | undefined | null // Refresh tokens only exist for users authenticated with the OAuth Code Grant Flow
    token_type: string // Usually "Bearer", legacy accounts may be "Implicit"
    user_id: string
    scope: string // eg. "weight location profile activity social heartrate sleep nutrition settings"
}

export type IFitbitAccessTokenSnapshot = IFitbitSnapshot<IFitbitApiAccessToken>

//---------------------------------------------------------------------------
// "fitbit-tokenstatus"
//---------------------------------------------------------------------------

/**
 * Token status allows us to inspect more in-depth details about an access token
 */
export interface IFitbitTokenStatus {
    active: boolean // all other fields are only present if active is true.
    client_id?: string
    exp?: number
    iat?: number
    scope?: string // string containing scope, its not quite JSON. Can be converted to IFitbitTokenScope .
    token_type?: 'access_token'
    user_id?: string
}

export type IFitbitTokenStatusSnapshot = IFitbitSnapshot<IFitbitTokenStatus>

//---------------------------------------------------------------------------
// Fitbit token scope helper functions
//---------------------------------------------------------------------------

/**
 * Helper function to parse & unpack the tokenStatus.scope string to an IFitbitTokenScope object
 * @param tokenStatus
 */
export function parseFitbitTokenStatusScope(tokenStatus: IFitbitTokenStatus): IFitbitTokenScope {
    const output: IFitbitTokenScope = {}
    const scope = tokenStatus.scope
    if (scope) {
        const cleanedScope = scope.replace('{', '').replace('}', '').split(',')
        for (const permission of cleanedScope) {
            const split = permission.split('=')
            // lhs is permission, rhs is type of access (READ/WRITE etc)
            const perm = split[0].trim().toLowerCase()
            output[perm] = split[1].trim().toLowerCase() as FitbitPermissionAccess
        }
    }
    //console.log("scope=" + JSON.stringify(output));
    return output
}

/**
 * Helper function to parse & unpack the token.scope string to an IFitbitTokenScope object
 * This differs from the above function in that we do not know the read/write capability for each permission from the token, so we assume "read" only
 * @param token
 */
export function parseFitbitTokenScope(token: IFitbitApiAccessToken): IFitbitTokenScope {
    const output: IFitbitTokenScope = {}
    const scope = token.scope
    if (scope) {
        const scopes = scope.split(' ')
        for (const permission of scopes) {
            const perm = permission.trim().toLowerCase()
            output[perm] = 'read'
        }
    }
    return output
}

//---------------------------------------------------------------------------
// "fitbit-accounts"
//---------------------------------------------------------------------------

export enum FitbitAccountStatus {
    NotAuthorized = 'NotAuthorized', // user fitbit account has not been authorized
    Authorized = 'Authorized', // user has authorized their fitbit account for biotasense
    Expired = 'Expired', // user authorization has expired for some reason
    Revoked = 'Revoked', // user has revoked authorization
    Deleted = 'Deleted', // user has deleted their fitbit account
}

// Fitbit Account Record
// This is a core document record that represents a fitbit user account on our system.
//

export interface IFitbitAccount extends IFireStoreDocument {
    userId: string | null // arguably unnecessary since the document Id is also the userId
    accountName: string // BiotaSense internal name for this account
    accountStatus: FitbitAccountStatus // access token status
    subscriptionActive: boolean // user subscription status
    accountDeleted: boolean
    accountDeletedOn?: FirebaseFirestore.Timestamp // date of deletion

    // PII (from Profile)
    displayName: string | null // the users display name from their fitbit profile
    avatarUrl: string | null // the users 100px avatar from their fitbit profile
    fitbitMemberSince: string | null // capture the users fitbit join date, this indicates how far back we might be able to go with data.

    // Properties that might be updated over time
    lastSync: FirebaseFirestore.Timestamp | null // last time we received any data about this user via fitbit subscriptions

    lastUpdateActivities: FirebaseFirestore.Timestamp | null // last time we received activity updates for this user
    lastUpdateSleep: FirebaseFirestore.Timestamp | null // last time we received sleep updates for this user
    lastUpdateHeart: FirebaseFirestore.Timestamp | null // last time we received heart updates for this user

    // Permissions granted by the user. These may change over time if user revokes or grants permissions.
    permissions?: IFitbitTokenScope

    // only accessible if we have "fitbit devices & settings" permissions
    // if users have multiple devices we may need to look at this.
    currentDeviceVersion: string | null // the currently active device name
    currentDeviceBatteryStatus: string | null
    currentDeviceBatteryLevel: number | null
    currentDeviceLastSync: FirebaseFirestore.Timestamp | null
}

//---------------------------------------------------------------------------
// "fitbit-notifications"
//---------------------------------------------------------------------------
// Subscription Notifications - Fitbit API schema
// Similar to, but not the same as the subscription API response
export interface IFitbitSubscriptionNotification {
    collectionType: FitbitCollection // the collection this subscription relates to
    ownerId: string // the fitbit user Id that this subscription relates to
    ownerType: string // usually "user"
    subscriptionId: string // the subscription Id that was set by us when the subscription was created
    date: string // "YYYY-MM-DD", only present in a notification
}

export type IFitbitSubscriptionNotificationSnapshot = IFitbitSnapshot<IFitbitSubscriptionNotification>

//---------------------------------------------------------------------------
// "fitbit-devices"
//---------------------------------------------------------------------------

export type FitbitDeviceBatteryStatus = 'High' | 'Medium' | 'Low' | 'Empty'
export type FitbitDevices =
    | 'One'
    | 'MobileTrack'
    | 'Aria'
    | 'Charge HR'
    | 'Flex'
    | 'Blaze'
    | 'Alta HR'
    | 'Charge 2'
    | 'Charge 3'
    | 'Charge 4'
    | 'Inspire HR'
    | 'Charge'
    | 'Zip'
    | 'Ionic'
    | 'Aria 2'
export type FitbitDeviceFeatures =
    | 'DISTANCE'
    | 'SLEEP'
    | 'STATUS_CHARACTERISTIC'
    | 'HEART_RATE'
    | 'BEDTIME_REMINDER'
    | 'ACTIVE_MINUTES'
    | 'ELEVATION'
    | 'BONDING'
    | 'GPS'
    | 'SEDENTARY_TIME'
    | 'CONNECTED_GPS'
    | 'INACTIVITY_ALERTS'
    | 'CALORIES'
    | 'ADVERTISES_SERIAL'
    | 'ALARMS'
    | 'SMART_SLEEP'
    | 'AUTOSTRIDE'
    | 'STEPS'
export type FitbitDeviceType = 'TRACKER' | 'SCALE'

export interface IFitbitDevice {
    battery: FitbitDeviceBatteryStatus
    batteryLevel: number
    deviceVersion: string // not using our FitbitDevices type yet as there may be some devices we aren't aware of
    features: any[]
    id: string
    lastSyncTime: string
    mac: string
    type: FitbitDeviceType
}

export type IFitbitDevices = IFitbitDevice[]

export type IFitbitDevicesSnapshot = IFitbitSnapshot<IFitbitDevices>

//---------------------------------------------------------------------------
// "fitbit-activities"
//---------------------------------------------------------------------------

// Metric interfaces

// Activity metrics

export interface IFitbitActivityGoals {
    activeMinutes: number
    caloriesOut: number
    distance: number
    floors: number
    steps: number
}

export interface IFitbitActivityDistance {
    activity: string
    distance: number
}

export interface IFitbitActivityHeartRateZone {
    caloriesOut: number
    max: number
    min: number
    minutes: number
    name: string
}

export interface IFitbitActivitySummary {
    activeScore: number
    activityCalories: number
    caloriesBMR: 1869
    caloriesOut: 3286
    distances: IFitbitActivityDistance[]
    elevation: number
    fairlyActiveMinutes: number
    floors: number
    heartRateZones: IFitbitActivityHeartRateZone[]
    lightlyActiveMinutes: number
    marginalCalories: number
    restingHeartRate: number
    sedentaryMinutes: number
    steps: number
    veryActiveMinutes: number
}

export interface IFitbitActivity {
    goals: IFitbitActivityGoals
    summary: IFitbitActivitySummary
}

export type IFitbitActivitySnapshot = IFitbitSnapshot<IFitbitActivity>

export interface IFitbitActivityTimeSeriesSteps {
    'activities-log-steps': {
        dateTime: string
        value: number
    }[]
}

//---------------------------------------------------------------------------
// "fitbit-sleep"
//---------------------------------------------------------------------------

// Sleep metrics

export interface IFitbitSleepLevel {
    data: { dateTime: string; level: string; seconds: number }[]
    shortData: { dateTime: string; level: string; seconds: number }[]
    summary: {
        deep: { count: number; minutes: number; thirtyDayAvgMinutes: number }
        light: { count: number; minutes: number; thirtyDayAvgMinutes: number }
        rem: { count: number; minutes: number; thirtyDayAvgMinutes: number }
        wake: { count: number; minutes: number; thirtyDayAvgMinutes: number }
    }
}

export interface IFitbitSleepRecord {
    dateOfSleep: string
    duration: number
    efficiency: number
    endTime: string
    infoCode: number
    isMainSleep: boolean
    levels: IFitbitSleepLevel
    logId: number
    minutesAfterWakeup: number
    minutesAsleep: number
    minutesAwake: number
    minutesToFallAsleep: number
    startTime: string
    timeInBed: number
    type: string
}

export interface IFitbitSleepSummary {
    stages: {
        deep: number
        light: number
        rem: number
        wake: number
    }
    totalMinutesAsleep: number
    totalSleepRecords: number
    totalTimeInBed: number
}

export interface IFitbitSleep {
    sleep: IFitbitSleepRecord[]
    summary: IFitbitSleepSummary
}

export type IFitbitSleepSnapshot = IFitbitSnapshot<IFitbitSleep>

//---------------------------------------------------------------------------
// "fitbit-profile"
//---------------------------------------------------------------------------

export interface IFitbitProfile {
    user: any
    //TODO:
}

export type IFitbitProfileSnapshot = IFitbitSnapshot<IFitbitProfile>

//---------------------------------------------------------------------------
// "fitbit-heart"
//---------------------------------------------------------------------------

export interface IFitbitHeart {
    'activities-heart': any[]
    //TODO:
}

export type IFitbitHeartSnapshot = IFitbitSnapshot<IFitbitHeart>
