import { Injectable } from '@angular/core'
import firebase from 'firebase/app'
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection, QueryFn } from '@angular/fire/firestore'
import { Observable } from 'rxjs'
import { map, take, tap } from 'rxjs/operators'

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>
type DocPredicate<T> = string | AngularFirestoreDocument<T>

@Injectable()
export class FirestoreService {
    constructor(private db: AngularFirestore) {}

    /// **************
    /// Get a Reference
    /// **************
    public col<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): AngularFirestoreCollection<T> {
        return typeof ref === 'string' ? this.db.collection<T>(ref, queryFn) : ref
    }

    public doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
        return typeof ref === 'string' ? this.db.doc<T>(ref) : ref
    }

    /// **************
    /// Get Data
    /// **************
    public doc$<T>(ref: DocPredicate<T>): Observable<T> {
        return this.doc(ref)
            .snapshotChanges()
            .pipe(
                map((doc) => {
                    console.log('FirestoreService.doc$() loaded document ' + doc.payload.ref.path)
                    console.log(JSON.stringify(doc.payload.data()))
                    return doc.payload.data() as T
                }),
            )
    }

    /// with Ids
    public docWithIds$<T>(ref: DocPredicate<T>): Observable<any> {
        return this.doc(ref)
            .snapshotChanges()
            .pipe(
                map((a) => {
                    console.log('FirestoreService.docWithIds$() loaded document ' + a.payload.ref.path)

                    // return actions.map(a => {

                    // });
                    const data = a.payload.data()
                    const id = a.payload.id
                    const result = { id, ...data }
                    console.log(JSON.stringify(result))
                    return result
                }),
            )
    }

    public col$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): Observable<T[]> {
        return this.col(ref, queryFn)
            .snapshotChanges()
            .pipe(
                map((docs) => {
                    console.log('FirestoreService.col$() loaded ' + docs.length + ' documents from collection')

                    return docs.map((a) => a.payload.doc.data()) as T[]
                }),
            )
    }

    /// with Ids
    public colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): Observable<any[]> {
        return this.col(ref, queryFn)
            .snapshotChanges()
            .pipe(
                map((actions) => {
                    console.log('FirestoreService.colWithIds$() loaded ' + actions.length + ' documents from collection')
                    return actions.map((a) => {
                        const data = a.payload.doc.data()
                        const id = a.payload.doc.id
                        return { id, ...data }
                    })
                }),
            )
    }

    /// **************
    /// Write Data
    /// **************
    /// Firebase Server Timestamp as a field value
    get serverTimestamp() {
        return firebase.firestore.FieldValue.serverTimestamp()
    }

    getTimestamp(date: Date = new Date()) {
        return firebase.firestore.Timestamp.fromDate(date)
    }

    set<T>(ref: DocPredicate<T>, data: any) {
        const timestamp = this.serverTimestamp
        return this.doc(ref).set({
            ...data,
            updatedAt: timestamp,
            createdAt: timestamp,
        })
    }

    update<T>(ref: DocPredicate<T>, data: any) {
        return this.doc(ref).update({
            ...data,
            updatedAt: this.serverTimestamp,
        })
    }

    delete<T>(ref: DocPredicate<T>) {
        return this.doc(ref).delete()
    }

    public add<T>(ref: CollectionPredicate<T>, data) {
        const timestamp = this.serverTimestamp
        return this.col(ref).add({
            ...data,
            updatedAt: timestamp,
            createdAt: timestamp,
        })
    }

    geopoint(lat: number, lng: number) {
        return new firebase.firestore.GeoPoint(lat, lng)
    }

    /// If doc exists update, otherwise set
    upsert<T>(ref: DocPredicate<T>, data: any) {
        const doc = this.doc(ref).snapshotChanges().pipe(take(1)).toPromise()
        return doc.then((snap) => {
            return snap.payload.exists ? this.update(ref, data) : this.set(ref, data)
        })
    }

    /// **************
    /// Inspect Data
    /// **************
    /*
  inspectDoc(ref: DocPredicate<any>): void {
    const tick = new Date().getTime();
    this.doc(ref).snapshotChanges()
      .pipe(take(1)
              .tap(d => {
                const tock = new Date().getTime() - tick;
                console.log(`Loaded Document in ${tock}ms`, d);
              })
      .subscribe());
  }

  inspectCol(ref: CollectionPredicate<any>): void {
    const tick = new Date().getTime();
    this.col(ref).snapshotChanges()
      .take(1)
      .do(c => {
        const tock = new Date().getTime() - tick;
        console.log(`Loaded Collection in ${tock}ms`, c);
      })
      .subscribe();
  }
  */
    /// **************
    /// Create and read doc references
    /// **************
    /// create a reference between two documents
    connect(host: DocPredicate<any>, key: string, doc: DocPredicate<any>) {
        return this.doc(host).update({ [key]: this.doc(doc).ref })
    }
    /// returns a documents references mapped to AngularFirestoreDocument
    docWithRefs$<T>(ref: DocPredicate<T>) {
        return this.doc$(ref).pipe(
            map((doc) => {
                for (const k of Object.keys(doc)) {
                    if (doc[k] instanceof firebase.firestore.DocumentReference) {
                        doc[k] = this.doc(doc[k].path)
                    }
                }
                return doc
            }),
        )
    }
    /// **************
    /// Atomic batch example
    /// **************
    /// Just an example, you will need to customize this method.
    atomic() {
        const batch = firebase.firestore().batch()
        /// add your operations here
        const itemDoc = firebase.firestore().doc('items/myCoolItem')
        const userDoc = firebase.firestore().doc('users/userId')
        const currentTime = this.serverTimestamp
        batch.update(itemDoc, { timestamp: currentTime })
        batch.update(userDoc, { timestamp: currentTime })
        /// commit operations
        return batch.commit()
    }
}
