import { Injectable } from '@angular/core'

/**
 * Simple service container for branding options
 * These are referenced by the navigation layout templates
 * Providing a service this way allows us to easily configure the branding in the client app.
 */

@Injectable({
    providedIn: 'root',
})
export class FuseAppService {
    currentUserName = ''
    currentUserEmail = ''
    currentUserAvatar = ''
    brandLogoUrl = ''
    brandName = ''

    /**
     * Constructor
     *
     */
    constructor() {
        /** */
    }

    setBranding(brandName: string, brandLogoUrl: string) {
        this.brandLogoUrl = brandLogoUrl
        this.brandName = brandName
    }
}
