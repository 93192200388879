import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { FuseSharedModule } from '../../../../../fuse/src/lib/shared.module'
import { MatProgressBarModule } from '@angular/material/progress-bar'

// Shared app components
import { NgxsProgressDotsComponent } from './progress-dots.component'

@NgModule({
    imports: [CommonModule, FuseSharedModule, MatProgressBarModule],
    declarations: [NgxsProgressDotsComponent],
    exports: [NgxsProgressDotsComponent],
})
export class NgxsProgressDotsModule {}
