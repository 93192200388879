import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core'
import { addDays, differenceInDays, format, isAfter, isBefore, isEqual, subDays } from 'date-fns'

@Component({
    selector: 'app-timeseries-chart-simple',
    templateUrl: './timeseries-chart-simple.component.html',
    styleUrls: ['./timeseries-chart-simple.component.scss'],
})
export class TimeSeriesChartSimpleComponent implements OnInit, OnChanges {
    @Input() widgetTitle = '' // title of the widget
    @Input() timeSeriesData: Record<string, unknown>[] = null
    @Input() select: string[] = []
    @Input() legend: true
    @Input() type = 'bar-stacked'

    chartWidget: Record<string, unknown> = {}
    chartSource = {}

    defaultChartRangeIndex = 1 // 30 Days
    hasTimeSeriesData = false

    constructor() {
        /**
         * Chart widget
         */
        this.chartWidget = {
            currentRange: 'TW',
            xAxis: false,
            yAxis: false,
            gradient: true,
            legend: false,
            showXAxisLabel: false,
            xAxisLabel: 'Days',
            showYAxisLabel: false,
            yAxisLabel: 'Value',
            timeline: true,
            scheme: {
                domain: ['#42BFF7', '#C6ECFD', '#C7B42C', '#AAAAAA'],
            },
            onSelect: (ev) => {
                // console.log(ev)
            },
        }

        this.chartWidget.currentRange = Object.keys(this.chartSource)[this.defaultChartRangeIndex]
    }

    ngOnInit() {
        this.checkForNewDataSource()

        this.chartWidget.currentRange = Object.keys(this.chartSource)[this.defaultChartRangeIndex]
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.timeSeriesData) {
            this.checkForNewDataSource()
            this.chartWidget.currentRange = Object.keys(this.chartSource)[this.defaultChartRangeIndex]
        }
    }

    checkForNewDataSource() {
        if (this.timeSeriesData) {
            this.chartSource = {
                '15 Days': this.getMetricWindow(this.timeSeriesData, 15),
                '30 Days': this.getMetricWindow(this.timeSeriesData, 30),
                '60 Days': this.getMetricWindow(this.timeSeriesData, 60),
                '90 Days': this.getMetricWindow(this.timeSeriesData, 90),
            }
            this.hasTimeSeriesData = true
        } else {
            this.hasTimeSeriesData = false
        }
    }

    private metricDateToDate(date: string) {
        const parts = date.split('-')
        return new Date('20' + parts[2] + '-' + parts[1] + '-' + parts[0])
    }

    private dateToTimeSeriesDate(date: Date) {
        //return format(date, 'dd-MM-yy')
        return format(date, 'dd-MM-yy')
    }

    private padMetrics(metrics: any[], start: Date, upto: Date, values: any) {
        let dt = start
        const days = differenceInDays(upto, start)

        /*
        if (days) {
            console.log('*** PADDING: ' + days)
        }
        */

        for (let i = 0; i < days; i++) {
            metrics.push({
                name: this.dateToTimeSeriesDate(dt),
                series: values,
            })
            dt = addDays(dt, 1)
        }
    }

    getMetricWindow(metrics: any[], days: number) {
        const metricWindow = []
        const today = new Date()
        const start = subDays(today, days - 1)

        if (metrics.length === 0) {
            // Full timeseries padding
            this.padMetrics(metricWindow, start, today, [{ name: 'No Data', value: 0 }])
        } else {
            // Get the keys that we'll use for padding
            const empty = metrics[0].series
                .map((m) => {
                    return { name: m.name, value: 0 }
                })
                .filter((m) => !this.select.length || this.select.includes(m.name))

            // Front padding
            const firstDate = this.metricDateToDate(metrics[0].name)

            if (isBefore(start, firstDate)) {
                this.padMetrics(metricWindow, start, firstDate, empty)
            }

            let currentDate = start
            currentDate.setUTCHours(0, 0, 0, 0)

            // Copy main metrics
            for (const metric of metrics) {
                const dt = this.metricDateToDate(metric.name)
                if (!isBefore(dt, start) && !isAfter(dt, today)) {
                    // Take care of sparse time series (pad gaps)
                    while (isBefore(currentDate, dt)) {
                        metricWindow.push({
                            name: this.dateToTimeSeriesDate(currentDate),
                            series: empty,
                        })
                        currentDate = addDays(currentDate, 1)
                    }
                    currentDate = addDays(currentDate, 1)

                    // Select specific fields?
                    if (this.select.length == 0) {
                        metricWindow.push(metric)
                    } else {
                        metricWindow.push({
                            name: dt,
                            series: metric.series.filter((m) => this.select.includes(m.name)),
                        })
                    }

                    if (!this.select || this.select.includes(metric.name)) {
                        metricWindow.push(metric)
                    }
                }
            }

            // Trailing padding
            let lastDate = this.metricDateToDate(metrics[metrics.length - 1].name)
            if (isAfter(today, lastDate)) {
                if (isBefore(lastDate, start)) {
                    lastDate = start
                }
                this.padMetrics(metricWindow, lastDate, today, empty)
            }
        }

        // Line and area charts have a slightly different format for our purposes
        // so do the transformation here
        if (this.type === 'line' || this.type === 'area' || this.type === 'normalised-area') {
            const newMetrics = {}

            for (const metric of metricWindow) {
                const dt = metric.name
                for (const ser of metric.series) {
                    const cat = ser.name
                    const value = ser.value

                    if (!newMetrics[cat]) {
                        newMetrics[cat] = { series: [] }
                    }

                    newMetrics[cat]['series'].push({
                        name: dt,
                        value: value,
                    })
                }
            }

            const retMetrics = []

            // Create the final timeseries
            for (const metric in newMetrics) {
                retMetrics.push({
                    name: metric,
                    series: newMetrics[metric].series,
                })
            }

            return retMetrics
        }

        return metricWindow
    }
}
