<mat-toolbar class="p-0 mat-elevation-z1">


    <!-- menu button, shows when view width is limited and nav menu is minimized -->
    <div fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="none"  fxLayout="row" fxLayoutAlign="start center">
            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>
        
        </div>

        <!-- custom toolbar content container, injected by client app as a portal component -->
        <div fxFlex="1 1 auto" id="toolbar-content-container">
            <!-- client app toolbar content will be injected here -->
        </div>

        
        <!-- SM: Disabled search bar -->
        <!--
        <div class="toolbar-separator"></div>
        <fuse-search-bar (input)="search($event)"></fuse-search-bar>
        -->

        <div class="toolbar-separator"></div>

        <!-- SM: Language selector, see https://www.whoishostingthis.com/resources/flag-icons/ for free assets -->
        <button mat-button fxHide fxShow.gt-xs
                class="language-button"
                [matMenuTriggerFor]="languageMenu">
            <div fxLayout="row" fxLayoutAlign="center center">
                <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
            </div>
        </button>

        <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

            <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                <span fxLayout="row" fxLayoutAlign="start center">
                    <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                    <span class="iso">{{lang.title}}</span>
                </span>
            </button>

        </mat-menu>

        <!-- side panel -->
        <div *ngIf="!hiddenSidepanel" class="toolbar-separator" fxHide fxShow.gt-xs></div>
        <button *ngIf="!hiddenSidepanel" mat-icon-button
                class="quick-panel-toggle-button"
                (click)="toggleSidebarOpen('quickPanel')"
                aria-label="Toggle quick panel">
            <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
        </button>

        <!-- nav bar icon -->
        <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>
        <button mat-icon-button class="navbar-toggle-button"
                *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

    </div>

</mat-toolbar>
