<div class="secondary-text">

	<div fxLayout="row" fxLayoutAlign="start center">

		<mat-icon class="breadcrumb-active-item s-18" (click)="navigateToRoute('')">home</mat-icon>

		<ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">

			<mat-icon class="s-16">chevron_right</mat-icon>

				<!-- TODO: make the linkable part show a clickable hover -->
			<span *ngIf="!last" class="breadcrumb-active-item" (click)="navigateToRoute(breadcrumb.url)" >{{ breadcrumb.name }}</span>
			<span *ngIf="last"><strong>{{ breadcrumb.name }}</strong></span>
	
		</ng-container>	

	</div>
</div>

