/**
 * SystemSharedModule
 * Provides common app functionality:
 *  - Fuse Pipes & Directives
 *  - CommonModule
 *  - FormsModule & ReactiveFormsModule
 *  - FlexLayoutModule
 *  - SystemPipesModules
 *
 * No root provided services in here.
 * Import into feature modules & components as needed.
 *
 */
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { FuseSharedModule } from '../../../fuse/src/lib/shared.module'
import { NgxsPipesModule } from './pipes/pipes.module'

@NgModule({
    declarations: [],
    imports: [CommonModule, FuseSharedModule, NgxsPipesModule],
    exports: [
        CommonModule,

        // shared library modules
        FuseSharedModule,

        // shared pipes
        NgxsPipesModule,
    ],
})
export class NgxsSharedModule {}
