import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { FuseSharedModule } from '../../../../../fuse/src/lib/shared.module'
import { MatDialogModule } from '@angular/material/dialog'

// Shared app components
import { NgxsConfirmComponent } from './confirm.component'

@NgModule({
    imports: [CommonModule, FuseSharedModule, MatDialogModule],
    declarations: [NgxsConfirmComponent],
    exports: [NgxsConfirmComponent],
})
export class NgxsConfirmModule {}
