import { MatPaginatorIntl } from '@angular/material/paginator'
import { Injectable } from '@angular/core'

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
    constructor() {
        super()

        this.getAndInitTranslations()
    }

    getAndInitTranslations() {
        this.itemsPerPageLabel = 'Size'
        this.nextPageLabel = 'Next Page'
        this.previousPageLabel = 'Previous Page'
        this.changes.next()
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            // return `0 / ${length}`;
            return 'Page 1'
        }
        const newLength = Math.max(length, 0)
        const startIndex = page * pageSize
        const endIndex = startIndex < newLength ? Math.min(startIndex + pageSize, newLength) : startIndex + pageSize
        return 'Page ' + (page + 1).toString()
        // return `${startIndex + 1} - ${endIndex} / ${newLength}`;
    }
}
