import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-progress-bar',
    // causes eslint exception: https://github.com/angular-eslint/angular-eslint/issues/207
    //template: '<div [ngStyle]="{ \'width\': progress + \'%\' }" class="progressbar"></div>',
    // eslint-disable-next-line
    template: `<div [ngStyle]="{ 'width': progress + '%' }" class="progressbar"></div>`,
    
})
export class NgxsProgressBarComponent {
    @Input() progress = 0
}
