import { Routes } from '@angular/router'

import { StudyService } from './services/study.service'
import { AuthGuard, redirectUnauthorizedToLogin } from './shared.module'

export const MainRoutes: Routes = [
    {
        path: 'overview',
        canActivate: [AuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin, breadcrumb: 'Overview' },
        resolve: {
            study: StudyService,
        },
        loadChildren: () => import('./pages/overview/overview.module').then((m) => m.OverviewModule),
    },
    {
        path: 'participants',
        canActivate: [AuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin, breadcrumb: 'Participants' },
        resolve: {
            study: StudyService,
        },
        loadChildren: () => import('./pages/participants/participants.module').then((m) => m.ParticipantsModule),
    },
    {
        path: 'compliance',
        canActivate: [AuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin, breadcrumb: 'Compliance' },
        resolve: {
            study: StudyService,
        },
        loadChildren: () => import('./pages/compliance/compliance.module').then((m) => m.ComplianceModule),
    },
    {
        path: 'export',
        canActivate: [AuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin, breadcrumb: 'Export' },
        resolve: {
            study: StudyService,
        },
        loadChildren: () => import('./pages/export/export.module').then((m) => m.ExportModule),
    },
    {
        path: 'team',
        canActivate: [AuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin, breadcrumb: 'Team' },
        resolve: {
            study: StudyService,
        },
        loadChildren: () => import('./pages/team/team.module').then((m) => m.TeamModule),
    },
    {
        path: 'developers',
        canActivate: [AuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin, breadcrumb: 'Developers' },
        resolve: {
            study: StudyService,
        },
        loadChildren: () => import('./pages/developers/developers.module').then((m) => m.DevelopersModule),
    },
    {
        path: 'test-charts',
        canActivate: [AuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin, breadcrumb: 'Test' },
        loadChildren: () => import('./pages/test-charts/test-charts.module').then((m) => m.TestChartsModule),
    },
    {
        path: 'auth',
        loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: 'errors',
        loadChildren: () => import('./pages/errors/errors.module').then((m) => m.ErrorsModule),
    },
    {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
    },
    {
        path: '**', // the catch-all route (for unregistered routes)
        //component: Error404Component, // Using the 404 component for 404 routes will preserve the faulty URL, but also discloses which URLs are protected since guarded routes redirect to /404.
        redirectTo: '/errors/error-404',
        pathMatch: 'full',
    },
]
