import { Component, OnInit, Input } from '@angular/core'

@Component({
    selector: '[app-panel]',
    templateUrl: './panel.component.html',
    styleUrls: ['./panel.component.scss'],
})
export class NgxsPanelComponent implements OnInit {
    @Input() panelType = 'panel' // can be "card" (MatCard) or "panel" (MatExpansionPanel)
    @Input() panelId: string
    @Input() panelTitle = '' // not rendered if empty string
    @Input() panelSubtitle = '' // not rendered if empty string
    @Input() isCollapsed = false
    @Input('icon') panelIcon = 'arrow_right' //'indeterminate_check_box'; Icon is only rendered if panelTitle is not an empty string

    @Input() isLoading = false
    @Input() disableFooterWhenLoading = true

    constructor() {
        /** */
    }

    ngOnInit() {}
}
