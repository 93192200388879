<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 pb-0 m-0">

        <form name="eventForm" [formGroup]="eventForm" class="event-form" fxLayout="column" fxFlex>

            <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>Edit Study Name</mat-label>
                <input matInput
                       name="studyName"
                       formControlName="studyName"
                       required>
            </mat-form-field>

        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button
                mat-raised-button color="basic"
                (click)="matDialogRef.close()"
                aria-label="Cancel">
            Cancel
        </button>

        <button mat-button color="primary"
                mat-raised-button color="accent"
                (click)="editStudy()"
                [disabled]="eventForm.invalid"
                aria-label="Save">
            Save
        </button>
    </div>
</div>
