import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { FuseSharedModule } from '../../../../../fuse/src/lib/shared.module'

import { MatIconModule } from '@angular/material/icon'

// Shared app components
import { NgxsBreadcrumbComponent } from './breadcrumb.component'

@NgModule({
    imports: [CommonModule, FuseSharedModule, MatIconModule],
    declarations: [NgxsBreadcrumbComponent],
    exports: [NgxsBreadcrumbComponent],
})
export class NgxsBreadcrumbModule {}
