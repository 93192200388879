import { Component, Inject, OnDestroy, OnInit, Injector, InjectionToken } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { Platform } from '@angular/cdk/platform'
import { TranslateService } from '@ngx-translate/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { FuseConfigService } from '../services/config.service'
import { FuseNavigationService } from '../components/navigation/navigation.service'
import { FuseSidebarService } from '../components/sidebar/sidebar.service'
import { FuseSplashScreenService } from '../services/splash-screen.service'
import { FuseTranslationLoaderService } from '../services/translation-loader.service'

//import { locale as navigationEnglish } from 'app/navigation/i18n/en'
//import { locale as navigationTurkish } from 'app/navigation/i18n/tr'

// Create the injection token for the custom settings
export const FUSE_NAVIGATION = new InjectionToken('fuseNavigation')

import { FuseNavigation } from '../types/fuse-navigation'
import { FuseConfig } from '../types/fuse-config'

@Component({
    selector: 'fuse-app',
    templateUrl: './fuse-app.component.html',
    styleUrls: ['./fuse-app.component.scss'],
})
export class FuseAppComponent implements OnInit, OnDestroy {
    fuseConfig: FuseConfig
    navigation: FuseNavigation

    // Private
    private _unsubscribeAll: Subject<any>

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private injector: Injector,
        @Inject(FUSE_NAVIGATION) private _navigation: FuseNavigation,
    ) {
        // Get default navigation
        this.navigation = _navigation

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation)

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main')

        // Add languages
        this._translateService.addLangs(['en', 'tr'])

        // Set the default language
        this._translateService.setDefaultLang('en')

        // Set the navigation translations
        //        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish)

        // Use a language
        this._translateService.use('en')

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         */

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         * setTimeout(() => {
         * this._translateService.setDefaultLang('en');
         * this._translateService.setDefaultLang('tr');
         * });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile')
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject()
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // debug to list all known routes for this app
        //this.listRoutes()

        // Subscribe to config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
            this.fuseConfig = config

            // Boxed
            if (this.fuseConfig.layout.width === 'boxed') {
                this.document.body.classList.add('boxed')
            } else {
                this.document.body.classList.remove('boxed')
            }

            // Color theme - Use normal for loop for IE11 compatibility
            for (const className of this.document.body.classList) {
                if (className.startsWith('theme-')) {
                    this.document.body.classList.remove(className)
                }
            }

            this.document.body.classList.add(this.fuseConfig.colorTheme)
        })
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next()
        this._unsubscribeAll.complete()
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen()
    }
}
