import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { NgxChartsModule } from '@swimlane/ngx-charts'

// Shared app components
import { DataChartComponent } from './data-chart.component'

@NgModule({
    imports: [CommonModule, NgxChartsModule],
    declarations: [DataChartComponent],
    exports: [DataChartComponent],
})
export class DataChartModule {}
