/**
 * Core Server module for common firebase/backend services
 * Provides app wide services for firebase declared in this module
 * Imported only once by the app module, if firebase is required in the app
 */

import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core'

// Services
import { NgxsAuthenticationService } from './services/authentication.service'
import { NgxsCookieService } from './services/cookieservice.service'
import { NgxsTenantService, MULTITENANT_TOKEN } from './services/tenant.service'

// Firebase support services
import { AngularFireAuthGuard } from '@angular/fire/auth-guard'
import { AngularFirestore } from '@angular/fire/firestore'

// Firebase support modules
import { AngularFireFunctionsModule } from '@angular/fire/functions'
import { AngularFireDatabaseModule } from '@angular/fire/database'
import { AngularFireModule, FirebaseOptions, FIREBASE_OPTIONS } from '@angular/fire'
import { AngularFireAuthModule } from '@angular/fire/auth'

@NgModule({
    declarations: [],
    imports: [
        AngularFireAuthModule,
        AngularFireFunctionsModule,
        AngularFireDatabaseModule,
        AngularFireModule, // .initializeApp(environment.firebase) // see https://stackoverflow.com/questions/49776296/pass-angularfire-config-imported-in-library-using-forroot/54831986#54831986
    ],
    providers: [NgxsCookieService, NgxsAuthenticationService, NgxsTenantService, AngularFirestore, AngularFireAuthGuard],
})
export class NgxsServerModule {
    constructor(@Optional() @SkipSelf() parentModule: NgxsServerModule) {
        if (parentModule) {
            throw new Error('Core ServerModule is already loaded. Import it in the AppModule only')
        }
    }

    /**
     * FirebaseModule must be imported by the client application AppModule with `.forRoot(firebaseOptions)`
     * @param firebaseOptions - runtime configuration for firebase
     * @param isMultiTenant - indicate if the app uses single or multi tenant authentication
     * @returns
     */
    public static forRoot(firebaseOptions: FirebaseOptions, isMultiTenant: boolean = false): ModuleWithProviders<NgxsServerModule> {
        return {
            ngModule: NgxsServerModule,
            providers: [
                { provide: FIREBASE_OPTIONS, useValue: firebaseOptions },
                { provide: MULTITENANT_TOKEN, useValue: isMultiTenant },
            ],
        }
    }
}
