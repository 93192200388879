<app-portal hostId="#toolbar-content-container">

    <div fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex>

            <div fxLayout="row" fxLayoutAlign="start center">
                
                    <button mat-button [matMenuTriggerFor]="studyMenu" #menuTrigger="matMenuTrigger"
                            class="study-menu-button">
                        <div fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon class="ml-16">assessment</mat-icon>
                            <span class="username mr-12 ml-8" fxHide fxShow.gt-sm>{{ getStudyName() }}</span>
                            <mat-icon class="" fxHide.xs>keyboard_arrow_down</mat-icon>
                        </div>
                    </button>

                    <mat-menu class="study-menu" #studyMenu="matMenu" [overlapTrigger]="false">

                            <div *ngFor="let study of studies">
                                <button mat-menu-item class="study-menu-item-button" (click)='onSelectStudy(study.studyId)'>
                                    <mat-icon class="">assessment</mat-icon>
                                    <span class="study-button">{{ study.name }} </span>

                                    <span *ngIf="canAddDeleteEditStudies && study.studyId === studyId">
                                        <button class="edit-button"
                                            mat-stroked-button color="accent"
                                            (click)="$event.stopPropagation(); trigger.closeMenu(); onRenameStudy()"
                                            aria-label="Edit">
                                            Edit
                                        </button>
                                    </span>                    
                                </button>
                                <mat-divider *ngIf="studies.length > 1 && study.studyId === studyId"></mat-divider>
                            </div>

                            <div *ngIf="canAddDeleteEditStudies">
                                <mat-divider></mat-divider>                                        

                                <button mat-menu-item class="study-menu-item-button" (click)='onCreateStudy()'>
                                    <mat-icon>add_circle_outline</mat-icon>
                                    <span class="study-button">New Study</span>
                                </button>
                            </div>
                    </mat-menu>

                </div>

            </div>

        <!-- SM: THIS IS THE USER PROFILE DROP DOWN MENU TOOL -->
        <div fxFlex="0 1 auto" *ngIf="isLoggedIn">

            <!-- User Menu -->
            <button mat-button [matMenuTriggerFor]="userMenu"
                    class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon>person</mat-icon>
                    <span class="username mr-12 ml-12" fxHide fxShow.gt-sm>{{ getUserName() }}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu class="user-menu" #userMenu="matMenu" [overlapTrigger]="false" xPosition="before">

                <div class="user-name">{{ getUserName() }} </div>
                <div class="user-role">{{ getUserRole() }} </div>
                <mat-divider></mat-divider>                                        

                <button mat-menu-item (click)='onSelectProfile()'>
                    <mat-icon>account_circle</mat-icon>
                    <span>My Profile</span>
                </button>

                <button mat-menu-item class="" (click)='onLogout()'>
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Log out</span>
                </button>

            </mat-menu>

        </div>

    </div>

</app-portal>
