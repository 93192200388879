import { Component, OnInit, Output, Input, SimpleChanges } from '@angular/core'
import { NgxChartsModule } from '@swimlane/ngx-charts'
import * as shape from 'd3-shape'
import { Metric } from '../../../main/types/metrics/metric'
import { MetricGroup, MetricDataSet } from '../../services/metric-dataset'
import { MetricDatasetBuilder } from '../../services/metric-dataset-builder'
import { isUndefined } from 'util'

@Component({
    selector: 'component-biotasense-line-chart',
    templateUrl: './biotasense-line-chart.component.html', //,
    //styleUrls: ['./biotasense-subjects-view.component.scss']
})
export class BiotaSenseLineChartComponent implements OnInit {
    //view: any[] = [700, 400];

    // options
    showXAxis = true
    showYAxis = true
    gradient = false
    showLegend = false
    showXAxisLabel = true
    xAxisLabelNgx = ''
    showYAxisLabel = true
    yAxisLabelNgx = ''
    timeline = false
    curve = shape.curveCardinal
    xAxisTicks = []
    trimYAxis = false
    animate = false

    // Inputs for the builder
    // public groupNames : Array<string>;
    // public dateGetterFunction: (m: Metric) => Date;
    // public groupAssignFunction: (m: Metric) => string;
    // public fromDate: Date;
    // public toDate: Date;
    // public dateFormatter: (date: Date) => string = this.dateToString;

    @Input() groupNames: Array<string> = null
    @Input() dateGetterFunction: (m: Metric) => Date = null
    @Input() groupAssignFunction: (m: Metric) => string = null
    @Input() fromDate: Date = null
    @Input() toDate: Date = null
    @Input() dateFormatter: (date: Date) => string = null
    @Input() metricData: Array<Metric> = null
    @Input() aggregateFunction: (m: Array<Metric>, series: string) => any

    @Input() xAxisLabel: string
    @Input() yAxisLabel: string
    @Input() widgetTitle: string

    // Allow the lineChart the option to include a fromDays and toDays so
    // that we don't have to have the client set up the startDate each time
    @Input() fromDays: number = null
    @Input() toDays: number = null

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        console.log('ngOnChanges')
        this.tryBuildDataset()

        // Allow the parent component to pass the labels and update the chart if they change
        if (!isUndefined(changes.xAxisLabel)) {
            this.xAxisLabelNgx = changes.xAxisLabel.currentValue
        }

        if (!isUndefined(changes.yAxisLabel)) {
            this.yAxisLabelNgx = changes.yAxisLabel.currentValue
        }
    }

    private tryBuildDataset() {
        // Ensure that we have everything that we need before building the dataset

        if (this.groupAssignFunction == null && this.fromDate == null && this.fromDays == null) {
            // In this scenario we have no way to determine the group the metrics belong to
            return
        }

        if (this.metricData == null) {
            // We have no data to work with
            return
        }

        this.buildDataset()
    }

    private buildDataset() {
        const builder = new MetricDatasetBuilder(this.metricData)

        // Do this first as can be overidden by setDateRange
        if (this.groupNames != null) builder.groupNames = this.groupNames

        // Optional Formatter, default shows dd/mm/YYYY
        if (this.dateFormatter != null) builder.dateFormatter = this.dateFormatter

        // Optional date getter, default = uses CapturedAt
        if (this.dateGetterFunction != null) builder.dateGetterFunction = this.dateGetterFunction

        // Group assign function is date by defau;t, but could be set via
        // using the fromDate && toDate. Can also be overriden by setDateRange
        if (this.groupAssignFunction != null) {
            builder.groupAssignFunction = this.groupAssignFunction
        } else if (this.fromDate != null) {
            // We use the fromDate mainly, if the toDate is not set we use
            // todays date
            builder.setDateRange(this.fromDate, this.toDate == null ? new Date() : this.toDate)
        } else if (this.fromDays != null) {
            builder.setDateRangeDays(this.fromDays, this.toDays == null ? 0 : this.toDays)
        }

        // Need to turn our dataset into something that can be use by the graph
        const dataSet = builder.getDataSets(this.aggregateFunction)

        const lineChartData = {
            name: 'Step Count',
            series: [],
        }

        let i = 0
        for (i; i < dataSet.dataPoints.length; i++) {
            const set = {
                value: dataSet.dataPoints[i],
                name: dataSet.series[i],
            }

            lineChartData.series.push(set)
        }

        this.multi = []
        this.multi.push(lineChartData)

        // Need to work out which ticks we can show, we always want to show a multiple of 7?
        this.xAxisTicks = []

        const step = Math.ceil(dataSet.dataPoints.length / 7)
        for (i = dataSet.dataPoints.length - 1; i >= 0; i -= step) {
            if (i < step) {
                this.xAxisTicks.unshift(dataSet.series[0])
            } else {
                this.xAxisTicks.unshift(dataSet.series[i])
            }
        }
    }

    colorScheme = {
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
    }

    multi: any[]
}
