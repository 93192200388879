import { Injectable } from '@angular/core'

@Injectable()
export class NgxsCookieService {
    constructor() {
        /** */
    }

    // set(key: string, value: string): void;
    // set(key: string, value: string, expires: Date): void;
    set(key: string, value: string, expires?: Date): void {
        let cookieValue = `${key}=${value}`
        if (expires) {
            cookieValue += `;expires='${expires.toUTCString()}'`
        }
        document.cookie = cookieValue
    }

    setWithExpiryInYears(key: string, value: string, expires: number): void {
        this.setWithExpiryInDays(key, value, expires * 365)
    }

    setWithExpiryInDays(key: string, value: string, expires: number): void {
        this.setWithExpiryInHours(key, value, expires * 24)
    }

    setWithExpiryInHours(key: string, value: string, expires: number): void {
        this.setWithExpiryInMinutes(key, value, expires * 60)
    }

    setWithExpiryInMinutes(key: string, value: string, expires: number): void {
        this.setWithExpiryInSeconds(key, value, expires * 60)
    }

    setWithExpiryInSeconds(key: string, value: string, expires: number): void {
        this.setWithExpiryInMiliseconds(key, value, expires * 1000)
    }

    setWithExpiryInMiliseconds(key: string, value: string, expires: number): void {
        const expireDate = new Date()
        const time = expireDate.getTime() + expires
        expireDate.setTime(time)

        this.set(key, value, expireDate)
    }

    get(key: string): string | null {
        const decodedCookie: string = decodeURIComponent(document.cookie)
        const pairs: string[] = decodedCookie.split(/;\s*/)

        const prefix = `${key}=`
        for (const pair of pairs) {
            if (pair.indexOf(prefix) === 0) {
                return pair.substring(prefix.length)
            }
        }
        return null
    }

    delete(key: string): void {
        document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`
    }

    deleteAll(): void {
        const cookies = document.cookie.split(';')
        for (const pair of cookies) {
            const key = pair.split('=')[0]
            this.delete(key)
        }
    }
}
