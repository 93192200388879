import { PipeTransform, Pipe } from '@angular/core'

@Pipe({
    name: 'separateBy',
    pure: true,
})
export class NgxsSeparateByPipe implements PipeTransform {
    /**
     * Return a prettified version of the code.
     * Splits the code into segments with a separater character for easier human reading.
     * @param code The code to prettify
     * @param sepLength The length of each segment in characters
     * @param sepChar The separating character for each segment
     */
    public transform(code: string, sepLength: number = 4, sepChar: string = '-'): string {
        if (!code) {
            return ''
        }
        let i = 0
        let out = ''

        while (i < code.length) {
            if (i != 0) out += sepChar

            out += code.substr(i, sepLength)

            i += sepLength
        }

        return out
    }
}
