/**
 * PagesTableModule
 * Provides custom pages table functionality
 *
 */
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

// Custom Paginator
import { MatPaginatorIntl } from '@angular/material/paginator'
import { MatPaginatorModule } from '@angular/material/paginator'

import { CustomMatPaginatorIntl } from '../../utils/custompaginator.component'

// Shared types
export { NgxsPagedTable as PagedTable } from '../../utils/pagedtable'

@NgModule({
    declarations: [],
    imports: [CommonModule, MatPaginatorModule],
    providers: [
        {
            provide: MatPaginatorIntl,
            useClass: CustomMatPaginatorIntl,
        },
    ],

    exports: [CommonModule, MatPaginatorModule],
})
export class NgxsPagedTableModule {}
