import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { FuseSharedModule } from '@biotaware/ngx-fuse'
import { FuseWidgetModule } from '@biotaware/ngx-fuse'

import { MatButtonModule } from '@angular/material/button'

import { NgxChartsModule } from '@swimlane/ngx-charts'

// Shared app components
import { BiotaSenseGraphWidgetComponent } from './biotasense-graph-widget.component'

@NgModule({
    imports: [CommonModule, FuseSharedModule, FuseWidgetModule, MatButtonModule, NgxChartsModule],
    declarations: [BiotaSenseGraphWidgetComponent],
    exports: [BiotaSenseGraphWidgetComponent],
})
export class BiotaSenseGraphWidgetModule {}
