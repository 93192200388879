import { FuseNavigation } from '@biotaware/ngx-fuse'

// Root navigation items must have the product ID at the start of their item id.
// See biotaportal.service for more details.
export const navigation: FuseNavigation[] = [
    {
        id: 'root',
        title: 'BiotaSense',
        translate: 'BiotaSense',
        type: 'group',
        children: [
            {
                id: 'overview',
                title: 'Overview',
                translate: 'Overview',
                type: 'item',
                icon: 'home',
                url: 'overview',
            },
            {
                id: 'participants',
                title: 'Participants',
                translate: 'Participants',
                type: 'item',
                icon: 'groups',
                url: 'participants',
            },
            {
                id: 'compliance',
                title: 'Compliance',
                translate: 'Compliance',
                type: 'item',
                icon: 'playlist_add_check',
                url: 'compliance',
            },
            {
                id: 'export',
                title: 'Export',
                translate: 'Export',
                type: 'item',
                icon: 'file_download',
                url: 'export',
            },
        ],
    },
    // Settings
    {
        id: 'settings',
        title: 'Settings',
        translate: 'Settings',
        type: 'group',
        children: [
            {
                id: 'team',
                title: 'Team',
                translate: 'Team',
                type: 'item',
                icon: 'people',
                url: 'team',
            },
            {
                id: 'developers',
                title: 'Developers',
                translate: 'Developers',
                type: 'item',
                icon: 'code',
                url: 'developers',
            },
        ],
    },
]
