import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { FuseSharedModule } from '../../../../../fuse/src/lib/shared.module'

import { MatButtonModule } from '@angular/material/button'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRippleModule } from '@angular/material/core'
import { MatIconModule } from '@angular/material/icon'

// Shared app components
import { NgxsSpinnerButtonComponent } from './spinner-button.component'

// Export module's public API
export { NgxSpinnerButtonOptions } from './spinner-button.interface'

@NgModule({
    imports: [CommonModule, FuseSharedModule, MatButtonModule, MatProgressBarModule, MatProgressSpinnerModule, MatRippleModule, MatIconModule],
    declarations: [NgxsSpinnerButtonComponent],
    exports: [NgxsSpinnerButtonComponent],
})
export class NgxsSpinnerButtonModule {}
