<!-- Page Header -->

        <!-- HEADER -->
        <div class="header accent mb-4 p-24 h-120 mat-elevation-z1" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="mr-16" (click)="goBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>

            <div fxLayout="column" fxLayoutAlign="start start">
                
                <!-- PAGE TITLE -->
                <div class="logo" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon class="logo-icon mr-16"
                                [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">{{ icon }}
                    </mat-icon>
                    <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                        {{ title }}
                    </span>
                </div>

                <!-- BREAD CRUMB -->
                <app-breadcrumb [@animate]="{value:'*',params:{delay:'150ms',x:'-25px'}}"></app-breadcrumb>


            </div>
    
            <!-- adding more children here will render along the row, so might be useful for adding toolbars in the header later -->
            <!-- SEARCH - NEEDS TO IMPLEMENTED ON COMPONENT INTERFACE -->
                        <!--
            <div class="ml-sm-32" fxLayout="row" fxLayoutAlign="center center" fxFlex>

                <div class="search-wrapper mt-16 mt-sm-0" *ngIf="searchEnabled">
    
                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>search</mat-icon>
                        <input [formControl]="test" placeholder="Search for a participant">
                    </div>
    
                </div>
    
            </div>
                        -->


            
        </div>
        <!-- / HEADER -->


<!--


<div class="page-heading">
	<h1 class="large-heading">{{ title }}</h1>
</div>
-->
<!--

ng-content-begin
<ng-content></ng-content>
ng-content-end
-->

<!-- / Page Header -->