/**
 * Participant Document Model
 * CLIENT READABLE
 */

import { IFireStoreDocumentVersioned } from '@biotaware/models-shared-db-document'
import * as fitbitmodel from './FitbitServer.model'

export class Collections {
    // participants are a sub-collection of "Studies"
    public static Participants = 'participants'
}

/** Enum to represent the Fitbit connection status for a Participant */
export enum FitbitStatus {
    NotConnected = 'NotConnected',
    Authorised = 'Authorised',
    Expired = 'Expired',
}

/**
 * Participants may eventually provide data via multiple sources (fitbit/garmin/apple etc)
 * So we reflect each possible data source in the participant record in an encapsulated way
 * For fitbit participants, we simply reflect the current fitbit account status
 *
 */
export interface IParticipantDataSourceFitbit {
    fitbitAccount: fitbitmodel.IFitbitAccount // snapshot of this participants fitbit account status
    dataSourceCreatedAt: FirebaseFirestore.Timestamp // date of this participant's first fitbit data source event
    dataSourceUpdatedAt: FirebaseFirestore.Timestamp // data of this participant's latest fitbit data source event
}

/**
 * IParticipant represents an individual participant in a study.
 */
export interface IParticipant extends IFireStoreDocumentVersioned<1> {
    readonly participantId: string // cannot be changed after inception. is a client provided code to anonymise a participant and is set by study manager when created
    readonly studyId: string // the study that this participant belongs to

    // API writable fields
    deactivated: boolean // indicates if participant has been deactivated from study (kind of legacy from original design)

    // fitbit data source for this participant if they have authorized
    fitbitDataSource?: IParticipantDataSourceFitbit
    // participants may in the future have additional sources for data...

    // deprecated
    fitbitUserId?: string
    fitbitAuthenticatedAt: FirebaseFirestore.Timestamp | null
    fitbitConnectionStatus: FitbitStatus
    lastSync: FirebaseFirestore.Timestamp | null
}
