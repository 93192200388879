//----------------------------------------------------------------------------
// BigQuery query cache model
//----------------------------------------------------------------------------

import { IFireStoreDocumentVersioned } from '@biotaware/models-shared-db-document'

export class Collections {
    // BiqQuery cache collection
    public static BigQueryCache = 'bigquery-cache/cache/results'
    // BigQuery cached results collection
    public static BigQueryCachedQuery = 'bigquery-cache/cache/queries'
}

export enum BigQueryCacheResultType {
    None = 'None',
    Fetched = 'Fetched',
    Cached = 'Cached',
}

// This is the structure of the document that should be observed for updates in the
// client UI.  As such, it should reveal no details about the query technicals (SQL commands etc)
export interface IBigQueryCache extends IFireStoreDocumentVersioned<1> {
    queryId: string
    resultType: BigQueryCacheResultType
    resultRows: string[]
    timeTaken: number
    duration: number
    expires: FirebaseFirestore.Timestamp
}

// This is an accompanying document structure that can contain the query technicals (SQL commands etc)
// These documents should not be accessible by client code.
export interface IBigQueryCachedQuery extends IFireStoreDocumentVersioned<1> {
    queryId: string
    query: string
}
