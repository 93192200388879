/**
 * Study Document Model
 * CLIENT READABLE
 */
import { IFireStoreDocumentVersioned } from '@biotaware/models-shared-db-document'

export class Collections {
    public static Studies = 'studies' // server side only collection rooted from tenant
}

export interface IStudy extends IFireStoreDocumentVersioned<1> {
    studyId: string // The firebase ID of the study
    name: string // The name of the study
    description: string // Description of study details
    instanceId: string // The MD5 hash of the studyId
    archived: boolean // Whether the study is archived or not
    participantCount?: number
}
