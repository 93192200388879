export const locale = {
    lang: 'tr',
    data: {
        NAV: {
            MANAGE: 'Manage',
            OVERVIEW: 'Overview',
            USERS: 'Users',
            DEVICES: 'Devices',
            SIMS: 'SIMS',
            WEARERS: 'Wearers',
            'VIRTUAL-NUMBERS': 'Virtual Numbers',
        },
    },
}
