<!-- Chart Widget -->
<fuse-widget class="widget">

    <!-- Front -->
    <div class="fuse-widget-front" fxLayout="column" fxFlex="100">

        <div class="px-16 border-bottom" fxLayout="row wrap"
                fxLayoutAlign="space-between center">

            <div fxFlex class="py-16 h3">{{widgetTitle}}</div>

            <div fxFlex="0 1 auto" class="py-16" fxLayout="row">

                <div *ngIf="hasTimeSeriesData">
                <button mat-button class="px-16"
                        *ngFor="let range of chartSource | keys"
                        (click)="chartWidget.currentRange = range.key"
                        [ngClass]="{'accent' : chartWidget.currentRange == range.key}">
                    {{range.key}}
                </button>
                </div>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
                <div class="spinner" *ngIf="!hasTimeSeriesData">
                    Loading....<mat-spinner diameter="20"></mat-spinner>
                </div>

                <div class="h-420 my-16" fxLayout="row" fxFlex="100" fxFlex.gt-sm="100">

                    <div fxFlex="100" *ngIf="type == 'line' && hasTimeSeriesData">
                        <ngx-charts-line-chart
                            *fuseIfOnDom
                            animations="false"
                            [animations]="false"
                            [scheme]="chartWidget.scheme"
                            [results]="this.chartSource[this.chartWidget.currentRange]"
                            [gradient]="chartWidget.gradient"
                            [xAxis]="chartWidget.xAxis"
                            [yAxis]="chartWidget.yAxis"
                            [legend]="chartWidget.legend"
                            [showXAxisLabel]="chartWidget.showXAxisLabel"
                            [showYAxisLabel]="chartWidget.showYAxisLabel"
                            [xAxisLabel]="chartWidget.xAxisLabel"
                            [yAxisLabel]="chartWidget.yAxisLabel"
                            (select)="chartWidget.onSelect($event)">
                        </ngx-charts-line-chart>
                    </div>

                    <div fxFlex="100" *ngIf="type == 'area' && hasTimeSeriesData">
                        <ngx-charts-area-chart
                            *fuseIfOnDom
                            animations="false"
                            [animations]="false"
                            [scheme]="chartWidget.scheme"
                            [results]="this.chartSource[this.chartWidget.currentRange]"
                            [gradient]="chartWidget.gradient"
                            [xAxis]="chartWidget.xAxis"
                            [yAxis]="chartWidget.yAxis"
                            [legend]="chartWidget.legend"
                            [showXAxisLabel]="chartWidget.showXAxisLabel"
                            [showYAxisLabel]="chartWidget.showYAxisLabel"
                            [xAxisLabel]="chartWidget.xAxisLabel"
                            [yAxisLabel]="chartWidget.yAxisLabel"
                            (select)="chartWidget.onSelect($event)">
                        </ngx-charts-area-chart>
                    </div>

                    <div fxFlex="100" *ngIf="type == 'normalised-area' && hasTimeSeriesData">
                        <ngx-charts-area-chart-normalized
                            *fuseIfOnDom
                            animations="false"
                            [animations]="false"
                            [scheme]="chartWidget.scheme"
                            [results]="this.chartSource[this.chartWidget.currentRange]"
                            [gradient]="chartWidget.gradient"
                            [xAxis]="chartWidget.xAxis"
                            [yAxis]="chartWidget.yAxis"
                            [legend]="chartWidget.legend"
                            [showXAxisLabel]="chartWidget.showXAxisLabel"
                            [showYAxisLabel]="chartWidget.showYAxisLabel"
                            [xAxisLabel]="chartWidget.xAxisLabel"
                            [yAxisLabel]="chartWidget.yAxisLabel"
                            (select)="chartWidget.onSelect($event)">
                        </ngx-charts-area-chart-normalized>
                    </div>

                    <div fxFlex="100" *ngIf="type == 'bar-stacked' && hasTimeSeriesData">
                        <ngx-charts-bar-vertical-stacked
                            *fuseIfOnDom
                            animations="false"
                            [animations]="false"
                            [scheme]="chartWidget.scheme"
                            [results]="this.chartSource[this.chartWidget.currentRange]"
                            [gradient]="chartWidget.gradient"
                            [xAxis]="chartWidget.xAxis"
                            [yAxis]="chartWidget.yAxis"
                            [legend]="chartWidget.legend"
                            [showXAxisLabel]="chartWidget.showXAxisLabel"
                            [showYAxisLabel]="chartWidget.showYAxisLabel"
                            [xAxisLabel]="chartWidget.xAxisLabel"
                            [yAxisLabel]="chartWidget.yAxisLabel"
                            (select)="chartWidget.onSelect($event)">
                        </ngx-charts-bar-vertical-stacked>                
                    </div>

                </div>
        </div>
    </div>
    <!-- / Front -->

</fuse-widget>
<!-- / WIDGET 5 -->