import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { NgxsProgressDotsModule } from '@biotaware/ngx-system'

// Shared app components
import { BiotaSenseSubjectPinComponent } from './biotasense-subjectpin.component'

@NgModule({
    imports: [CommonModule, NgxsProgressDotsModule],
    declarations: [BiotaSenseSubjectPinComponent],
    exports: [BiotaSenseSubjectPinComponent],
})
export class BiotaSenseSubjectPinModule {}
