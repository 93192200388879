import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, ViewChild } from '@angular/core'

import { Router } from '@angular/router'

import { AppComponent } from '../../../app.component'
import { NgxsAuthenticationService, AuthenticationState } from '@biotaware/ngx-server'

import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { Md5 } from 'ts-md5/dist/md5'

//import { startWith, tap, delay } from 'rxjs/operators';
import { NgxsTenantService } from '@biotaware/ngx-server'
import { StudyService } from '../../services/study.service'
import { IStudy } from '@biotaware/models-biotasense-db-biotasense/src/Study.model'
import { MatDialog } from '@angular/material/dialog'
import { FormGroup } from '@angular/forms'
import { NewStudyDialogComponent } from './new-study/new-study-form.component'
import { EditStudyDialogComponent } from './edit-study/edit-study-form.component'
import { UserService } from '../../services/user.service'
import { RoleCaps, RolePermissions, RoleType } from '@biotaware/models-biotasense-db-biotasense/src/RolePermissions.service'

@Component({
    selector: 'app-toolbar-content',
    templateUrl: './toolbar-content.component.html',
    styleUrls: ['./toolbar-content.component.scss'],
    //changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarContentComponent implements OnInit, AfterViewInit, OnDestroy {
    username = ''
    email = ''
    tenantId = ''
    profileImageUrl = ''
    loggedIn = false
    studyName = ''
    studyId = ''
    userId = ''
    userRole = ''

    study$: Subscription = null
    studies: IStudy[] = []
    userSubscription: Subscription = null

    canAddDeleteEditStudies = false

    @ViewChild('menuTrigger') trigger

    private _unsubscribeAll: Subject<any> = new Subject()

    constructor(
        private _app: AppComponent,
        private _authService: NgxsAuthenticationService,
        private _tenantService: NgxsTenantService,
        private _studyService: StudyService,
        private _userService: UserService,
        private _matDialog: MatDialog,
        private _router: Router, //private _cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        // Subscribe to application user updates so we can keep the toolbar
        // user info up to date.
        //
        this._authService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user) => {
            this.email = user ? user.email : ''
            const username = user ? user.displayName : ''
            this.username = username || this.email
            if (user) {
                this.profileImageUrl = user.photoURL ? user.photoURL : 'https://www.gravatar.com/avatar/' + Md5.hashStr(this.email.toLowerCase())
            } else {
                this.profileImageUrl = 'assets/images/avatars/profile.jpg'
            }
            if (user) {
                this.userId = user.uid
            }
        })

        this._authService.authenticationState$.pipe(takeUntil(this._unsubscribeAll)).subscribe((state) => {
            this.loggedIn = state === AuthenticationState.LoggedIn
        })

        this._studyService.study$.pipe(takeUntil(this._unsubscribeAll)).subscribe((study) => {
            this.studyName = study ? study.name : ''
            this.studyId = study ? study.studyId : ''

            if (study) {
                this.setCurrentStudyToTop()

                // TODO: seems an odd place to put this but we know by now that we have a tenant
                // and a study, so we've got all we need to get the user info.
                // Replace when we've got a more standard way of having the user doc info.
                this.userSubscription = this._userService
                    .getUserCollection()
                    .doc(this.userId)
                    .valueChanges()
                    .subscribe((user) => {
                        if (this.username == this.email) {
                            this.username = user.fullname
                        } else {
                            this.username = user.fullname
                        }
                        if (user?.role) {
                            this.userRole = user.role.charAt(0).toUpperCase() + user.role.slice(1)
                        } else {
                            this.userRole = 'Role not defined'
                        }

                        const capabilities = RolePermissions.getCapabilities(user.role as RoleType)
                        this.canAddDeleteEditStudies = RolePermissions.hasCapability(capabilities, RoleCaps.CAN_ADD_DELETE_EDIT_STUDIES)
                    })
            }
        })

        this._studyService.studies$.pipe(takeUntil(this._unsubscribeAll)).subscribe((studies: IStudy[]) => {
            this.studies = [...studies]

            this.setCurrentStudyToTop()
        })
    }

    ngAfterViewInit(): void {}

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe()
        }
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next()
        this._unsubscribeAll.complete()
    }

    get isLoggedIn(): boolean {
        return this.loggedIn
    }

    onLogout(): void {
        this._app.logout()
    }

    onSelectProfile(): void {
        this._router.navigate(['/team/' + this.userId])
    }

    onSelectStudy(studyId: string): void {
        this._studyService.selectStudy(studyId)
    }

    onCreateStudy() {
        const dialogRef = this._matDialog.open(NewStudyDialogComponent, {
            panelClass: 'event-form-dialog',
            data: {
                action: 'new',
                date: '',
            },
        })

        dialogRef.afterClosed().subscribe((response: FormGroup) => {
            if (!response) {
                return
            }
            const newEvent = response.getRawValue()
            // newEvent.actions = this.actions;
            // this.events.push(newEvent);
            // this.refresh.next(true);
        })
    }

    onRenameStudy() {
        const dialogRef = this._matDialog.open(EditStudyDialogComponent, {
            panelClass: 'event-form-dialog',
            data: {
                studyName: this.studyName,
            },
        })

        dialogRef.afterClosed().subscribe((response: FormGroup) => {
            if (!response) {
                return
            }
            const newEvent = response.getRawValue()
            // newEvent.actions = this.actions;
            // this.events.push(newEvent);
            // this.refresh.next(true);
        })
    }

    getTenantName(): string {
        return this._tenantService.tenantName
    }

    getStudyName(): string {
        return this.studyName
    }

    getUserName(): string {
        return this.username
    }

    getUserRole(): string {
        return this.userRole
    }

    getUserAvatar(): string {
        return this.profileImageUrl
    }

    /**
     * Sets the currently selected study as the first in the menu list
     */
    private setCurrentStudyToTop() {
        const currentStudy = this.studies.filter((study) => study.studyId === this.studyId)
        const filtered = this.studies.filter((study) => study.studyId !== this.studyId)

        this.studies = currentStudy.concat(filtered)
    }
}
