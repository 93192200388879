import { NgModule, Optional, SkipSelf } from '@angular/core'
import { CommonModule } from '@angular/common'

// Fuse
import { FuseSharedModule } from '../shared.module'
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '../components'
import { FuseLayoutModule } from '../layout/layout.module'

import { FuseAppComponent } from './fuse-app.component'

@NgModule({
    declarations: [FuseAppComponent],
    imports: [CommonModule, FuseProgressBarModule, FuseSharedModule, FuseSidebarModule, FuseThemeOptionsModule, FuseLayoutModule],
    exports: [FuseAppComponent],
})
export class FuseAppModule {
    constructor(@Optional() @SkipSelf() parentModule: FuseAppModule) {
        if (parentModule) {
            throw new Error('FuseAppModule is already loaded. Import it in the AppModule only!')
        }
    }
}
