import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { FuseSharedModule } from '@biotaware/ngx-fuse'
import { FuseWidgetModule } from '@biotaware/ngx-fuse'

import { MatButtonModule } from '@angular/material/button'

import { NgxChartsModule } from '@swimlane/ngx-charts'

// Shared app components
import { TimeSeriesChartComponent } from './timeseries-chart.component'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

@NgModule({
    imports: [CommonModule, FuseSharedModule, FuseWidgetModule, MatButtonModule, MatProgressSpinnerModule, NgxChartsModule],
    declarations: [TimeSeriesChartComponent],
    exports: [TimeSeriesChartComponent],
})
export class TimeSeriesChartModule {}
