import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

// Main Routes
import { MainRoutes } from './main/main.routes'

const routes: Routes = [...MainRoutes]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
