<fuse-widget class="line-chart-widget">

      <!-- Front -->
      <div class="fuse-widget-front">

            <div class="px-16 border-bottom" fxLayout="row"
                  *ngIf="widgetTitle"
                  fxLayoutAlign="space-between center">

                  <div fxFlex class="py-16 h3">{{widgetTitle}}</div>

                  <div fxFlex="0 1 auto" class="py-16" fxLayout="row">
                  <!-- <button mat-button class="px-16"
                              *ngFor="let range of widgets.widget5.mainChart | keys"
                              (click)="widget5.currentRange = range.key"
                              [ngClass]="{'accent' : widget5.currentRange == range.key}">
                        {{range.key}}
                  </button> -->
                  </div>
            </div>
  
            <div class="h-420 my-16">
                  <ngx-charts-area-chart
                        [scheme]="colorScheme"
                        [animations]="animate"
                        [results]="multi"
                        [gradient]="gradient"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        [legend]="showLegend"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxisLabel]="xAxisLabelNgx"
                        [yAxisLabel]="yAxisLabelNgx"
                        [timeline]="timeline"
                        [curve]="curve"
                        [xAxisTicks]="xAxisTicks">
                  </ngx-charts-area-chart>

            </div>

      </div>
</fuse-widget>