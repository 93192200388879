/**
 * Firebase User Authentication Service
 * Only provides access to the Firebase Auth User record, not any database User records, since that may be app-specific
 * Handles single or multi tenant authentication
 */
import { Injectable } from '@angular/core'
import { Observable, Subject, BehaviorSubject } from 'rxjs'
import { AngularFireAuth } from '@angular/fire/auth'

import { NgxsTenantService } from './tenant.service'

import firebase from 'firebase/app'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { NgxsEnvironmentService } from '@biotaware/ngx-system'

export enum AuthenticationState {
    Authenticating = 0,
    LoggedIn = 1,
    LoggedOut = 2,
}

@Injectable({ providedIn: 'root' })
export class NgxsAuthenticationService {
    // stores
    private readonly _user: BehaviorSubject<firebase.User | null> = new BehaviorSubject(null)
    private readonly _userToken: BehaviorSubject<string | null> = new BehaviorSubject(null)
    private readonly _authenticationState = new BehaviorSubject<AuthenticationState>(AuthenticationState.Authenticating)

    constructor(
        //private _firestore: AngularFirestore,
        private _angularFireAuth: AngularFireAuth,
        private _tenant: NgxsTenantService,
        private _environment: NgxsEnvironmentService,
        private http: HttpClient,
    ) {
        // register listener for auth state changes
        // this listener handles the bulk of authentication work, updating our state
        _angularFireAuth.onAuthStateChanged((user) => {
            if (user) {
                console.log('onAuthStateChanged - USER IS LOGGED IN')
                this._user.next(user)
                const tenantId = user.tenantId
                if (this._environment.isMultiTenant() && !tenantId) {
                    console.error('Tenant ID is invalid. Did this login use the primary account?')
                    this.logout()
                }
                this._tenant.setTenant(user.tenantId)
                this._authenticationState.next(AuthenticationState.LoggedIn)
            } else {
                console.log('onAuthStateChanged - USER IS LOGGED OUT')
                this._tenant.setTenant(null)
                this._user.next(null)
                this._authenticationState.next(AuthenticationState.LoggedOut)
            }
        })

        // subscribe to token updates
        _angularFireAuth.onIdTokenChanged(
            (userUpdate) => {
                if (userUpdate) {
                    userUpdate.getIdToken().then((t) => {
                        //console.log(" - User Token Updated '" + t + "'")
                        console.log(' - User Token Updated')
                        this._userToken.next(t)
                    })
                } else {
                    console.log(' - User Token Invalidated')
                    this._userToken.next(null)
                }
            },
            (error) => {
                console.log(" - User Token Invalidated '" + error + "'")
                this._userToken.next(null)
            },
        )
    }

    // selectors
    public readonly user$ = this._user.asObservable()
    public readonly userToken$ = this._userToken.asObservable()
    public readonly authenticationState$ = this._authenticationState.asObservable()

    // snapshots

    /**
     * Return a snapshot of the currently signed in user
     */
    public get user(): firebase.User | null {
        return this._user.value
    }

    /**
     * Retrieve the currently logged in user's JWT access token or empty string if not logged in
     */
    public get userToken(): string | null {
        return this._userToken.value
    }

    /**
     * Retrieve the current state of the authentication service
     */
    public get authenticationState(): AuthenticationState {
        return this._authenticationState.value
    }

    /**
     * Helper function to determine if we are currently in an authenticated state
     */
    get isLoggedIn(): boolean {
        return this.authenticationState === AuthenticationState.LoggedIn
    }

    /**
     * Attempt authorisation with the given user login credentials
     * @param email
     * @param password
     */
    login(email: string, password: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this._authenticationState.next(AuthenticationState.Authenticating)
            this._angularFireAuth.signInWithEmailAndPassword(email, password).then(
                (res) => {
                    console.log('login() succeeded')
                    // Nothing to do here...
                    // Successful login will trigger onAuthStateChanged
                    // which is handled elsewhere
                    resolve(res)
                },
                (err) => reject(err),
            )
        })
    }

    /**
     * Attempt authorisation with the given tenant user login credentials
     * @param tenantName
     * @param email
     * @param password
     */
    loginTenant(tenantName: string, email: string, password: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this._authenticationState.next(AuthenticationState.Authenticating)

            const emailPasswordEncoded = btoa(email + ':' + password)
            const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'basic ' + emailPasswordEncoded)

            const apiHost = this._environment.getApiHost()
            this.http
                .get<any>(apiHost + '/api/v1/accounts/tenant?tenantName=' + tenantName, {
                    headers: headers,
                })
                .subscribe(
                    (response) => {
                        console.log('*** DATA = ' + JSON.stringify(response))
                        const tenantId = response.tenants[0].id

                        // AngularFire 6 has changed how the tenantId is set for the Auth. They want us to inject
                        // TENANT_ID but this pre-supposes we have a fixed tenant rather than a dynamic tenant
                        // so we set the tenantId directly on the auth object
                        firebase.auth().tenantId = tenantId

                        this._angularFireAuth.signInWithEmailAndPassword(email, password).then(
                            (res) => {
                                console.log('loginTenant() succeeded')

                                // Now update the last login time on the server
                                const headers = new HttpHeaders().set('Authorization', 'basic ' + emailPasswordEncoded)

                                this.http
                                    .post<any>(
                                        apiHost + '/api/v1/users/updateLastLogin',
                                        {
                                            tenantId: tenantId,
                                            email: email,
                                        },
                                        {
                                            headers: headers,
                                        },
                                    )
                                    .subscribe(
                                        (response) => {
                                            // Final resolve
                                            resolve(res)
                                        },
                                        (error) => {
                                            reject(error)
                                        },
                                    )
                            },
                            (error) => reject(error),
                        )
                    },
                    (error) => {
                        reject(error)
                    },
                )
        })
    }

    /**
     * Sign out the current user
     */
    logout(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this._angularFireAuth.signOut().then(
                (res) => {
                    console.log('logout() succeeded')
                    // Nothing to do here...
                    // Successful logout will trigger onAuthStateChanged
                    // which is handled elsewhere
                    resolve(res)
                },
                (err) => reject(err),
            )
        })
    }

    /**
     * Register new tenant
     */
    register(tenant: string, fullname: string, email: string, password: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const emailPasswordEncoded = btoa(email + ':' + password)
            const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'basic ' + emailPasswordEncoded)

            const apiHost = this._environment.getApiHost()
            this.http
                .post<any>(
                    apiHost + '/api/v1/accounts/tenant',
                    {
                        tenantName: tenant,
                        userName: fullname,
                    },
                    {
                        headers: headers,
                    },
                )
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (error) => {
                        reject(error)
                    },
                )
        })
    }

    /**
     * Register a new user using an invite
     * @param inviteId
     * @param fullname
     * @param email
     * @param password
     * @returns
     */
    registerByInvite(inviteId: string, fullname: string, email: string, password: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const emailPasswordEncoded = btoa(email + ':' + password)
            const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'basic ' + emailPasswordEncoded)

            const apiHost = this._environment.getApiHost()
            this.http
                .post<any>(
                    apiHost + '/api/v1/users/add',
                    {
                        inviteId: inviteId,
                        displayName: fullname,
                        email: email,
                        password: password,
                    },
                    {
                        headers: headers,
                    },
                )
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (error) => {
                        reject(error)
                    },
                )
        })
    }

    /**
     * Call to check if an invite password token is expired
     * @param inviteId
     * @returns
     */
    inviteExpired(inviteId: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'none')

            const apiHost = this._environment.getApiHost()
            this.http
                .get<any>(apiHost + '/api/v1/users/inviteExpired/' + inviteId, {
                    headers: headers,
                })
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (error) => {
                        reject(error)
                    },
                )
        })
    }

    /**
     * Reset password
     * @param tenantName
     * @param email
     * @returns
     */
    resetPassword(tenantName: string, email: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const emailPasswordEncoded = btoa(email + ':' + 'DummyPassword9')
            const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'basic ' + emailPasswordEncoded)

            const apiHost = this._environment.getApiHost()

            this.http
                .post<any>(
                    apiHost + '/api/v1/users/resetPassword',
                    {
                        tenantName: tenantName,
                        email: email,
                    },
                    {
                        headers: headers,
                    },
                )
                .subscribe(
                    (response) => {
                        // Final resolve
                        resolve(response)
                    },
                    (error) => {
                        reject(error)
                    },
                )
        })
    }

    /**
     * Register a new user using an invite
     * @param inviteId
     * @param fullname
     * @param email
     * @param password
     * @returns
     */
    resetPasswordEnact(resetPasswordId: string, email: string, password: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const emailPasswordEncoded = btoa(email + ':' + password)
            const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'basic ' + emailPasswordEncoded)

            const apiHost = this._environment.getApiHost()
            this.http
                .post<any>(
                    apiHost + '/api/v1/users/resetPasswordEnact',
                    {
                        resetPasswordId: resetPasswordId,
                        email: email,
                        password: password,
                    },
                    {
                        headers: headers,
                    },
                )
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (error) => {
                        reject(error)
                    },
                )
        })
    }

    /**
     * Call to check if a reset password token is expired
     * @param resetPasswordId
     * @returns
     */
    resetPasswordExpired(resetPasswordId: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'none')

            const apiHost = this._environment.getApiHost()
            this.http
                .get<any>(apiHost + '/api/v1/users/resetPasswordExpired/' + resetPasswordId, {
                    headers: headers,
                })
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (error) => {
                        reject(error)
                    },
                )
        })
    }

    /**
     * Given a tenant name, get the actual tenant id
     * @param tenantName
     * @param email
     */
    getTenantId(tenantName: string, email: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this._authenticationState.next(AuthenticationState.Authenticating)

            const emailPasswordEncoded = btoa(email + ':DummyPassword9')
            const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'basic ' + emailPasswordEncoded)

            const apiHost = this._environment.getApiHost()
            this.http
                .get<any>(apiHost + '/api/v1/accounts/tenant?tenantName=' + tenantName, {
                    headers: headers,
                })
                .subscribe(
                    (response) => {
                        const tenantId = response.data.tenants[0].id

                        resolve(tenantId)
                    },
                    (error) => {
                        reject(error)
                    },
                )
        })
    }
}
