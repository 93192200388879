import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { FuseSharedModule } from '@biotaware/ngx-fuse'
import { FuseWidgetModule } from '@biotaware/ngx-fuse'

import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'

import { NgxChartsModule } from '@swimlane/ngx-charts'

// Shared app components
import { BiotaSenseAnalysisWidgetComponent } from './biotasense-analysis-widget.component'

@NgModule({
    imports: [CommonModule, FuseSharedModule, FuseWidgetModule, MatFormFieldModule, MatSelectModule, NgxChartsModule],
    declarations: [BiotaSenseAnalysisWidgetComponent],
    entryComponents: [],
    exports: [BiotaSenseAnalysisWidgetComponent],
    providers: [],
})
export class BiotaSenseAnalysisWidgetModule {}
