/**
 * Role rules and capabilities service for Biotasense
 * This class is used on both client and server side.  Client side use
 * determines which fields and controls are available according to the
 * users capabilities specified by their role.  Then, the server side
 * uses this class to double check that these rules are maintained before
 * modifying documents and other data.
 */

// Role capabilities
export const enum RoleCaps {
    CAN_EDIT_OWN_PROFILE = 1,
    CAN_EDIT_OWNER_PROFILES,
    CAN_EDIT_ADMIN_PROFILES,
    CAN_EDIT_MANAGER_PROFILES,
    CAN_EDIT_VIEWER_PROFILES,
    CAN_EDIT_ROLES,
    CAN_DELETE_OTHER_PROFILES,
    CAN_ADD_DELETE_EDIT_STUDIES,
    CAN_INVITE_TEAM_MEMBERS,
    CAN_ADD_PARTICIPANTS,
    CAN_DELETE_PARTICIPANTS,
    CAN_ACTIVATE_AND_DEACTIVATE_PARTICIPANTS,
    CAN_EXPORT_AND_DOWNLOAD,
    CAN_GENERATE_API_KEYS,
    CAN_HANDLE_BILLING,
}

export const UserRoles = ['owner', 'administrator', 'manager', 'viewer'] as const

export type RoleType = typeof UserRoles[number]

export abstract class RolePermissions {
    // Define the individual roles we recognise
    static roleOwner = UserRoles[0]
    static roleAdmin = UserRoles[1]
    static roleManager = UserRoles[2]
    static roleViewer = UserRoles[3]

    // An array of the rolee. NB: it's important these are low priority to high priority
    static roles = [RolePermissions.roleViewer, RolePermissions.roleManager, RolePermissions.roleAdmin, RolePermissions.roleOwner]

    /**
     * Return true if role is valid, false if not
     * @param role The role to check
     * @returns true or false
     */
    static validRole(role: RoleType) {
        return this.roles.indexOf(role) != -1
    }

    /**
     * Check if the given array of capabities contain a specific capability
     * @param capabilities The array of capabilities
     * @param capability The individual capability to check for
     * @returns true if the array has the capability, false if not
     */
    public static hasCapability(capabilities: Array<RoleCaps>, capability: RoleCaps) {
        return capabilities.find((c) => c == capability) !== undefined
    }

    /*
     * Uniquely add the capability
     */
    public static addCapability(capabilities: Array<RoleCaps>, capability: RoleCaps) {
        if (!this.hasCapability(capabilities, capability)) {
            capabilities.push(capability)
        }
    }

    /**
     * Return array of capabilities for a given role
     * @param role The given role
     * @returns The array of capabilities for the given role
     */
    public static getCapabilities(role: RoleType): Array<RoleCaps> {
        const capabilities: Array<RoleCaps> = []

        if (!this.validRole(role)) {
            throw Error('getCapabilities(): Unhandled role type: ' + role)
        }

        switch (role) {
            case this.roleViewer: {
                this.addCapability(capabilities, RoleCaps.CAN_EDIT_OWN_PROFILE)
                this.addCapability(capabilities, RoleCaps.CAN_EXPORT_AND_DOWNLOAD)
                break
            }
            case this.roleManager: {
                this.addCapability(capabilities, RoleCaps.CAN_EDIT_OWN_PROFILE)
                this.addCapability(capabilities, RoleCaps.CAN_ADD_PARTICIPANTS)
                this.addCapability(capabilities, RoleCaps.CAN_DELETE_PARTICIPANTS)
                this.addCapability(capabilities, RoleCaps.CAN_ACTIVATE_AND_DEACTIVATE_PARTICIPANTS)
                this.addCapability(capabilities, RoleCaps.CAN_EXPORT_AND_DOWNLOAD)
                this.addCapability(capabilities, RoleCaps.CAN_GENERATE_API_KEYS)
                break
            }
            case this.roleAdmin: {
                this.addCapability(capabilities, RoleCaps.CAN_EDIT_OWN_PROFILE)
                this.addCapability(capabilities, RoleCaps.CAN_EDIT_VIEWER_PROFILES)
                this.addCapability(capabilities, RoleCaps.CAN_EDIT_MANAGER_PROFILES)
                this.addCapability(capabilities, RoleCaps.CAN_EDIT_ROLES)
                this.addCapability(capabilities, RoleCaps.CAN_DELETE_OTHER_PROFILES)
                this.addCapability(capabilities, RoleCaps.CAN_ADD_DELETE_EDIT_STUDIES)
                this.addCapability(capabilities, RoleCaps.CAN_INVITE_TEAM_MEMBERS)
                this.addCapability(capabilities, RoleCaps.CAN_ADD_PARTICIPANTS)
                this.addCapability(capabilities, RoleCaps.CAN_DELETE_PARTICIPANTS)
                this.addCapability(capabilities, RoleCaps.CAN_ACTIVATE_AND_DEACTIVATE_PARTICIPANTS)
                this.addCapability(capabilities, RoleCaps.CAN_EXPORT_AND_DOWNLOAD)
                this.addCapability(capabilities, RoleCaps.CAN_GENERATE_API_KEYS)
                break
            }
            case this.roleOwner: {
                this.addCapability(capabilities, RoleCaps.CAN_EDIT_OWN_PROFILE)
                this.addCapability(capabilities, RoleCaps.CAN_EDIT_VIEWER_PROFILES)
                this.addCapability(capabilities, RoleCaps.CAN_EDIT_MANAGER_PROFILES)
                this.addCapability(capabilities, RoleCaps.CAN_EDIT_ADMIN_PROFILES)
                this.addCapability(capabilities, RoleCaps.CAN_EDIT_ROLES)
                this.addCapability(capabilities, RoleCaps.CAN_DELETE_OTHER_PROFILES)
                this.addCapability(capabilities, RoleCaps.CAN_ADD_DELETE_EDIT_STUDIES)
                this.addCapability(capabilities, RoleCaps.CAN_INVITE_TEAM_MEMBERS)
                this.addCapability(capabilities, RoleCaps.CAN_ADD_PARTICIPANTS)
                this.addCapability(capabilities, RoleCaps.CAN_DELETE_PARTICIPANTS)
                this.addCapability(capabilities, RoleCaps.CAN_ACTIVATE_AND_DEACTIVATE_PARTICIPANTS)
                this.addCapability(capabilities, RoleCaps.CAN_EXPORT_AND_DOWNLOAD)
                this.addCapability(capabilities, RoleCaps.CAN_GENERATE_API_KEYS)
                this.addCapability(capabilities, RoleCaps.CAN_HANDLE_BILLING)
                break
            }
        }

        return capabilities
    }

    /**
     * Return a numeric priority for the role
     * @param role The role to check
     * @returns The index priority of the role
     */
    static rolePriority(role: RoleType) {
        return this.roles.indexOf(role)
    }

    /**
     * Role rule checking to generally be performed *after* profile edits are made.
     * @param authrole The role of the current logged in user
     * @param authuid The user id of the current logged in user
     * @param otherrole The role of the user who's profile data we're editing
     * @param otheruid The user id of the user who's profile data we're editing
     * @param editrole The value of the edited role (it may be the same, i.e. not actually editied)
     * @returns true if the edit is allowed, false if not
     */
    static allowableProfileEdit(authrole: RoleType, authuid: string, otherrole: RoleType, otheruid: string, editrole: RoleType): boolean {
        if (!this.validRole(authrole)) {
            throw Error('allowableProfileEdit(): Unhandled auth role type: ' + authrole)
        }

        if (!this.validRole(otherrole)) {
            throw Error('allowableProfileEdit(): Unhandled other role type: ' + authrole)
        }

        if (!this.validRole(editrole)) {
            throw Error('allowableProfileEdit(): Unhandled edit role type: ' + authrole)
        }

        const caps = this.getCapabilities(authrole)

        // Editing ourselves?
        if (authuid === otheruid) {
            // Can never change our own role!
            if (editrole !== authrole) return false

            if (this.hasCapability(caps, RoleCaps.CAN_EDIT_OWN_PROFILE)) {
                return true
            }
            return false
        }

        const authPriority = this.rolePriority(authrole)
        const otherPriority = this.rolePriority(otherrole)
        const editPriority = this.rolePriority(editrole)

        // Editing somebody else...
        // If we're same or lower priority then we can't edit them
        if (authPriority <= otherPriority) {
            return false
        }

        // Check for viewer editing
        if (otherrole === this.roleViewer && !this.hasCapability(caps, RoleCaps.CAN_EDIT_VIEWER_PROFILES)) {
            return false
        }

        // Check for manager editing
        if (otherrole === this.roleManager && !this.hasCapability(caps, RoleCaps.CAN_EDIT_MANAGER_PROFILES)) {
            return false
        }

        // Check for admin editing
        if (otherrole === this.roleAdmin && !this.hasCapability(caps, RoleCaps.CAN_EDIT_ADMIN_PROFILES)) {
            return false
        }

        // Check for owner editing
        if (otherrole === this.roleOwner && !this.hasCapability(caps, RoleCaps.CAN_EDIT_OWNER_PROFILES)) {
            return false
        }

        // If we're editing roles then check if we're allowed to do that
        if (otherrole !== editrole && !this.hasCapability(caps, RoleCaps.CAN_EDIT_ROLES)) {
            return false
        }

        // At this point we're allowed edits, but check that we're not illegally promoting a role beyond our own
        if (editPriority > authPriority) return false

        return true
    }

    /**
     * Pre-check to see if we're allowed to edit a users profile given the rules of our own role
     * @param authrole The role of the current logged in user
     * @param authuid The user id of the current logged in user
     * @param otherrole The role of the user who's profile data we're editing
     * @param otheruid The user id of the user who's profile data we're editing
     * @returns true if we're able to edit the profile, false if not
     */
    static canProfileEdit(authrole: RoleType, authuid: string, otherrole: RoleType, otheruid: string): boolean {
        if (!this.validRole(authrole)) {
            throw Error('allowableProfileEdit(): Unhandled auth role type: ' + authrole)
        }

        if (!this.validRole(otherrole)) {
            throw Error('allowableProfileEdit(): Unhandled other role type: ' + authrole)
        }

        const caps = this.getCapabilities(authrole)

        // Editing ourselves?
        if (authuid === otheruid) {
            if (this.hasCapability(caps, RoleCaps.CAN_EDIT_OWN_PROFILE)) {
                return true
            }
            return false
        }

        const authPriority = this.rolePriority(authrole)
        const otherPriority = this.rolePriority(otherrole)

        // Editing somebody else...
        // If we're same or lower priority then we can't edit them
        if (authPriority <= otherPriority) {
            return false
        }

        // Check for viewer editing
        if (otherrole === this.roleViewer && !this.hasCapability(caps, RoleCaps.CAN_EDIT_VIEWER_PROFILES)) {
            return false
        }

        // Check for manager editing
        if (otherrole === this.roleManager && !this.hasCapability(caps, RoleCaps.CAN_EDIT_MANAGER_PROFILES)) {
            return false
        }

        // Check for admin editing
        if (otherrole === this.roleAdmin && !this.hasCapability(caps, RoleCaps.CAN_EDIT_ADMIN_PROFILES)) {
            return false
        }

        // Check for owner editing
        if (otherrole === this.roleOwner && !this.hasCapability(caps, RoleCaps.CAN_EDIT_OWNER_PROFILES)) {
            return false
        }

        return true
    }

    /**
     * Pre-check to see if we're allowed to edit a users role given the rules of our own role
     * @param authrole The role of the current logged in user
     * @param authuid The user id of the current logged in user
     * @param otherrole The role of the user who's role we're editing
     * @param otheruid The user id of the user who's role we're editing
     * @returns true if we're able to edit the role, false if not
     */
    static canRoleEdit(authrole: RoleType, authuid: string, otherrole: RoleType, otheruid: string): boolean {
        if (!this.validRole(authrole)) {
            throw Error('allowableProfileEdit(): Unhandled auth role type: ' + authrole)
        }

        if (!this.validRole(otherrole)) {
            throw Error('allowableProfileEdit(): Unhandled other role type: ' + authrole)
        }

        // If we're not allwed to edit at all then we certainly can't edit the role!
        if (!this.canProfileEdit(authrole, authuid, otherrole, otheruid)) {
            return false
        }

        // Can never edit our own role!
        if (authuid === otheruid) return false

        return true
    }

    /**
     * For the given role, return the roles we're allowed to promote/demote to
     * @param role The other role in question
     * @returns An array of roles of the form [{ name: 'Owner', value: 'owner'}, ...] ideal for UI work
     */
    static getAllowedRoles(role: RoleType): Array<any> {
        if (!this.validRole(role)) {
            throw Error('getAllowedRoles(): Unhandled auth role type: ' + role)
        }

        const roleOptions = []
        const maxRole = this.rolePriority(role)

        for (let i = 0; i <= maxRole; i++) {
            const otherrole = this.roles[i]
            roleOptions.push({ name: otherrole[0].toUpperCase() + otherrole.substring(1), value: otherrole })
        }

        roleOptions.reverse()

        return roleOptions
    }

    /**
     * Get the best (highest priority) role from an array of role identifiers
     * @param roles An array of role identifiers
     * @returns best role found or '' if no recognised roles found
     */
    static getBestRole(roles: string[]) {
        if (roles.indexOf(this.roleOwner) != -1) return this.roleOwner
        if (roles.indexOf(this.roleAdmin) != -1) return this.roleAdmin
        if (roles.indexOf(this.roleManager) != -1) return this.roleManager
        if (roles.indexOf(this.roleViewer) != -1) return this.roleViewer
        throw Error('No valid role found amongst: ' + JSON.stringify(roles))
    }
}
