import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core'

@Component({
    selector: 'app-biotasense-simple-data-widget',
    templateUrl: './biotasense-simple-data-widget.component.html',
    styleUrls: ['./biotasense-simple-data-widget.component.scss'],
})
export class BiotaSenseSimpleDataWidgetComponent implements OnInit, OnChanges {
    @Input() widgetTitle = '' // title of the data widget
    @Input() dataColour = 'light-blue-fg' // colour of the data value
    @Input() dataLabel = '' // label of the data widget
    @Input() dataInfo = '' // Information about the data widget [optional]
    @Input() widgetValue: any = '---'

    constructor() {
        /** */
    }

    ngOnInit() {
        // ...
    }

    ngOnChanges(changes: SimpleChanges) {
        // ...
    }
}
