
<div id='app-progress-dots' *ngIf="isLoading" class="w-32 mt-16 mb-16">
  <mat-progress-bar mode="buffer"></mat-progress-bar>
</div>
<!-- render component's child content if finished loading -->
<div *ngIf="!isLoading">
  <ng-content></ng-content>
</div>



<!--
<div *ngIf="isLoading" class="loading-dots" [class.loading-dots--white]="isLight">
  <div class="bounce1"></div>
  <div class="bounce2"></div>
  <div class="bounce3"></div>
</div>
-->
