<!-- https://github.com/swimlane/ngx-charts/blob/403bf9d7a08534476b66c3043c66935e73226bb4/demo/app.component.html -->
 

<div class="data-chart-container-inner">


  <!--


  [trimXAxisTicks]="trimXAxisTicks"
  [trimYAxisTicks]="trimYAxisTicks"
  [maxXAxisTickLength]="maxXAxisTickLength"
  [maxYAxisTickLength]="maxYAxisTickLength"
  -->

  <!-- VERTICAL BAR CHARTS - NORMAL / STACKED / NORMALIZED -->
  <ngx-charts-bar-vertical
    *ngIf="chartType === 'bar-vertical'"
    [view]="view"
    [scheme]="colorScheme"
    [schemeType]="schemeType"
    [customColors]="customColors"
    [results]="single"
    [animations]="animations"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [legendTitle]="legendTitle"
    [legendPosition]="legendPosition"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [tooltipDisabled]="tooltipDisabled"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [showGridLines]="showGridLines"
    [barPadding]="barPadding"
    [roundDomains]="roundDomains"
    [roundEdges]="roundEdges"
    [yScaleMax]="yScaleMax"
    [showDataLabel]="showDataLabel"


    (select)="select($event)"
    (legendLabelClick)="onLegendLabelClick($event)">
  </ngx-charts-bar-vertical>

  <ngx-charts-bar-vertical-stacked
  *ngIf="chartType === 'bar-vertical-stacked'"
  class="chart-container"
  [view]="view"
  [scheme]="colorScheme"
  [schemeType]="schemeType"
  [results]="multi"
  [animations]="animations"
  [gradient]="gradient"
  [tooltipDisabled]="tooltipDisabled"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [legendTitle]="legendTitle"
  [legendPosition]="legendPosition"
  (legendLabelClick)="onLegendLabelClick($event)"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [showGridLines]="showGridLines"
  [barPadding]="barPadding"
  [roundDomains]="roundDomains"
  [yScaleMax]="yScaleMax"
  [showDataLabel]="showDataLabel"

  (select)="select($event)">
  </ngx-charts-bar-vertical-stacked>

  <ngx-charts-bar-vertical-normalized
  *ngIf="chartType === 'bar-vertical-normalized'"
  class="chart-container"
  [view]="view"
  [scheme]="colorScheme"
  [schemeType]="schemeType"
  [results]="multi"
  [animations]="animations"
  [gradient]="gradient"
  [tooltipDisabled]="tooltipDisabled"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [legendTitle]="legendTitle"
  [legendPosition]="legendPosition"
  [showXAxisLabel]="showXAxisLabel"
  (legendLabelClick)="onLegendLabelClick($event)"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [showGridLines]="showGridLines"
  [barPadding]="barPadding"
  [roundDomains]="roundDomains"

  (select)="select($event)">
  </ngx-charts-bar-vertical-normalized>


  <!-- HORIZONTAL BAR CHARTS - PLAIN / STACKED / NORMALIZED -->
  <ngx-charts-bar-horizontal
  *ngIf="chartType === 'bar-horizontal'"
  [view]="view"
  [scheme]="colorScheme"
  [schemeType]="schemeType"
  [results]="single"
  [animations]="animations"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [legendTitle]="legendTitle"
  [legendPosition]="legendPosition"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [tooltipDisabled]="tooltipDisabled"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [showGridLines]="showGridLines"
  [barPadding]="barPadding"
  [roundDomains]="roundDomains"
  [roundEdges]="roundEdges"
  [xScaleMax]="xScaleMax"
  [showDataLabel]="showDataLabel"


  (legendLabelClick)="onLegendLabelClick($event)"
  (select)="select($event)">
  </ngx-charts-bar-horizontal>


  <ngx-charts-bar-horizontal-stacked
  *ngIf="chartType === 'bar-horizontal-stacked'"
  class="chart-container"
  [view]="view"
  [scheme]="colorScheme"
  [schemeType]="schemeType"
  [results]="multi"
  [animations]="animations"
  [gradient]="gradient"
  [tooltipDisabled]="tooltipDisabled"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [legendTitle]="legendTitle"
  [legendPosition]="legendPosition"
  [showXAxisLabel]="showXAxisLabel"
  (legendLabelClick)="onLegendLabelClick($event)"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [showGridLines]="showGridLines"
  [barPadding]="barPadding"
  [roundDomains]="roundDomains"
  [showDataLabel]="showDataLabel"
  [xScaleMax]="xScaleMax"

  (select)="select($event)">
  </ngx-charts-bar-horizontal-stacked>



  <ngx-charts-bar-horizontal-normalized
  *ngIf="chartType === 'bar-horizontal-normalized'"
  class="chart-container"
  [view]="view"
  [scheme]="colorScheme"
  [schemeType]="schemeType"
  [results]="multi"
  [animations]="animations"
  [gradient]="gradient"
  [tooltipDisabled]="tooltipDisabled"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [legendTitle]="legendTitle"
  [legendPosition]="legendPosition"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  (legendLabelClick)="onLegendLabelClick($event)"
  [showGridLines]="showGridLines"
  [barPadding]="barPadding"
  [roundDomains]="roundDomains"

  (select)="select($event)">
  </ngx-charts-bar-horizontal-normalized>


  <!-- NUMBER CARD -->


  <ngx-charts-number-card
  *ngIf="chartType === 'number-card'"
  [view]="view"
  (legendLabelClick)="onLegendLabelClick($event)"
  [scheme]="colorScheme"
  cardColor="#f5f5f5"
  emptyColor="#1e222e"
  [results]="single"
  [animations]="animations"
  (select)="select($event)">
  </ngx-charts-number-card>


  <!-- LINE CHART - PLAIN / AREA   -->

  <ngx-charts-line-chart
  *ngIf="chartType === 'line-chart'"
  [view]="view"
  class="chart-container"
  [scheme]="colorScheme"
  [schemeType]="schemeType"
  [results]="multi"
  [animations]="animations"
  [legend]="showLegend"
  [legendTitle]="legendTitle"
  [legendPosition]="legendPosition"
  (legendLabelClick)="onLegendLabelClick($event)"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [autoScale]="autoScale"
  [xScaleMin]="xScaleMin"
  [xScaleMax]="xScaleMax"
  [yScaleMin]="yScaleMin"
  [yScaleMax]="yScaleMax"
  [timeline]="timeline"
  [showGridLines]="showGridLines"
  [curve]="curve"
  [rangeFillOpacity]="rangeFillOpacity"
  [roundDomains]="roundDomains"
  [tooltipDisabled]="tooltipDisabled"

  (select)="select($event)">
  </ngx-charts-line-chart>

  <!-- AREA CHART - PLAIN / STACKED / NORMALIZED   -->
  <ngx-charts-area-chart
  *ngIf="chartType === 'area-chart'"
  class="chart-container"
  [view]="view"
  [scheme]="colorScheme"
  [schemeType]="schemeType"
  [results]="multi"
  [animations]="animations"
  [legend]="showLegend"
  [legendTitle]="legendTitle"
  [legendPosition]="legendPosition"
  (legendLabelClick)="onLegendLabelClick($event)"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [autoScale]="autoScale"
  [xScaleMin]="xScaleMin"
  [xScaleMax]="xScaleMax"
  [yScaleMin]="yScaleMin"
  [yScaleMax]="yScaleMax"
  [timeline]="timeline"
  [showGridLines]="showGridLines"
  [roundDomains]="roundDomains"
  [curve]="curve"
  [tooltipDisabled]="tooltipDisabled"

  (select)="select($event)">
  </ngx-charts-area-chart>

  <ngx-charts-area-chart-stacked
  *ngIf="chartType === 'area-chart-stacked'"
  class="chart-container"
  [view]="view"
  [scheme]="colorScheme"
  [schemeType]="schemeType"
  [results]="dateData"
  [animations]="animations"
  [legend]="showLegend"
  [legendTitle]="legendTitle"
  [legendPosition]="legendPosition"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  (legendLabelClick)="onLegendLabelClick($event)"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [timeline]="timeline"
  [xScaleMin]="xScaleMin"
  [xScaleMax]="xScaleMax"
  [yScaleMin]="yScaleMin"
  [yScaleMax]="yScaleMax"
  [showGridLines]="showGridLines"
  [roundDomains]="roundDomains"
  [tooltipDisabled]="tooltipDisabled"
  [curve]="curve"

  (select)="select($event)">
  </ngx-charts-area-chart-stacked>


  <ngx-charts-area-chart-normalized
  *ngIf="chartType === 'area-chart-normalized'"
  class="chart-container"
  [view]="view"
  [scheme]="colorScheme"
  [schemeType]="schemeType"
  [results]="dateData"
  [animations]="animations"
  [legend]="showLegend"
  [legendTitle]="legendTitle"
  [legendPosition]="legendPosition"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  (legendLabelClick)="onLegendLabelClick($event)"
  [yAxis]="showYAxis"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [timeline]="timeline"
  [showGridLines]="showGridLines"
  [roundDomains]="roundDomains"
  [tooltipDisabled]="tooltipDisabled"
  [curve]="curve"

  (select)="select($event)">
  </ngx-charts-area-chart-normalized>


  <!-- PIE CHART - NORMAL / ADVANCED / GRID -->
  <ngx-charts-pie-chart
  *ngIf="chartType === 'pie-chart'"
  class="chart-container"
  [view]="view"
  [scheme]="colorScheme"
  [results]="single"
  [animations]="animations"
  [legend]="showLegend"
  [legendTitle]="legendTitle"
  [legendPosition]="legendPosition"
  [explodeSlices]="explodeSlices"
  [labels]="showLabels"
  [doughnut]="doughnut"
  [arcWidth]="arcWidth"
  (legendLabelClick)="onLegendLabelClick($event)"
  [gradient]="gradient"
  [tooltipDisabled]="tooltipDisabled"
  [tooltipText]="pieTooltipText"
  (dblclick)="dblclick($event)"
  (select)="select($event)">
  </ngx-charts-pie-chart>


  <ngx-charts-advanced-pie-chart
  *ngIf="chartType === 'advanced-pie-chart'"
  class="chart-container"
  [view]="view"
  [scheme]="colorScheme"
  [results]="single"
  [valueFormatting]="valueFormatting"
  [animations]="animations"
  (legendLabelClick)="onLegendLabelClick($event)"
  [gradient]="gradient"
  [tooltipDisabled]="tooltipDisabled"
  [tooltipText]="pieTooltipText"
  (select)="select($event)">
  </ngx-charts-advanced-pie-chart>


  <ngx-charts-pie-grid
  *ngIf="chartType === 'pie-grid'"
  class="chart-container"
  [view]="view"
  [scheme]="colorScheme"
  (legendLabelClick)="onLegendLabelClick($event)"
  [results]="single"
  [animations]="animations"
  [tooltipDisabled]="tooltipDisabled"
  [tooltipText]="pieTooltipText"
  (select)="select($event)">
  </ngx-charts-pie-grid>

  <!-- POLAR -->
  <ngx-charts-polar-chart
  *ngIf="chartType === 'polar-chart'"
  [view]="view"
  class="chart-container"
  [scheme]="colorScheme"
  [schemeType]="schemeType"
  [results]="multi"
  [animations]="animations"
  [legend]="showLegend"
  [legendTitle]="legendTitle"
  [legendPosition]="legendPosition"
  (legendLabelClick)="onLegendLabelClick($event)"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [autoScale]="autoScale"
  [showGridLines]="showGridLines"
  [rangeFillOpacity]="rangeFillOpacity"
  [roundDomains]="roundDomains"
  [tooltipDisabled]="tooltipDisabled"
  [showSeriesOnHover]="showSeriesOnHover"

  [curve]="closedCurve"
  (select)="select($event)">
  </ngx-charts-polar-chart>


  <!-- BUBBLE -->
  <ngx-charts-bubble-chart
  *ngIf="chartType === 'bubble-chart'"
  [view]="view"
  class="chart-container"
  [results]="bubble"
  [animations]="animations"
  [showGridLines]="showGridLines"
  [legend]="showLegend"
  [legendTitle]="legendTitle"
  [legendPosition]="legendPosition"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [autoScale]="autoScale"
  [xScaleMin]="xScaleMin"
  [xScaleMax]="xScaleMax"
  [yScaleMin]="yScaleMin"
  [yScaleMax]="yScaleMax"
  [scheme]="colorScheme"
  [schemeType]="schemeType"
  [roundDomains]="roundDomains"
  [tooltipDisabled]="tooltipDisabled"
  [minRadius]="minRadius"
  [maxRadius]="maxRadius">

  </ngx-charts-bubble-chart>

</div>
