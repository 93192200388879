import { Component, OnInit, Input } from '@angular/core'
//import { ModelComponent } from 'app/main/types/metrics/model-component';
//import { AccountsService } from '@biotasdk/services/accounts.service';

/**
 * Simple component that attempts to load the Account Secret used to hold the
 * login password (PIN) for a Subject
 *
 */
@Component({
    selector: 'biotasense-subjectpin',
    templateUrl: './biotasense-subjectpin.component.html',
})
export class BiotaSenseSubjectPinComponent implements OnInit {
    /** Acount to get the pin of */
    @Input() accountId: string

    public isLoading = false
    public hasError = false
    public pinCode: string

    constructor() {
        //private accountSvc: AccountsService
        /** */
    }

    ngOnInit() {
        this.isLoading = true
        /*
        this.accountSvc.getAccountSecret(this.accountId, "subject-pin")
            .then(secretResponse => {

                let secretValue: string = secretResponse.Data.SecretValue;

                if (secretValue.length > 0) {
                    this.pinCode = secretValue;
                }
                else {
                    this.hasError = true;
                }

                this.isLoading = false;
            })

            .catch(error => {
                this.hasError = true;
                this.isLoading = false;
                console.log(error);
            });
            */
    }
}
