/**
 * Main App Shared Module
 * Provides modules that are common to all pages and components in the main app
 */
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'

// Common UI rendering modules to import to minimize clutter in page components
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FlexLayoutModule } from '@angular/flex-layout'

// Common material UI modules
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'
import { MatSelectModule } from '@angular/material/select'
import { MatIconModule } from '@angular/material/icon'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatTabsModule } from '@angular/material/tabs'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatBadgeModule } from '@angular/material/badge'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatCheckboxModule } from '@angular/material/checkbox'

// provide AngularFire modules
import { AngularFireModule } from '@angular/fire'
import { AngularFirestoreModule } from '@angular/fire/firestore'
import { AngularFireFunctionsModule } from '@angular/fire/functions'
import { redirectUnauthorizedTo } from '@angular/fire/auth-guard'

// Set up the unauthorized page to go to
export { AngularFireAuthGuard } from '@angular/fire/auth-guard'
export const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth/login'])
export { NgxsAuthGuard as AuthGuard } from '@biotaware/ngx-server'

// Library app-wide shared modules
import { FuseSharedModule } from '@biotaware/ngx-fuse'
import { NgxsSharedModule } from '@biotaware/ngx-system'

// Shared app modules
import { MainPipesModule } from './pipes/pipes.module'
import { MainComponentsModule } from './components/components.module'

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,

        // Common UI Modules
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,

        // Common Material UI Modules
        MatTableModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSortModule,
        MatDialogModule,
        MatButtonModule,
        MatPaginatorModule,
        MatIconModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatBadgeModule,
        MatToolbarModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatCheckboxModule,

        // AngularFire modules
        AngularFirestoreModule,
        AngularFireModule,
        AngularFireFunctionsModule,

        // Other shared modules
        FuseSharedModule,
        NgxsSharedModule,
        MainPipesModule,
        MainComponentsModule,
    ],
    exports: [
        CommonModule,
        TranslateModule,

        // Common UI Modules
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,

        // Common Material UI Modules
        MatTableModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSortModule,
        MatDialogModule,
        MatButtonModule,
        MatPaginatorModule,
        MatIconModule,
        MatSnackBarModule,

        MatExpansionModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,

        MatBadgeModule,
        MatToolbarModule,

        MatSlideToggleModule,

        MatTooltipModule,

        MatCheckboxModule,

        // AngularFire modules
        AngularFirestoreModule,
        AngularFireModule,
        AngularFireFunctionsModule,

        // Other shared modules
        FuseSharedModule,
        NgxsSharedModule,
        MainPipesModule,
        MainComponentsModule,
    ],
})
export class MainSharedModule {}
