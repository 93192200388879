/*
 * Public API Surface of @biotaware/ngx-system/fuse
 */

// Fuse library exports
export * from './lib/directives/directives'
export * from './lib/directives/fuse-if-on-dom/fuse-if-on-dom.directive'
export * from './lib/directives/fuse-inner-scroll/fuse-inner-scroll.directive'
export * from './lib/directives/fuse-mat-sidenav/fuse-mat-sidenav.directive'
export * from './lib/directives/fuse-mat-sidenav/fuse-mat-sidenav.service'
export * from './lib/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive'
export * from './lib/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.interfaces'

export * from './lib/pipes/pipes.module'
export * from './lib/pipes/keys.pipe'
export * from './lib/pipes/getById.pipe'
export * from './lib/pipes/htmlToPlaintext.pipe'
export * from './lib/pipes/filter.pipe'
export * from './lib/pipes/camelCaseToDash.pipe'

export * from './lib/services/config.service'
export * from './lib/services/copier.service'
export * from './lib/services/match-media.service'
export * from './lib/services/splash-screen.service'
export * from './lib/services/translation-loader.service'

export * from './lib/animations'
export * from './lib/mat-colors'

//export * from './@fuse/components'
export * from './lib/components/confirm-dialog/confirm-dialog.component'
export * from './lib/components/confirm-dialog/confirm-dialog.module'
export * from './lib/components/countdown/countdown.component'
export * from './lib/components/countdown/countdown.module'
export * from './lib/components/demo/demo-content/demo-content.component'
export * from './lib/components/demo/demo-sidebar/demo-sidebar.component'
export * from './lib/components/demo/demo.module'
export * from './lib/components/highlight/highlight.component'
export * from './lib/components/highlight/highlight.module'
export * from './lib/components/material-color-picker/material-color-picker.component'
export * from './lib/components/material-color-picker/material-color-picker.module'
export * from './lib/components/navigation/horizontal/collapsable/collapsable.component'
export * from './lib/components/navigation/horizontal/item/item.component'
export * from './lib/components/navigation/vertical/collapsable/collapsable.component'
export * from './lib/components/navigation/vertical/group/group.component'
export * from './lib/components/navigation/vertical/item/item.component'
export * from './lib/components/navigation/navigation.component'
export * from './lib/components/navigation/navigation.module'
export * from './lib/components/navigation/navigation.service'
export * from './lib/components/progress-bar/progress-bar.component'
export * from './lib/components/progress-bar/progress-bar.module'
export * from './lib/components/progress-bar/progress-bar.service'
export * from './lib/components/search-bar/search-bar.component'
export * from './lib/components/search-bar/search-bar.module'
export * from './lib/components/shortcuts/shortcuts.component'
export * from './lib/components/shortcuts/shortcuts.module'
export * from './lib/components/sidebar/sidebar.component'
export * from './lib/components/sidebar/sidebar.module'
export * from './lib/components/sidebar/sidebar.service'
export * from './lib/components/theme-options/theme-options.component'
export * from './lib/components/theme-options/theme-options.module'
export * from './lib/components/widget/widget-toggle.directive'
export * from './lib/components/widget/widget.component'
export * from './lib/components/widget/widget.module'

export * from './lib/utils/index'
export * from './lib/fuse.module'
export * from './lib/shared.module'

export * from './lib/types/fuse-config'
export * from './lib/types/fuse-navigation'

export * from './lib/layout/layout.module'

// SM: this is a custom module I created for apps that use the fuse system
export * from './lib/app/fuse-app.component'
export * from './lib/app/fuse-app.module'
export * from './lib/app/fuse-app.service'

export * from './lib/ngx-fuse.module'
