import { Component, OnInit, Input } from '@angular/core'
import { Location } from '@angular/common'
import { FormControl } from '@angular/forms'

import { fuseAnimations } from '../../../../../fuse/src/lib/animations'

@Component({
    selector: '[app-pageheader]',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss'],
    animations: fuseAnimations,
})
export class NgxsPageHeaderComponent implements OnInit {
    @Input() title: string
    @Input() searchInput: FormControl
    @Input() searchEnabled: boolean
    @Input() icon = 'account_box'

    constructor(private location: Location) {}

    ngOnInit() {}

    // SM: this needs to be more sophisticated to that it understands the current route and only shows the back button only if back is part of the current route.
    // the intention isnt to implement a generic back button because the browser already has one, rather we want to provide breadcrumb-context navigational convenience to the user.
    goBack() {
        this.location.back()
    }
}
