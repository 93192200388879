import firebase from 'firebase/app'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
//import { MatSort } from '@angular/material/sort';

import { PageEvent } from '@angular/material/paginator'

import { AngularFirestore, AngularFirestoreCollection, CollectionReference, AngularFirestoreCollectionGroup } from '@angular/fire/firestore'
import { Subscription } from 'rxjs'

export class NgxsPagedTable {
    public pageSize = 5
    public pageSizeOptions = [5, 10, 20, 50]
    public firstPaginator: MatPaginator = null
    public secondPaginator: MatPaginator = null

    private tableData: any[] = []
    public dataSource = new MatTableDataSource(this.tableData)

    private firstOnPage: firebase.firestore.DocumentSnapshot[] = []
    private oldPageSize = this.pageSize
    private snapshotSub: Subscription = null

    private filter = ''

    constructor(
        public firestore: AngularFirestore,
        public collectionName: string,
        public orderBy: string,
        public direction: firebase.firestore.OrderByDirection = 'asc',
    ) {
        const item = localStorage.getItem('tablePageSize')
        if (item) {
            this.pageSize = parseInt(item, 10)
            this.oldPageSize = this.pageSize
        } else {
            localStorage.setItem('tablePageSize', this.pageSize.toString())
        }
    }

    /**
     * Set the paginator for the paged table
     * The paginator is the MatPaginator usually defined in the template
     */
    public setPaginator(firstPaginator: MatPaginator, secondPaginator?: MatPaginator): void {
        this.firstPaginator = firstPaginator
        this.secondPaginator = secondPaginator
    }

    /**
     * Set the collection name for the paged table
     * This is handy for when tables are sourced from sub-collections
     */
    public setCollection(collectionName: string): void {
        this.collectionName = collectionName
        this.initialLoadData()
    }

    /**
     * Paginator event
     * Specified and called from the template MatPaginator
     * Called when next/prev page is pressed or number of page
     * elements is changed.
     */
    public paginatorEvent(event?: PageEvent): void {
        let pageIndex = event.pageIndex

        // If we've altered the max number of entries on the page then alter
        // the size and go back to the first page.
        if (event.pageSize !== this.oldPageSize) {
            this.firstOnPage = []
            this.firstPaginator.pageIndex = 0

            if (this.secondPaginator) {
                this.secondPaginator.pageIndex = 0
            }

            pageIndex = 0
        }

        this.firstPaginator.pageIndex = pageIndex

        if (this.secondPaginator) {
            this.secondPaginator.pageIndex = pageIndex
        }

        this.oldPageSize = event.pageSize
        this.pageSize = event.pageSize

        localStorage.setItem('tablePageSize', this.pageSize.toString())

        this.loadData(pageIndex)
    }

    /**
     * Unsubscribe from snapshot updates
     */
    public unsubscribe(): void {
        // Unsubscribe from old collection
        if (this.snapshotSub) {
            this.snapshotSub.unsubscribe()
            this.snapshotSub = null
        }
    }

    /**
     * The initial load data.
     * Call to populate the table first time after view is initialised.
     */
    public initialLoadData(): void {
        this.firstOnPage = []
        this.firstPaginator.pageIndex = 0

        if (this.secondPaginator) {
            this.secondPaginator.pageIndex = 0
        }

        this.loadData(0)
    }

    /**
     * Set the order of the data.
     */
    public setOrderBy(orderBy: string, direction: firebase.firestore.OrderByDirection): void {
        this.orderBy = orderBy
        this.direction = direction
        this.initialLoadData()
    }

    /**
     * Set the order of the data.
     */
    public setFilter(filter: string): void {
        this.filter = filter
        this.initialLoadData()
    }

    /**
     * Return query object containing 'where' clauses for string search
     */
    private createWhereQuery(
        query: firebase.firestore.Query<firebase.firestore.DocumentData>,
        filter: string,
    ): firebase.firestore.Query<firebase.firestore.DocumentData> {
        const splits = filter.split('/')
        const str = splits[splits.length - 1]

        let retQuery = query

        if (str !== '') {
            // Set the search filter
            const strlength = str.length
            const strFrontCode = str.slice(0, strlength - 1)
            const strEndCode = str.slice(strlength - 1, str.length)
            const endString = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1)

            if (splits.length === 1) {
                // Querying a document value field
                retQuery = query.where(this.orderBy, '>=', str).where(this.orderBy, '<', endString)
            } else {
                // Querying a document reference field
                const startDoc = firebase.firestore().collection(splits[0]).doc(str)
                const endDoc = firebase.firestore().collection(splits[0]).doc(endString)

                retQuery = query.where(this.orderBy, '>=', startDoc).where(this.orderBy, '<', endDoc)
            }
        }
        return retQuery
    }

    /**
     * Load table data.
     * Called to populate the data table and to create a snapshot subscription
     * for firestore collection updates.
     */
    private loadData(index: number): void {
        // Unsubscribe from old collection snapshot updates
        this.unsubscribe()

        // If we have a top page index then use that else use null
        // which will start from the beginning in the query
        const doc = index < this.firstOnPage.length ? this.firstOnPage[index] : null

        // Create new collection query with our filtering
        let collectionRef: AngularFirestoreCollection | undefined
        let collectionGroupRef: AngularFirestoreCollectionGroup | undefined

        // collection names that start with '>' are collection groups
        if (this.collectionName[0] === '>') {
            const collectionGroupName = this.collectionName.substr(1)
            console.log("Collection Group Query '" + collectionGroupName + "' in pagedTable")
            collectionGroupRef = this.firestore.collectionGroup(collectionGroupName, (ref) => {
                // Construct the firebase query
                let q = ref.limit(this.pageSize + 1)

                // Set the order
                q = q.orderBy(this.orderBy, this.direction)

                // Set the search filter
                q = this.createWhereQuery(q, this.filter)

                // Set the document to start from if we're not on the
                // first page
                if (doc) {
                    q = q.startAt(doc)
                }
                return q
            })
        } else {
            console.log('*** LOADING COLLECTION TABLE DATA *** ' + this.orderBy)
            collectionRef = this.firestore.collection(this.collectionName, (ref) => {
                // Construct the firebase query
                let q = ref.limit(this.pageSize + 1)

                // Set the order
                q = q.orderBy(this.orderBy, this.direction)

                // Set the search filter
                q = this.createWhereQuery(q, this.filter)

                // Set the document to start from if we're not on the
                // first page
                if (doc) {
                    q = q.startAt(doc)
                }
                return q
            })
        }

        // Subscribe to updates
        // Will always run first off to populate data

        //if (collectionRef) console.log("Collection Ref")
        //else console.log("Collection Group Ref")

        const observable = collectionRef ? collectionRef.snapshotChanges() : collectionGroupRef.snapshotChanges()

        this.snapshotSub = observable.subscribe(
            (response) => {
                console.log("Subscribed to '" + this.collectionName + "', with " + response.length + ' documents loaded')

                // Get the new table docs
                // Add a '_id' which is the docs id for the table element
                // Useful because some table fields need to access it.
                this.tableData = response.map((item) => {
                    return { _id: item.payload.doc.id, ...item.payload.doc.data() }
                })

                // Set the length of our supposed dataset so that the paginator enables/disables
                // page back and forward UI
                this.firstPaginator.length = this.firstPaginator.pageIndex * this.pageSize + this.tableData.length

                if (this.secondPaginator) {
                    this.secondPaginator.length = this.firstPaginator.pageIndex * this.pageSize + this.tableData.length
                }

                // If we have data then set the page top
                if (response.length > 0) {
                    this.firstOnPage[this.firstPaginator.pageIndex] = response[0].payload.doc
                } else {
                    // No pages
                    this.firstOnPage = []
                }

                // If we got one element extra than the page size - which is what we asked for -
                // then use the last doc as the first doc for the next page
                if (this.tableData.length > this.pageSize) {
                    const last = response.length - 1
                    this.firstOnPage[this.firstPaginator.pageIndex + 1] = response[last].payload.doc
                    this.tableData.pop()
                }

                // Set the data source so it updates
                this.dataSource.data = [...this.tableData]
            },
            (error) => {
                // ???
            },
        )
    }
}
