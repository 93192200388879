import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-progress-dots',
    templateUrl: './progress-dots.component.html',
    styleUrls: ['./progress-dots.component.scss'],
})
export class NgxsProgressDotsComponent {
    @Input() isLoading = true
    @Input() isLight = true
    constructor() {}
}
