import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core'

@Component({
    selector: 'app-biotasense-data-widget',
    templateUrl: './biotasense-data-widget.component.html',
    styleUrls: ['./biotasense-data-widget.component.scss'],
})
export class BiotaSenseDataWidgetComponent implements OnInit, OnChanges {
    @Input() widgetTitle = '' // title of the data widget
    @Input() dataColour = 'light-blue-fg' // colour of the data value
    @Input() dataLabel = '' // label of the data widget
    @Input() dataInfo = '' // Information about the data widget [optional]
    @Input() widgetData: any = null

    @Input() ranges = [{ start: -1, end: -1, name: '_def_' }]

    public widget = {
        ranges: {
            DT: 'Today',
            DY: 'Yesterday',
            DTW: 'This Week',
        },
        currentRange: 'DT',
        data: {
            label: 'LABEL',
            count: {
                DT: '---',
                DY: '---',
                DTW: '---',
            },
        },
    }

    constructor() {
        /** */
    }

    ngOnInit() {
        // ...
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.widgetData.currentValue) {
            this.widget = changes.widgetData.currentValue
        }
    }
}
